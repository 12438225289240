import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input, message } from "antd";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../../../Config";
import { myContext } from "../../../../App";

function Third({ setStepStage }) {
  const navigate = useNavigate();
  const { job_post_id } = useParams();
  const [jobTitle, setJobTitle] = useState("");
  const [plateForm, setplateForm] = useState("");
  const [purpose, setpurpose] = useState("");
  const b = useContext(myContext);
  const postId = b.postId;
  const [description, setDescription] = useState("");
  const [TargetTags, setTargetTags] = useState([]);
  const [customTags, setCustomTags] = useState("");
  const [customDescription, setCustomDescription] = useState("");
  const fetchJobs = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "UpdatedJobs"));
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.jobId === job_post_id) {
          setJobTitle(data.job_title);
          setplateForm(data.plateForm);
          setpurpose(data.AppPurpose);
          setDescription(
            constructDescription(
              data.job_title,
              data.AppPurpose,
              data.plateForm
            )
          );
          setTargetTags(
            constructTags(data.job_title, data.plateForm, data.AppPurpose)
          );
        }
      });
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };
  useEffect(() => {
    fetchJobs();
  }, [postId]);

  const constructDescription = (title, purpose, platform) => {
    // Array of 15 descriptions for title app developne
    const titleDescriptions = [
      "Our project is involve in the ",
      "We innovate user experiences through ",
      "Our project revolutionizes ",
      "We explore uncover valuable insights through",
      "We lead shaping the future of ",
      "Our project disrupts with ns",
      "We revolutionize connectivity through",
      "We are looking for someone with expertise in ",
      "We unlock possibilities with ",
      "Our project explores next-gen virtual reality creation of ",
      "We craft modern seamless experiences of",
      "We secure digital landscapes with proactive ",
      "We optimize performance with state-of-the-art  of",
      "We extract insights from big data with ",
      "We elevate brands with the",
    ];

    // Array of 15 descriptions for purpose
    const purposeDescriptions = [
      `The recommended freelancer should be professional in ${title} to create an attractive ${platform}.`,
      `We seek a freelancer proficient in ${title} to develop an engaging ${platform}.`,
      `Our ideal freelancer excels in ${title} to deliver exceptional results on ${platform}.`,
      `We're looking for a professional with expertise in ${title} to enhance ${platform}.`,
      `The chosen freelancer should have a strong background in ${title} for ${platform} development.`,
      `We require a skilled freelancer in ${title} to optimize ${platform} performance.`,
      `Our project demands expertise in ${title} to innovate ${platform} functionalities.`,
      `We're seeking a freelancer experienced in ${title} to craft a seamless ${platform}.`,
      `The freelancer should possess advanced knowledge in ${title} for ${platform} enhancement.`,
      `We're looking for a freelancer proficient in ${title} to transform ${platform} experiences.`,
      `The ideal candidate should specialize in ${title} to create a compelling ${platform}.`,
      `We need a freelancer adept in ${title} to elevate ${platform} effectiveness.`,
      `We're seeking a professional skilled in ${title} to optimize ${platform} operations.`,
      `The freelancer should have expertise in ${title} to drive ${platform} innovation.`,
      `We require a freelancer with a deep understanding of ${title} to revolutionize ${platform}.`,
    ];

    // Array of 15 descriptions for platform
    const platformDescriptions = [
      `The purpose of the ${platform} platform is to provide the best service of ${purpose}.`,
      `Our ${platform} platform aims to deliver top-notch ${purpose} service.`,
      `We strive to offer the best ${purpose} service through our ${platform} platform.`,
      `On our ${platform} platform, the focus is on providing exceptional ${purpose} service.`,
      `With our ${platform} platform, our goal is to ensure unparalleled ${purpose} service.`,
      `The primary objective of our ${platform} platform is to optimize ${purpose} service delivery.`,
      `Our ${platform} platform is designed to facilitate seamless ${purpose} service.`,
      `We're committed to delivering outstanding ${purpose} service through our ${platform} platform.`,
      `With a dedication to excellence, our ${platform} platform provides unmatched ${purpose} service.`,
      `The mission of our ${platform} platform is to elevate ${purpose} service standards.`,
      `Through our ${platform} platform, we aim to redefine ${purpose} service excellence.`,
      `Our ${platform} platform is engineered to enhance ${purpose} service quality.`,
      `We're passionate about delivering exceptional ${purpose} service via our ${platform} platform.`,
      `With a focus on innovation, our ${platform} platform revolutionizes ${purpose} service delivery.`,
      `Our ${platform} platform sets the standard for superior ${purpose} service performance.`,
    ];

    // Generate random indices for selecting descriptions
    const randomTitleIndex = Math.floor(
      Math.random() * titleDescriptions.length
    );
    const randomPurposeIndex = Math.floor(
      Math.random() * purposeDescriptions.length
    );
    const randomPlatformIndex = Math.floor(
      Math.random() * platformDescriptions.length
    );

    // Select random descriptions for title, purpose, and platform
    const selectedTitleDescription = titleDescriptions[randomTitleIndex];
    const selectedPurposeDescription = purposeDescriptions[randomPurposeIndex];
    const selectedPlatformDescription =
      platformDescriptions[randomPlatformIndex];

    // Construct the final description with selected descriptions and dynamic values
    return `. ${selectedTitleDescription}  ${title} project. ${selectedPlatformDescription}. ${selectedPurposeDescription} service.`;
  };

  const constructTags = (title, platform, purpose) => {
    let tags = [];
    if (title) {
      tags.push(title);
    }
    if (platform) {
      tags.push(`${platform} Development`);
    }
    if (purpose) {
      tags.push(purpose);
    }
    return tags;
  };

  const handleChange = (e) => {
    setCustomTags(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setCustomDescription(e.target.value);
  };

  const AddTags = () => {
    if (customTags !== "") {
      setTargetTags((prevTags) => [...prevTags, customTags]);
      setCustomTags("");
    }
  };
  const GoToFourth = async () => {
    // fetchJobs();

    if (TargetTags.length !== 0) {
      const finalDescription =
        customDescription !== "" ? customDescription : description;
      console.log(finalDescription);
      try {
        const userDocRef = doc(db, "UpdatedJobs", job_post_id);
        await updateDoc(userDocRef, {
          description: finalDescription,
          tags: TargetTags,
        });
      } catch (error) {
        console.log(error);
      }
      setStepStage(4);
      navigate(`/Post-Job/${job_post_id}`);
    } else {
      message.error("Sorry Some Error Occured");
    }
  };
  return (
    <>
      <div className="container">
        <h4>{jobTitle !== "" ? jobTitle : "Loading"}</h4>
        <Input value={jobTitle} /> <br />
        <h4 style={{ marginTop: "2rem" }}>Project Description</h4>
        <Input.TextArea
          value={description}
          onChange={handleDescriptionChange}
        />
        <br />
        {/* <div
          className="myMenusSelction my-4"
          style={{ border: "1px solid blue" }}
        >
          <svg
            style={{ margin: "auto" }}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-link-45deg"
            viewBox="0 0 16 16"
          >
            <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z" />
            <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z" />
          </svg>
          Do you want to attached any file?
        </div> */}
        <span style={{ marginTop: "1rem" }}>What Skills Are Required?</span>
        <br />
        <p>
          Lorem ipsum a sapiente voluptas molestias accusantium eum ex beatae
          cum perferendis nesciunt autem consequuntur.
        </p>
        <div
          className="myMenusSelction3 my-4"
          style={{ border: "1px solid blue" }}
        >
          {" "}
          {TargetTags.map((e) => {
            return (
              <>
                {" "}
                <p
                  className="mx-2"
                  style={{
                    border: "1px solid gray",
                    borderRadius: "20px",
                    padding: "5px 10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {e}
                </p>{" "}
              </>
            );
          })}
          <Input placeholder="Enter your tags" onChange={handleChange} />
          <button onClick={AddTags} className="btn btn-primary my-3">
            Add Tag
          </button>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <button
            onClick={GoToFourth}
            type="success"
            className="btn btn-success my-3"
          >
            Next
          </button>
          <Link style={{ margin: "auto 2px " }} to={"/"}>
            Press Enter
          </Link>
        </div>
      </div>
    </>
  );
}

export default Third;
