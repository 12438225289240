import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";

const Message = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  // Function to format the time difference
  const formatTimeDifference = (timestamp) => {
    const now = new Date();
    const messageTime = timestamp.toDate();
    const differenceInSeconds = Math.floor((now - messageTime) / 1000);

    if (differenceInSeconds < 5) {
      return "just now";
    } else if (differenceInSeconds < 60) {
      return `${differenceInSeconds} seconds ago`;
    } else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(differenceInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: message.img ? "20%" : "50%" }}>
        <div
          ref={ref}
          className={`message ${message.senderId === currentUser.uid && "owner"}`}
          style={{
            backgroundColor: message.senderId === currentUser.uid && "owner" ? "#7ba0db" : "white",
            borderRadius: message.senderId === currentUser.uid && "owner" ? "25px 25px 0px 25px" : "25px 25px 25px 0px",
            float: message.senderId === currentUser.uid && "owner" ? "right" : "left"
          }}
        >
          <div className="messageContent"> {message.img && <img style={{ width: "100%" }} src={message.img} alt="" />}
            <p >{message.text}<br></br> <span style={{ color: "gray" }}>{formatTimeDifference(message.date)}</span></p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Message;
