import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Avatar, message } from "antd";
import "../../../Component/HomeComponents/Caro.css";
import MyAllServices from "../../../services.json";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../Config";
import { myContext } from "../../../App";
function SubCom() {
  const cardSetRef = useRef(null);
  const service = [
    {
      image: "/img/blog/learn_about_bg.png",
      desc: "Some quick example text to build on the card title and make up the bulk of the cards content.",
      name: "Web Development1",
    },
    {
      image: "/img/blog/learn_about_bg.png",
      desc: "Some quick example text to build on the card title and make up the bulk of the cards content.",
      name: "Web Development2",
    },
    {
      image: "/img/blog/learn_about_bg.png",
      desc: "Some quick example text to build on the card title and make up the bulk of the cards content.",
      name: "Web Development3",
    },
    {
      image: "/img/blog/learn_about_bg.png",
      desc: "Some quick example text to build on the card title and make up the bulk of the cards content.",
      name: "Web Development3",
    },
    {
      image: "/img/blog/learn_about_bg.png",
      desc: "Some quick example text to build on the card title and make up the bulk of the cards content.",
      name: "Web Development4",
    },
    {
      image: "/img/blog/learn_about_bg.png",
      desc: "Some quick example text to build on the card title and make up the bulk of the cards content.",
      name: "Web Development6",
    },
    {
      image: "/img/blog/learn_about_bg.png",
      desc: "Some quick example text to build on the card title and make up the bulk of the cards content.",
      name: "Web Development8",
    },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (scrollOffset) => {
    // const cardSetWidth = cardSetRef.current.offsetWidth;
    const maxScrollPosition = (MyAllServices.categories.length - 3) * 250; // Adjust the multiplier based on your card width

    let newScrollPosition = scrollPosition + scrollOffset;

    // Check if the next scroll position exceeds the maximum allowed position
    if (newScrollPosition > maxScrollPosition) {
      newScrollPosition = 0; // Set back to the beginning
    } else if (newScrollPosition < 0) {
      newScrollPosition = maxScrollPosition; // Set to the end
    }

    setScrollPosition(newScrollPosition);
  };
  const a = useContext(myContext);
  const myFetchJob = a.fetchJobs;
  const navigate = useNavigate();
  const userId = a.UserUid;
  const status = a.status;
  const [allJobs, setAllJobs] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const fetchJobs = async () => {
    setisLoading(true);
    try {
      const jobsCollection = await getDocs(collection(db, "jobs"));
      const usersArray = []; // Create an array to store user data

      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          const userId = doc.data().uid;
          const user = await getUserData(userId);
          if (user && Object.keys(user).length > 0) {
            // Push user data into the array
            usersArray.push({
              id: userId,
              name: user.name !== "" ? user.name : "",
              username: user.username !== "" ? user.username : "", // Use empty string as a fallback if name is undefined
              profilePicture: user.image !== "" ? user.image : "", // Use empty string as a fallback if profilePicture is undefined
            });
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );

      // Set the AllUsers state after the map function has completed
      setAllUsers(usersArray);
      setAllJobs(jobsArray);
      setisLoading(false);
      myFetchJob();
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoading(false);
    }
  };
  const getUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "user", userId));
      return userDoc.exists() ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);
  useEffect(() => {
    console.log(allJobs);
  }, [allJobs]);
  useEffect(() => {
    console.log(AllUsers);
  }, [AllUsers]);

  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  const handleGoToProfile = (userId) => {
    navigate(`/Hire-Talent/user-profile/${userId}`);
  };
  const handleClickContatc = (e) => {
    navigate(`/contact/${e}`);
  };

  const AddToFevirit = async (e) => {
    const TragetGigiFavorits = allJobs.find((i) => i.id === e);
    if (TragetGigiFavorits) {
      const FavPostRef = await addDoc(collection(db, "MyFavorites"), {
        uid: userId,
        favId: "",
        gigId: TragetGigiFavorits.id,
        category: TragetGigiFavorits.category,
        title: TragetGigiFavorits.title,
        userName: TragetGigiFavorits.userName,
        profile: TragetGigiFavorits.userProfile,
        description: TragetGigiFavorits.description,
      });
      const jobId = FavPostRef.id;
      await updateDoc(FavPostRef, { favId: jobId });
      message.success("Successfully Added To Favorite");
      myFetchJob();
    } else {
      message.error("Sorry Internal Error");
    }

    console.log(TragetGigiFavorits);
  };
  return (
    <div>
      {/* <h2 className="text-center">Hire The Best Talent</h2>
      <div className="myCaro-container" style={{ display: "flex" }}>
        <button
          className="myCaro-prev"
          onClick={() => handleScroll(-250)}
          disabled={scrollPosition === 0}
        >
          {" "}
          <span
            style={{
              backgroundColor: "lightblue",
              padding: ".5rem",
              borderRadius: "50%",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-left-circle"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
              />
            </svg>
          </span>
        </button>

        <div
          className="myCaro"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            position: "relative",
          }}
        >
          <div
            className="card-set"
            style={{
              display: "flex",
              flexDirection: "row",
              transition: "transform 0.5s ease-in-out",
              transform: `translateX(${-scrollPosition}px)`,
            }}
          >
            {status === "Freelancer" ? (
              <>
                {MyAllServices.categories.map((e, index) => (
                  <div
                    className="card mx-2"
                    style={{ width: "250px", display: "inline-block" }}
                    key={e.name}
                  >
                    <img
                      style={{ height: "10rem", objectFit: "cover" }}
                      className="card-img-top"
                      src={e.icon}
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      {" "}
                      <div class="d-flex">
                        <div class="user-profile-picture mr-3">
                          <img
                            src="./img/candiateds/1.png"
                            alt="Profile Picture"
                            class="w-100 h-100"
                          />
                        </div>
                        <div class="user-detai">
                          <h6 style={{ margin: "auto" }}>{e.name}</h6>
                          <p style={{ margin: "auto", fontSize: ".8rem" }}>
                            {e.name}
                          </p>
                        </div>
                      </div>
                      <p className="card-text" style={{ whiteSpace: "normal" }}>
                        {e.description}
                      </p>
                      <a href="post_job.html" className="btn btn-primary">
                        Post Job
                      </a>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {" "}
                {allJobs.map((e, index) => {
                  const carouselId = `carouselExampleIndicators-${index}`;
                  const carouselTarget = `#carouselExampleIndicators-${index}`;
                  return (
                    <div
                      className="card mx-3"
                      style={{ width: "240px" }}
                      id="MyUserCradsService"
                      key={index}
                    >
                      <div
                        className="carousel slide"
                        data-ride="carousel"
                        id={carouselId}
                      >
                        <div class="carousel-inner" style={{ width: "100%" }}>
                          <div class="carousel-item active">
                            <img
                              style={{ height: "9rem" }}
                              class="d-block w-100"
                              src={
                                e.img1 !== ""
                                  ? e.img1
                                  : "https://media.istockphoto.com/id/1434742171/photo/laptop-ppt-presentation-business-meeting-and-team-working-on-review-for-new-digital-website.webp?b=1&s=170667a&w=0&k=20&c=CTuv0GsOQhhXBx7nhCghIaadrbJS9bpTdIn7zHi8xOo="
                              }
                              alt="First slide"
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              style={{ height: "9rem" }}
                              class="d-block w-100"
                              src={
                                e.img2 !== ""
                                  ? e.img2
                                  : "https://media.istockphoto.com/id/1434742171/photo/laptop-ppt-presentation-business-meeting-and-team-working-on-review-for-new-digital-website.webp?b=1&s=170667a&w=0&k=20&c=CTuv0GsOQhhXBx7nhCghIaadrbJS9bpTdIn7zHi8xOo="
                              }
                              alt="Second slide"
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              style={{ height: "9rem" }}
                              class="d-block w-100"
                              src={
                                e.img3 !== ""
                                  ? e.img3
                                  : "https://media.istockphoto.com/id/1434742171/photo/laptop-ppt-presentation-business-meeting-and-team-working-on-review-for-new-digital-website.webp?b=1&s=170667a&w=0&k=20&c=CTuv0GsOQhhXBx7nhCghIaadrbJS9bpTdIn7zHi8xOo="
                              }
                              alt="Third slide"
                            />
                          </div>
                        </div>
                        <a
                          style={{ marginTop: "-1rem" }}
                          className="carousel-control-prev"
                          href={carouselTarget}
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          style={{ marginTop: "-1rem" }}
                          className="carousel-control-next"
                          href={carouselTarget}
                          role="button"
                          data-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>

                      <div class="card-body">
                        {" "}
                        <div class="d-flex">
                          <div class="user-profile-picture mr-3">
                            <Badge
                              dot
                              color="green"
                              style={{ marginRight: "4px", marginTop: "31px" }}
                            >
                              <Avatar
                                onClick={() => handleGoToProfile(e.uid)}
                                style={{ cursor: "pointer" }}
                                size={40}
                                src={
                                  e.userProfile !== "none"
                                    ? e.userProfile
                                    : "./img/candiateds/1.png"
                                }
                              />{" "}
                              <div
                                style={{
                                  position: "absolute",
                                  top: 74,
                                  right: 8,
                                  // background: "#52c41a",
                                  borderRadius: "50%",
                                  width: 12,
                                  height: 12,
                                }}
                              ></div>
                            </Badge>
                          </div>
                          <div class="user-detail">
                            <h6 style={{ margin: "auto" }}>
                              {e.userName}{" "}
                              {e.isEmailVerified ? (
                                <>
                                  <svg
                                    style={{ color: "blue" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="currentColor"
                                    class="bi bi-check-circle-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                </>
                              ) : (
                                ""
                              )}
                            </h6>
                            <Link style={{ margin: "auto", fontSize: ".8rem" }}>
                              Web Developer
                            </Link>
                          </div>
                        </div>
                        <Link
                          to={`/Gigs-details/${e.jobId}`}
                          style={{
                            textAlign: "left",
                            marginTop: "3rem",
                            textDecoration: "underline",
                          }}
                        >
                          {e.description && e.description.slice(0, 60)}.....
                        </Link>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "1.5rem",
                            cursor: "pointer",
                          }}
                        >
                          <svg
                            onClick={() => handleClickContatc(e.uid)}
                            style={{ color: "green" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-chat-left-text"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                          </svg>

                          <svg
                            style={{ color: "red" }}
                            onClick={() => AddToFevirit(e.id)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-heart"
                            viewBox="0 0 16 16"
                          >
                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>

        <button
          className=" myCaro-next"
          onClick={() => handleScroll(200)}
          disabled={
            scrollPosition === (MyAllServices.categories.length - 4) * 250
          } // Adjust the multiplier based on your card width
        >
          {" "}
          <span
            style={{
              backgroundColor: "lightblue",
              padding: ".5rem",
              borderRadius: "50%",
            }}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-right-circle"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
              />
            </svg>
          </span>
        </button>
      </div> */}
      <h1 style={{ color: "#00d363", textAlign: "center" }}>
        Tap Into a Globel Network
      </h1>
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ color: "rgba(0, 132, 255, 0.8)" }}
                >
                  Post a Job
                </h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Facilis inventore delectus quos ipsam commodi consequuntur
                  atqu error odio eos harum laboriosam totam veritatis.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ color: "color: rgba(0, 132, 255, 0.8)" }}
                >
                  Post a Job
                </h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Facilis inventore delectus quos ipsam commodi consequuntur
                  atqu error odio eos harum laboriosam totam veritatis.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ color: "color: rgba(0, 132, 255, 0.8)" }}
                >
                  Post a Job
                </h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Facilis inventore delectus quos ipsam commodi consequuntur
                  atqu error odio eos harum laboriosam totam veritatis.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 my-4">
            <div class="card">
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ color: "color: rgba(0, 132, 255, 0.8)" }}
                >
                  Post a Job
                </h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Facilis inventore delectus quos ipsam commodi consequuntur
                  atqu error odio eos harum laboriosam totam veritatis.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 my-4">
            <div class="card">
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ color: "color: rgba(0, 132, 255, 0.8)" }}
                >
                  Post a Job
                </h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Facilis inventore delectus quos ipsam commodi consequuntur
                  atqu error odio eos harum laboriosam totam veritatis.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 my-4">
            <div class="card">
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ color: "color: rgba(0, 132, 255, 0.8)" }}
                >
                  Post a Job
                </h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Facilis inventore delectus quos ipsam commodi consequuntur
                  atqu error odio eos harum laboriosam totam veritatis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="featured_candidates_area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section_title text-center mb-40">
                <h3>Top Candidates Of ITHANGE</h3>
              </div>
            </div>
          </div>
          <div class="featured_candidates_area candidate_page_padding">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/1.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/2.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/3.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/4.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/5.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/6.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/7.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/8.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/9.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/10.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/3.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/4.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="pagination_wrap">
                    <ul>
                      <li>
                        <a href="#">
                          {" "}
                          <i class="ti-angle-left"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>01</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>02</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          <i class="ti-angle-right"></i>{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubCom;
