import React from "react";

function Preview({ jobs }) {
  return (
    <div>
      {" "}
      <div>
        {jobs.map((e) => {
          return (
            <>
              <div class="job_details_area">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="job_details_header">
                        <div class="single_jobs white-bg d-flex justify-content-between">
                          <div class="jobs_left d-flex align-items-center">
                            <div class="thumb">
                              <img src="img/svg_icon/1.svg" alt="" />
                            </div>
                            <div class="jobs_conetent">
                              <a href="#">
                                <h4>{e.job_title}</h4>
                              </a>
                              <div class="links_locat d-flex align-items-center">
                                <div class="location">
                                  <p>
                                    <i class="fa fa-map-marker"></i>{" "}
                                    {e.location}
                                  </p>
                                </div>
                                <div class="location">
                                  <p>
                                    <i class="fa fa-clock-o"></i> {e.jobNature}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="jobs_right">
                            <div class="apply_now">
                              <a class="heart_mark" href="#">
                                <i class="ti-heart"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="descript_wrap white-bg">
                        <div class="single_wrap">
                          <h4>Job description</h4>
                          <p>{e.description}</p>
                          {/* <p>{e.otherRequirements}</p> */}
                        </div>
                        {/* <div class="single_wrap">
                          <h4>Responsibility</h4>
                          <p>{e.jobResponsibilities}</p>
                        </div> */}
                        <div class="single_wrap">
                          <h4>Qualifications</h4>
                          <p>{e.educationRequirements}</p>
                        </div>
                        <div class="single_wrap">
                          <h4>Gender</h4>
                          <p>{e.benefits}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="job_sumary">
                        <div class="summery_header">
                          <h3>Job Summery</h3>
                        </div>
                        <div class="job_content">
                          <ul>
                            <li>
                              Published on: <span>12 Nov, 2019</span>
                            </li>
                            <li>
                              Job Type: <span>{e.contestOrProject} </span>
                            </li>
                            <li>
                              Salary: <span>{e.price}</span>
                            </li>
                            <li>
                              Required plateForm: <span>{e.plateForm}</span>
                            </li>
                            <li>
                              Required Functionality:{" "}
                              <span>{e.AppPurpose}</span>
                            </li>
                            <li>
                              Job Nature: <span> {e.jobNature}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="share_wrap d-flex">
                        <span>Share at:</span>
                        <ul>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-google-plus"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-envelope"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="job_location_wrap">
                        <div class="job_lok_inner">
                          <div id="map" style={{ height: "200px" }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Preview;
