import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../../Config";
import "./sta.css";
import { Button, Input, message } from "antd";
import { sendEmailVerification } from "firebase/auth";
function EmailVerification({ userStatus, isEmailVerify, RenderNe }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [IsOtpSent, setIsOtpSent] = useState(false);
  const [IsEmailVerificationSet, setIsEmailVerificationSet] = useState(false);
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const SendOtp = async (username) => {
    if (username !== "") {
      try {
        const user = auth.currentUser; // Get the current user
        if (!user) {
          console.error("User not logged in");
          return;
        }
        await sendEmailVerification(user);
        console.log("User status updated to 'Completed'");
        message.success("Email Verification has been sent");
        setIsEmailVerificationSet(true);
        setTimeout(() => {
          setIsEmailVerificationSet(false);
        }, 3000);
        navigate("/");
        RenderNe();
      } catch (error) {
        setIsOtpSent(false);
        message.error("Sorry some error occcured");
        console.error("Error updating user status:", error.message);
      }

      setIsOtpSent(false);
    }
    setIsOtpSent(false);
  };

  const handleSkipEmailVerification = () => {
    navigate("/");
  };
  return (
    <div>
      <div
        style={{
          display: !IsOtpSent ? "flex" : "none",
        }}
      >
        <div id="myAlEmailCards">
          <div className="MySidersUserEmail">
            <img
              style={{ height: "100%", width: "100%" }}
              src="../img/log.png"
              alt="Cover Image"
              className="img-fluid"
            />
          </div>

          {/* ---------------------My Sider Cards-------------------------- */}

          <div className="MyLeftSiderEmail">
            <button
              className="btn btn-sm btn-primary float-end"
              onClick={handleSkipEmailVerification}
            >
              &#8592; Skip
            </button>
            <div className="MyText">
              <h2 className="heading">Verify Your Email Address</h2>
              <br />
              {IsEmailVerificationSet && (
                <div
                  class="alert alert-warning alert-dismissible fade show"
                  role="success"
                >
                  <strong>Thanks!</strong> We Sent The Email Verification Link
                  To {username}
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              <p className="text">
                Add your email of your account; and check your email for
                verification code
              </p>
              <span className="warning-text">
                Enter in email on which you have account
              </span>
              <br></br>
              <Input
                style={{ marginTop: ".4rem" }}
                type="email"
                placeholder="Enter your email"
                className="form-control mt-3"
                value={username}
                onChange={handleUsernameChange}
              />
              <br />

              <br />
              <Button
                className="my-1"
                style={{ marginTop: ".5rem" }}
                type="primary"
                onClick={SendOtp}
              >
                Send OTP
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: IsOtpSent ? "" : "none" }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <div id="myAlEmailCards">
            <div className="MySidersUserEmail">
              <img
                style={{ height: "100%", width: "100%" }}
                src="../img/log.png"
                alt="Cover Image"
                className="img-fluid"
              />
            </div>

            {/* ---------------------My Sider Cards-------------------------- */}

            {/* <div className="MyLeftSiderEmail">
              <button
                onClick={() => setIsOtpSent(false)}
                className="btn btn-sm btn-primary float-end"
              >
                &#8592; Change Email
              </button>
              <div className="MyText2">
                <h2 className="heading">Enter Your OTP</h2>
                <p className="text">
                  Please Check {username} Email Address We Have Sent OTP <br />{" "}
                  Enter It In Following Input Boxes
                </p>
                <span style={{ marginTop: ".3rem" }}>Enter Correct OTP</span>
                <br />

                <Input
                  style={{
                    margin: "0.3px 4px",
                    width: "2rem",
                    textDecoration: "none",
                  }}
                  maxLength={1}
                  type="number"
                  placeholder="Enter your email"
                  className="form-control mt-3"
                />
                <Input
                  style={{ margin: "0.3px 4px", width: "2rem" }}
                  maxLength={1}
                  type="number"
                  placeholder="Enter your email"
                  className="form-control mt-3"
                />
                <Input
                  style={{ margin: "0.3px 4px", width: "2rem" }}
                  maxLength={1}
                  type="number"
                  placeholder="Enter your email"
                  className="form-control mt-3"
                />
                <Input
                  style={{ margin: "0.3px 4px", width: "2rem" }}
                  maxLength={1}
                  type="number"
                  placeholder="Enter your email"
                  className="form-control mt-3"
                />
                <Input
                  style={{ margin: "0.3px 4px", width: "2rem" }}
                  maxLength={1}
                  type="number"
                  placeholder="Enter your email"
                  className="form-control mt-3"
                />
                <Input
                  style={{ margin: "0.3px 4px", width: "2rem" }}
                  maxLength={1}
                  type="number"
                  placeholder="Enter your email"
                  className="form-control mt-3"
                />

                <br />

                <p>
                  Did'nt get code?<a href="/">Send Again</a>{" "}
                </p>
                <Button
                  className="my-1"
                  style={{ marginTop: ".5rem" }}
                  type="primary"
                >
                  Submit OTP
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
