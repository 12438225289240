import React, { useState, useEffect } from "react";
import "../../../../Component/HireTalent/Hire.css";
import { Link } from "react-router-dom";
import { Carousel, Avatar } from "antd";
import ServicesArray from "../../../../services.json";
function Main() {
  const renderCard = (item, index) => (
    <div className="crollerMorecard" style={{ width: "250px" }} key={index}>
      <Carousel autoplay>
        <div>
          <img
            style={{ height: "9rem" }}
            className="d-block w-100"
            src={item.icon}
            alt="First slide"
          />
        </div>
        <div>
          <img
            style={{ height: "9rem" }}
            className="d-block w-100"
            src={item.icon}
            alt="First slide"
          />
        </div>
        <div>
          <img
            style={{ height: "9rem" }}
            className="d-block w-100"
            src={item.icon}
            alt="First slide"
          />
        </div>
      </Carousel>

      <div className="card-body">
        <div className="d-flex">
          <Avatar style={{ cursor: "pointer" }} size={40} src={item.image} />
          <div className="user-detail">
            <h6 style={{ margin: "auto" }}>
              {item.name}
              <svg
                style={{ color: "blue" }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </h6>
            <Link style={{ margin: "auto", fontSize: ".8rem" }}>
              Web Developer
            </Link>
          </div>
        </div>
        <p className="card-text">
          Some quick example text to build on the card title and
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1.5rem",
            cursor: "pointer",
          }}
        >
          <svg
            style={{ color: "green" }}
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            className="bi bi-chat-left-text"
            viewBox="0 0 16 16"
          >
            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
          </svg>{" "}
          <p
            style={{
              color: "gray",
              fontFamily: "sans-serif",
              fontSize: ".9rem",
              margin: "auto 10px",
            }}
          >
            Starting at{" "}
            <span
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              {" "}
              $199
            </span>
          </p>
        </div>
      </div>
    </div>
  );

  // State to keep track of the chunk size
  const [chunkSize, setChunkSize] = useState(5);
  const [myPosition, setmyPosition] = useState("absolute");
  // Function to update chunk size based on window width
  const updateChunkSize = () => {
    if (window.innerWidth >= 1100) {
      setChunkSize(5);
    } else if (window.innerWidth >= 800) {
      setChunkSize(3);
      setmyPosition("unset");
    } else if (window.innerWidth >= 500) {
      setChunkSize(2);
    } else {
      setChunkSize(1);
    }
  };

  // Update chunk size on initial render and window resize
  useEffect(() => {
    updateChunkSize();
    window.addEventListener("resize", updateChunkSize);
    return () => {
      window.removeEventListener("resize", updateChunkSize);
    };
  }, []);

  // Split the array into chunks based on the current chunk size
  const chunkedArray = ServicesArray.categories.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    []
  );

  return (
    <div>
      {" "}
      <h2 style={{ marginLeft: "2rem" }}>Gigs May You Like</h2>
      <div
        style={{ backgroundColor: "white" }}
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          {chunkedArray.map((chunk, index) => (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              key={index}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: ".5rem",
                  justifyContent: "center",
                }}
              >
                {chunk.map((item, index) => renderCard(item, index))}
              </div>
            </div>
          ))}
        </div>{" "}
        <div
          style={{
            position: myPosition === "unset" ? myPosition : "none",
            marginTop: "-7rem",
            paddingBottom: "7rem",
            display: "flex",
          }}
        >
          {" "}
          <a
            id="mYprevousnutton"
            style={{
              backgroundColor: "gray",
              padding: "5px",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              top: "50%",
              left: "1%",
              position: myPosition,
            }}
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              style={{
                backgroundColor: "gray",
                borderRadius: "50%",
              }}
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            style={{
              backgroundColor: "gray",
              padding: "5px",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              top: "50%",
              right: "1%",
            }}
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              style={{
                color: "black",
                borderRadius: "50%",
              }}
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        <div style={{ display: myPosition === "absolute" ? "" : "none" }}>
          {" "}
          <a
            id="mYprevousnutton"
            style={{
              backgroundColor: "gray",
              padding: "5px",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              top: "50%",
              left: "1%",
              position: myPosition,
            }}
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              style={{
                backgroundColor: "gray",
                borderRadius: "50%",
              }}
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            style={{
              backgroundColor: "gray",
              padding: "5px",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              top: "50%",
              right: "1%",
            }}
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              style={{
                color: "black",
                borderRadius: "50%",
              }}
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Main;
