import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import "./job.css";
import { myContext } from "../../../App";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../Config";

function PublishedJob() {
  const navigate = useNavigate();
  const handleJob = () => {
    navigate("/Post-Job/job_post");
  };
  const a = useContext(myContext);
  let isVerify = a.isEmailVerified;
  const isEmailVerify = true;
  const Gig_id = 1;
  const userId = a.UserUid;
  const [allJobs, setAllJobs] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [isLoaiding, setisLoaiding] = useState(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false); // State to manage the visibility of the delete confirmation modal
  const [deleteJobId, setDeleteJobId] = useState(null);

  const deleteJob = async () => {
    try {
      await deleteDoc(doc(db, "UpdatedJobs", deleteJobId)); // Delete the document
      setDeleteModalVisible(false); // Close the modal
      fetchJobs(); // Refetch the jobs after deletion
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };
  const fetchJobs = async () => {
    setisLoaiding(true);
    try {
      const jobsCollection = await getDocs(collection(db, "UpdatedJobs"));
      const usersArray = []; // Create an array to store user data
      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          const userId = doc.data().uid;
          const user = await getUserData(userId);

          // Check if user data exists before accessing its properties
          if (user && Object.keys(user).length > 0) {
            // Push user data into the array
            usersArray.push({
              id: userId,
              name: user.name !== "" ? user.name : "", // Use empty string as a fallback if name is undefined
              profilePicture: user.image !== "" ? user.image : "", // Use empty string as a fallback if profilePicture is undefined
            });
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );

      // Set the AllUsers state after the map function has completed
      setAllUsers(usersArray);
      setAllJobs(jobsArray);
      setisLoaiding(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoaiding(false);
    }
  };
  const getUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "user", userId));
      return userDoc.exists() ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);

  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  return (
    <div>
      {" "}
      <Modal
        title="Confirm Deletion"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setDeleteModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={deleteJob}>
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this job?
      </Modal>
      <div class="job_listing_area plus_padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="job_lists m-0">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    {" "}
                    <div class="single_jobs white-bg d-flex justify-content-between">
                      <div class=" d-flex align-items-center">
                        <div class="jobs_conetent">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <svg
                              onClick={handleJob}
                              id="myAddGig"
                              style={{ margin: "auto" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="66"
                              height="66"
                              fill="currentColor"
                              class="bi bi-plus-circle-fill mx-3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                            </svg>
                            {"  "}{" "}
                            <div>
                              {" "}
                              <h2 style={{ marginBottom: "-20px" }}>
                                Post Your Job
                              </h2>
                              <br />
                              <p style={{ textAlign: "center" }}>
                                Get Fast Service From Ithange Freelancer
                                Community <br />
                                Ithange.ee
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div class="jobs_right">
                        <div class="apply_now">
                          <Button className="mx-2">Edit</Button>
                          <Button>Delete</Button>
                        </div>
                      </div> */}
                    </div>
                    {allJobs
                      .filter((i) => i.uid === userId && i.pending === false)
                      .map((e, index) => {
                        return (
                          <div
                            key={index}
                            class="single_jobs white-bg d-flex justify-content-between"
                          >
                            <div class="jobs_left d-flex align-items-center">
                              <div class="jobs_conetent">
                                <a href="job_details.html">
                                  <h4>
                                    {e.job_title}-{" "}
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      Approved
                                    </span>
                                  </h4>
                                </a>
                                <div class="links_locat d-flex align-items-center">
                                  <div class="location">
                                    <p>
                                      <i class="fa fa-money"></i>
                                      {e.price}
                                    </p>
                                  </div>
                                  <div class="location">
                                    <p>
                                      <i class="fa fa-clock-o"></i>
                                      {e.duration}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="jobs_right">
                              <div class="apply_now">
                                <Button className="mx-2">
                                  {" "}
                                  <Link to={`/jobApply/${e.jobId}`}>
                                    View Apply
                                  </Link>
                                </Button>
                                <Button
                                  onClick={() => {
                                    setDeleteModalVisible(true);
                                    setDeleteJobId(e.id);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishedJob;
