import React, { useContext, useState } from "react";
import "./style.css";
import { Input, Select, AutoComplete } from "antd";

import { addDoc, collection, updateDoc } from "firebase/firestore";
import { myContext } from "../../../../App";
import { useNavigate } from "react-router-dom";
import services from "../../../../services.json";
import { auth, db } from "../../../../Config";
const { Option } = Select;
function First({ setStepStage }) {
  const [jobDetails, setJobDetails] = useState({
    location: "",
    educationRequirements: "",
    jobNature: "",
    benefits: "",
  });
  const a = useContext(myContext);
  const navigate = useNavigate();

  const setpostId = a.setpostId;
  const [JobTittle, setJobTittle] = useState("");
  const [CustomJobTitle, setCustomJobTitle] = useState("");
  const handleSelectChange = (e) => {
    console.log(e);
    setJobTittle(e);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJobDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const StartJob = async () => {
    if (JobTittle !== "") {
      const jobPostRef = await addDoc(collection(db, "UpdatedJobs"), {
        uid: userId,
        jobId: "",
        job_title: JobTittle,
        plateForm: "",
        description: "",
        skill: "",
        contestOrProject: "",
        price: "",
        duration: "",
        pakage: "",
        AppPurpose: "",
        tags: "",
        pending: true,
        location: jobDetails.location,
        jobNature: jobDetails.jobNature,
        educationRequirements: jobDetails.educationRequirements,
        Gander: jobDetails.benefits,
      });
      const jobId = jobPostRef.id;
      setpostId(jobId);
      await updateDoc(jobPostRef, { jobId: jobId });
      setStepStage(2);
      navigate(`/Post-job/${jobId}`);
    }
  };
  const myCountry = [
    "Australia",
    "Brazil",
    "Canada",
    "China",
    "France",
    "Germany",
    "India",
    "Italy",
    "Japan",
    "Mexico",
    "Pakistan",
    "Russia",
    "South Africa",
    "Spain",
    "United Kingdom",
    "United States",
  ];
  const usrDegree = [
    "Bachelor of Science (BSc)",
    "Bachelor of Arts (BA)",
    "Bachelor of Business Administration (BBA)",
    "Bachelor of Engineering (BE)",
    "Bachelor of Technology (BTech)",
    "Master of Science (MSc)",
    "Master of Arts (MA)",
    "Master of Business Administration (MBA)",
    "Master of Engineering (MEng)",
    "Doctor of Philosophy (PhD)",
    "Associate of Arts (AA)",
    "Associate of Science (AS)",
    "Associate of Applied Science (AAS)",
    "Doctor of Medicine (MD)",
    "Juris Doctor (JD)",
    "Bachelor of Fine Arts (BFA)",
    "Bachelor of Education (BEd)",
    "Master of Fine Arts (MFA)",
    "Master of Education (MEd)",
    "Doctor of Education (EdD)",
    // Add more degrees as needed
  ];

  return (
    <div>
      <div className="container">
        <div className="firstLogo">
          <img
            style={{ width: "100%", height: "100%" }}
            src="/black_logo.png"
            alt="laoding"
          />
        </div>
        <div className="FirstMain ">
          <h1 className="my-2">Tell Us What you</h1>{" "}
          <h1 style={{ color: "blue" }}>need done</h1>
          <AutoComplete
            style={{ width: "100%", marginBottom: "20px" }}
            options={services.categories.map((category) => ({
              value: category.name,
            }))}
            onSelect={handleSelectChange}
            placeholder="Select or Enter the project Category"
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          >
            <Input
              onChange={(e) => setCustomJobTitle(e.target.value)}
              placeholder="Or Enter a Custom Category"
            />
          </AutoComplete>{" "}
          <div class="form-row">
            {" "}
            <div class="col">
              <select
                class="form-control"
                name="educationRequirements"
                onChange={handleInputChange}
              >
                <option value="">Select Qualification</option>
                {usrDegree.map((e) => {
                  return (
                    <>
                      {" "}
                      <option value={e}>{e}</option>
                    </>
                  );
                })}
              </select>
            </div>
            <div class="col">
              <select
                class="form-control"
                name="location"
                onChange={handleInputChange}
              >
                <option value="">Select Country</option>
                {myCountry.map((e) => {
                  return (
                    <>
                      {" "}
                      <option value={e}>{e}</option>
                    </>
                  );
                })}
              </select>
            </div>
          </div>{" "}
          <div class="form-row">
            <div class="col">
              <select
                class="form-control"
                name="jobNature"
                onChange={handleInputChange}
              >
                <option value="">Job Nature</option>
                <option value="full-time">Full Time</option>
                <option value="part-time">Part Time</option>
                <option value="contract">Contract</option>
                <option value="freelance">Freelance</option>
              </select>
            </div>

            {/* <div class="col">
              <select
                class="form-control"
                name="benefits"
                onChange={handleInputChange}
              >
                <option value="">Gander</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div> */}
          </div>{" "}
          <button
            onClick={StartJob}
            type="success"
            className="btn btn-success my-3"
          >
            Let's Go
          </button>
          <div className="TextBottomIn">
            <div
              className="my-2"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {" "}
              <svg
                style={{ color: "blue", margin: "auto" }}
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                className="bi bi-patch-check-fill mx-2"
                viewBox="0 0 16 16"
              >
                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
              </svg>
              <span>Contact the skill freelancer within a minutes</span>
            </div>
            <div
              className="my-2"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {" "}
              <svg
                style={{ color: "blue", margin: "auto" }}
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                className="bi bi-patch-check-fill mx-2"
                viewBox="0 0 16 16"
              >
                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
              </svg>
              <span>Chat with the candidate to find the right matched</span>
            </div>
            <div
              className="my-2"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {" "}
              <svg
                style={{ color: "blue", margin: "auto" }}
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                className="bi bi-patch-check-fill mx-2"
                viewBox="0 0 16 16"
              >
                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
              </svg>
              <span>Brows the user profile to more know about freelancers</span>
            </div>
            <div
              className="my-2"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {" "}
              <svg
                style={{ color: "blue", margin: "auto" }}
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                className="bi bi-patch-check-fill mx-2"
                viewBox="0 0 16 16"
              >
                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
              </svg>
              <span>Pay only when you 100% satisfied</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default First;
