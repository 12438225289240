import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./gig.css";
import { db } from "../../../Config";
import { Button, Modal } from "antd";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { myContext } from "../../../App";
// State to manage the visibility of the delete confirmation modal

function PublishedGig() {
  const [deleteJobId, setDeleteJobId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const a = useContext(myContext);
  const userId = a.UserUid;
  const [allJobs, setAllJobs] = useState([]);
  const navigate = useNavigate();
  const deleteJob = async () => {
    try {
      await deleteDoc(doc(db, "jobs", deleteJobId)); // Delete the document
      setDeleteModalVisible(false); // Close the modal
      fetchJobs(); // Refetch the jobs after deletion
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };

  const fetchJobs = async () => {
    try {
      const jobsCollection = await getDocs(collection(db, "jobs"));
      const jobsArray = jobsCollection.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((job) => job.uid === userId && job.isComplete === true);
      setAllJobs(jobsArray);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };
  const handleNewGig = () => {
    navigate("/Post-gigs");
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobsCollection = await getDocs(collection(db, "jobs"));
        const jobsArray = jobsCollection.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((job) => job.uid === userId && job.isComplete === true);
        setAllJobs(jobsArray);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    console.log(allJobs);
  }, [allJobs]);

  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  return (
    <div>
      {" "}
      <Modal
        title="Confirm Deletion"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setDeleteModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={deleteJob}>
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this Gig?
      </Modal>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        <div className="card" style={{ width: "230px", height: "270px" }}>
          <svg
            onClick={handleNewGig}
            id="myAddGig"
            style={{ margin: "auto" }}
            xmlns="http://www.w3.org/2000/svg"
            width="66"
            height="66"
            fill="currentColor"
            class="bi bi-plus-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
          </svg>
          <p style={{ color: "gray" }}>Create New Gig</p>
        </div>
        {allJobs.map((e, index) => {
          return (
            <div className="card" style={{ width: "230px" }} key={index}>
              <img src={e.img1} class="card-img-top" alt="..."></img>
              <div class="card-body">
                <p style={{ textAlign: "left" }} class="card-text">
                  {e.description && e.description.slice(0, 60)}.....
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <svg
                  onClick={() => {
                    setDeleteModalVisible(true);
                    setDeleteJobId(e.id);
                  }}
                  width="30"
                  height="30"
                  style={{ margin: "auto 10px", color: "gray" }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-trash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                </svg>

                <p
                  style={{
                    color: "gray",
                    fontFamily: "sans-serif",
                    fontSize: ".9rem",
                    margin: "auto 10px",
                  }}
                >
                  Starting at{" "}
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    {" "}
                    ${e.basic_price}
                  </span>{" "}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PublishedGig;
