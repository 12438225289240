import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { myContext } from "../../App";
import { getDoc, doc, collection, getDocs } from "firebase/firestore";
import { db } from "../../Config";
function Work() {
  const a = useContext(myContext);
  let isVerify = a.isEmailVerified;
  const isEmailVerify = true;
  const Gig_id = 1;
  const userId = a.UserUid;
  const [allJobs, setAllJobs] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [isLoaiding, setisLoaiding] = useState(false);
  const [FilterJobsArray, setFilterJobsArray] = useState([]);

  const fetchJobs = async () => {
    setisLoaiding(true);
    try {
      const jobsCollection = await getDocs(collection(db, "UpdatedJobs"));
      const usersArray = []; // Create an array to store user data

      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          const userId = doc.data().uid;
          const user = await getUserData(userId);

          // Check if user data exists before accessing its properties
          if (user && Object.keys(user).length > 0) {
            // Push user data into the array
            usersArray.push({
              id: userId,
              name: user.name !== "" ? user.name : "", // Use empty string as a fallback if name is undefined
              profilePicture: user.image !== "" ? user.image : "", // Use empty string as a fallback if profilePicture is undefined
            });
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );

      // Set the AllUsers state after the map function has completed
      setAllUsers(usersArray);
      setAllJobs(jobsArray);
      setisLoaiding(false);
      setFilterJobsArray(jobsArray); // Set the FilterJobsArray initially with all jobs data
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoaiding(false);
    }
  };

  const getUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "user", userId));
      return userDoc.exists() ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const [location, setlocation] = useState("");
  const [JobNature, setJobNature] = useState("");
  const [Qualification, setQualification] = useState("");
  const [JobGender, setJobGender] = useState("");
  const [NotFoundText, setNotFoundText] = useState("");
  const handleChangelocation = (e) => {
    setlocation(e.target.value);
    filterJobs(e.target.value);
  };

  const handleChangeJobNature = (e) => {
    setJobNature(e.target.value);
    setFilterJobsArray(allJobs);
  };
  const handleChangeQualification = (e) => {
    setQualification(e.target.value);
    setFilterJobsArray(allJobs);
  };

  const handleChangeGender = (e) => {
    setJobGender(e.target.value);
    setFilterJobsArray(allJobs);
  };
  const filterJobs = () => {
    const filteredJobs = allJobs.filter((job) => {
      return (
        job.location && job.location.toLowerCase() === location.toLowerCase()
      );
    });
    setFilterJobsArray(filteredJobs);
    if (filteredJobs.length === 0) {
      setNotFoundText("No Matched Found Sorry");
      setTimeout(() => {
        setNotFoundText("");
      }, 2000);
    }
  };

  const handleSearchJob = () => {
    setlocation("");
    setJobNature("");
    setQualification("");
    setJobGender("");
    setFilterJobsArray([]);
    setNotFoundText("");
  };
  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  const myCountry = [
    "Australia",
    "Brazil",
    "Canada",
    "China",
    "France",
    "Germany",
    "India",
    "Italy",
    "Japan",
    "Mexico",
    "Pakistan",
    "Russia",
    "South Africa",
    "Spain",
    "United Kingdom",
    "United States",
  ];
  const usrDegree = [
    "Bachelor of Science (BSc)",
    "Bachelor of Arts (BA)",
    "Bachelor of Business Administration (BBA)",
    "Bachelor of Engineering (BE)",
    "Bachelor of Technology (BTech)",
    "Master of Science (MSc)",
    "Master of Arts (MA)",
    "Master of Business Administration (MBA)",
    "Master of Engineering (MEng)",
    "Doctor of Philosophy (PhD)",
    "Associate of Arts (AA)",
    "Associate of Science (AS)",
    "Associate of Applied Science (AAS)",
    "Doctor of Medicine (MD)",
    "Juris Doctor (JD)",
    "Bachelor of Fine Arts (BFA)",
    "Bachelor of Education (BEd)",
    "Master of Fine Arts (MFA)",
    "Master of Education (MEd)",
    "Doctor of Education (EdD)",
    // Add more degrees as needed
  ];
  return (
    <div>
      <div class="bradcam_area bradcam_bg_1">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>{allJobs && allJobs.length}+ Jobs Available</h3>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <h1 style={{ textAlign: "center" }}>
        {isLoaiding && (
          <>
            <h3>Loading Getting Details........</h3>
          </>
        )}
      </h1>
      <div>
        <div class="job_listing_area plus_padding">
          <div class="container">
            <div class="row">
              <div class="col-lg-3">
                <div class="job_filter white-bg">
                  <div class="form_inner white-bg">
                    <h3>Filter</h3>
                    <form action="#">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="single_field">
                            <input type="text" placeholder="Search keyword" />
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="single_field">
                            <select
                              class="form-control my-4"
                              onChange={handleChangelocation}
                            >
                              <option value="">Location</option>
                              {myCountry.map((e) => {
                                return (
                                  <>
                                    {" "}
                                    <option value={e}>{e}</option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {/* <div class="col-lg-12">
                        <div class="single_field">
                          <select class="form-control my-4">
                            <option data-display="Category">Category</option>
                            <option value="1">Category 1</option>
                            <option value="2">Category 2</option>
                          </select>
                        </div>
                      </div> */}
                        {/* <div class="col-lg-12">
                        <div class="single_field">
                          <select
                            class="form-control my-4"
                            onChange={handleChangeExperience}
                          >
                            <option data-display="Experience">
                              Experience
                            </option>
                            <option value="1">1 Year</option>
                            <option value="2">2 Years</option>
                            <option value="3">More then 3 Years</option>
                          </select>
                        </div>
                      </div> */}
                        <div class="col-lg-12">
                          <div class="single_field">
                            <select
                              class="form-control my-4"
                              onChange={handleChangeJobNature}
                            >
                              <option value="">Job Nature</option>
                              <option value="full-time">Full Time</option>
                              <option value="part-time">Part Time</option>
                              <option value="contract">Contract</option>
                              <option value="freelance">Freelance</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="single_field">
                            <select
                              class="form-control my-4"
                              onChange={handleChangeQualification}
                            >
                              <option data-display="Qualification">
                                Qualification
                              </option>
                              {usrDegree.map((e) => {
                                return (
                                  <>
                                    {" "}
                                    <option value={e}>{e}</option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="single_field">
                            <select
                              class="form-control my-4"
                              onChange={handleChangeGender}
                            >
                              <option data-display="Gender">Gender</option>
                              <option value="male">male</option>
                              <option value="female">female</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="range_wrap">
                    <label for="amount">Price range:</label>
                    <div id="slider-range"></div>
                    <p>
                      <input
                        type="text"
                        id="amount"
                        readonly
                        style={{
                          border: "0",
                          color: "#7a838b",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      />
                    </p>
                  </div>
                  <div class="reset_btn">
                    <button
                      onClick={handleSearchJob}
                      class="boxed-btn3 w-100"
                      type="submit"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="recent_joblist_wrap">
                  <div class="recent_joblist white-bg">
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <h4>Job Listing</h4>
                      </div>
                      <div class="col-md-6">
                        <div class="serch_cat d-flex justify-content-end">
                          <select class="form-control my-4">
                            <option value="">Most Recent</option>
                            <option value="1">Marketer</option>
                            <option value="2">Wordpress</option>
                            <option value="4">Designer</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <h1 style={{ textAlign: "center" }}>
                  {isLoaiding && (
                    <>
                      <h3>Loading Getting Details........</h3>
                    </>
                  )}
                </h1>
                <div class="job_lists m-0">
                  <div class="row">
                    {FilterJobsArray.filter(
                      (i) =>
                        i.location === location ||
                        i.jobNature === JobNature ||
                        i.educationRequirements === Qualification ||
                        (i.Gander === JobGender && i.pending === false)
                    ).length === 0 && (
                      <>
                        {" "}
                        {location !== "" ||
                          JobNature !== "" ||
                          Qualification !== "" ||
                          (JobGender !== "" && (
                            <p style={{ textAlign: "center" }}>
                              Sorry No Matched Found{" "}
                            </p>
                          ))}
                      </>
                    )}
                    {allJobs.length !== 0 ? (
                      <>
                        {allJobs
                          .filter(
                            (i) =>
                              i.location === location ||
                              i.jobNature === JobNature ||
                              i.educationRequirements === Qualification ||
                              (i.Gander === JobGender && i.pending === false)
                          )
                          .map((e, index) => {
                            return (
                              <>
                                <div key={index} class="col-lg-12 col-md-12">
                                  <div class="single_jobs white-bg d-flex justify-content-between">
                                    <div class="jobs_left d-flex align-items-center">
                                      <div class="jobs_conetent">
                                        <a href="job_details.html">
                                          <h4>{e.job_title}</h4>
                                        </a>{" "}
                                        <p>
                                          {e.description.slice(0, 60)}
                                          .....
                                        </p>
                                        <div class="links_locat d-flex align-items-center">
                                          <div class="location">
                                            <p>
                                              <i class="fa fa-map-marker"></i>{" "}
                                              {e.location}
                                            </p>
                                          </div>
                                          <div class="location">
                                            <p>
                                              <i class="fa fa-clock-o"></i>{" "}
                                              {e.duration}
                                            </p>
                                          </div>{" "}
                                          <div class="location">
                                            <p>
                                              <i class="fa fa-eye"></i>{" "}
                                              {e.jobNature}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="jobs_right">
                                      <div class="apply_now">
                                        <a class="heart_mark" href="#">
                                          <i class="fa fa-heart"></i>
                                        </a>
                                        <Link
                                          to={`/Jobs-details/${e.jobId}`}
                                          class="boxed-btn3"
                                        >
                                          Apply Now
                                        </Link>
                                      </div>
                                      <div class="date">
                                        <p>Posted On: {e.postedDate}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </>
                    ) : (
                      ""
                    )}

                    {allJobs
                      .filter((i) => i.pending === false)
                      .map((e, index) => {
                        return (
                          <>
                            <div key={index} class="col-lg-12 col-md-12">
                              <div class="single_jobs white-bg d-flex justify-content-between">
                                <div class="jobs_left d-flex align-items-center">
                                  <div class="jobs_conetent">
                                    <a href="job_details.html">
                                      <h4>{e.job_title}</h4>
                                    </a>{" "}
                                    <p>
                                      {e.description.slice(0, 60)}
                                      .....
                                    </p>
                                    <div class="links_locat d-flex align-items-center">
                                      <div class="location">
                                        <p>
                                          <i class="fa fa-map-marker"></i>{" "}
                                          {e.location}
                                        </p>
                                      </div>
                                      <div class="location">
                                        <p>
                                          <i class="fa fa-clock-o"></i>{" "}
                                          {e.jobNature}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="jobs_right">
                                  <div class="apply_now">
                                    <a class="heart_mark" href="#">
                                      <i class="fa fa-heart"></i>
                                    </a>
                                    <Link
                                      to={`/Jobs-details/${e.jobId}`}
                                      class="boxed-btn3"
                                    >
                                      Apply Now
                                    </Link>
                                  </div>
                                  <div class="date">
                                    <p>Posted On: {e.postedDate}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    <div class="col-lg-12 col-md-12">
                      <div class="single_jobs white-bg d-flex justify-content-between">
                        <div class="jobs_left d-flex align-items-center">
                          <div class="thumb">
                            <img src="img/svg_icon/2.svg" alt="" />
                          </div>
                          <div class="jobs_conetent">
                            <a href="job_details.html">
                              <h4>Digital Marketer</h4>
                            </a>
                            <div class="links_locat d-flex align-items-center">
                              <div class="location">
                                <p>
                                  <i class="fa fa-map-marker"></i> California,
                                  USA
                                </p>
                              </div>
                              <div class="location">
                                <p>
                                  <i class="fa fa-clock-o"></i> Part-time
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="jobs_right">
                          <div class="apply_now">
                            <a class="heart_mark" href="#">
                              <i class="fa fa-heart"></i>
                            </a>
                            <Link to={"/Jobs-details"} class="boxed-btn3">
                              Apply Now
                            </Link>
                          </div>
                          <div class="date">
                            <p>Date line: 31 Jan 2020</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="single_jobs white-bg d-flex justify-content-between">
                        <div class="jobs_left d-flex align-items-center">
                          <div class="thumb">
                            <img src="img/svg_icon/3.svg" alt="" />
                          </div>
                          <div class="jobs_conetent">
                            <a href="job_details.html">
                              <h4>Wordpress Developer</h4>
                            </a>
                            <div class="links_locat d-flex align-items-center">
                              <div class="location">
                                <p>
                                  <i class="fa fa-map-marker"></i> California,
                                  USA
                                </p>
                              </div>
                              <div class="location">
                                <p>
                                  <i class="fa fa-clock-o"></i> Part-time
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="jobs_right">
                          <div class="apply_now">
                            <a class="heart_mark" href="#">
                              <i class="fa fa-heart"></i>
                            </a>
                            <Link to={"/Jobs-details"} class="boxed-btn3">
                              Apply Now
                            </Link>
                          </div>
                          <div class="date">
                            <p>Date line: 31 Jan 2020</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="single_jobs white-bg d-flex justify-content-between">
                        <div class="jobs_left d-flex align-items-center">
                          <div class="thumb">
                            <img src="img/svg_icon/4.svg" alt="" />
                          </div>
                          <div class="jobs_conetent">
                            <a href="job_details.html">
                              <h4>Visual Designer</h4>
                            </a>

                            <div class="links_locat d-flex align-items-center">
                              <div class="location">
                                <p>
                                  <i class="fa fa-map-marker"></i> California,
                                  USA
                                </p>
                              </div>
                              <div class="location">
                                <p>
                                  <i class="fa fa-clock-o"></i> Part-time
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="jobs_right">
                          <div class="apply_now">
                            <a class="heart_mark" href="#">
                              <i class="fa fa-heart"></i>
                            </a>
                            <Link to={"/Jobs-details"} class="boxed-btn3">
                              Apply Now
                            </Link>
                          </div>
                          <div class="date">
                            <p>Date line: 31 Jan 2020</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="single_jobs white-bg d-flex justify-content-between">
                        <div class="jobs_left d-flex align-items-center">
                          <div class="thumb">
                            <img src="img/svg_icon/5.svg" alt="" />
                          </div>
                          <div class="jobs_conetent">
                            <a href="job_details.html">
                              <h4>Software Engineer</h4>
                            </a>
                            <div class="links_locat d-flex align-items-center">
                              <div class="location">
                                <p>
                                  <i class="fa fa-map-marker"></i> California,
                                  USA
                                </p>
                              </div>
                              <div class="location">
                                <p>
                                  <i class="fa fa-clock-o"></i> Part-time
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="jobs_right">
                          <div class="apply_now">
                            <a class="heart_mark" href="#">
                              <i class="fa fa-heart"></i>
                            </a>
                            <Link to={"/Jobs-details"} class="boxed-btn3">
                              Apply Now
                            </Link>
                          </div>
                          <div class="date">
                            <p>Date line: 31 Jan 2020</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="single_jobs white-bg d-flex justify-content-between">
                        <div class="jobs_left d-flex align-items-center">
                          <div class="thumb">
                            <img src="img/svg_icon/1.svg" alt="" />
                          </div>
                          <div class="jobs_conetent">
                            <a href="job_details.html">
                              <h4>Creative Designer</h4>
                            </a>
                            <div class="links_locat d-flex align-items-center">
                              <div class="location">
                                <p>
                                  <i class="fa fa-map-marker"></i> California,
                                  USA
                                </p>
                              </div>
                              <div class="location">
                                <p>
                                  <i class="fa fa-clock-o"></i> Part-time
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="jobs_right">
                          <div class="apply_now">
                            <a class="heart_mark" href="#">
                              <i class="fa fa-heart"></i>
                            </a>
                            <Link to={"/Jobs-details"} class="boxed-btn3">
                              Apply Now
                            </Link>
                          </div>

                          <div class="date">
                            <p>Date line: 31 Jan 2020</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="pagination_wrap">
                        <ul>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="ti-angle-left"></i>{" "}
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span>01</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span>02</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="ti-angle-right"></i>{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
