import React from "react";
import Gig from "./Gig";

function GigsPostMain() {
  return (
    <div>
      <Gig />
    </div>
  );
}

export default GigsPostMain;
