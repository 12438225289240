import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "antd";
import { myContext } from "../../../App";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

import { useNavigate } from "react-router-dom";
import { db } from "../../../Config";
function PendingJobs() {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false); // State to manage the visibility of the delete confirmation modal
  const [deleteJobId, setDeleteJobId] = useState(null);
  const navigate = useNavigate();
  const handleJob = () => {
    navigate("/Post-Job");
  };
  const a = useContext(myContext);

  const userId = a.UserUid;
  const [allJobs, setAllJobs] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [isLoaiding, setisLoaiding] = useState(false);
  const fetchJobs = async () => {
    setisLoaiding(true);
    try {
      const jobsCollection = await getDocs(collection(db, "UpdatedJobs"));
      const usersArray = []; // Create an array to store user data
      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          const userId = doc.data().uid;
          const user = await getUserData(userId);
          if (user && Object.keys(user).length > 0) {
            // Push user data into the array
            usersArray.push({
              id: userId,
              name: user.name !== "" ? user.name : "", // Use empty string as a fallback if name is undefined
              profilePicture: user.image !== "" ? user.image : "", // Use empty string as a fallback if profilePicture is undefined
            });
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
      setAllUsers(usersArray);
      setAllJobs(jobsArray);
      setisLoaiding(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoaiding(false);
    }
  };
  const getUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "user", userId));
      return userDoc.exists() ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  };
  useEffect(() => {
    fetchJobs();
  }, [userId]);
  const handleClick = (e) => {
    navigate(`/Post-Job/${e}`);
  };
  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  const deleteJob = async () => {
    try {
      await deleteDoc(doc(db, "UpdatedJobs", deleteJobId)); // Delete the document
      setDeleteModalVisible(false); // Close the modal
      fetchJobs(); // Refetch the jobs after deletion
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };
  return (
    <div>
      {" "}
      <Modal
        title="Confirm Deletion"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setDeleteModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={deleteJob}>
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this job?
      </Modal>
      <div class="job_listing_area plus_padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="job_lists m-0">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    {allJobs.filter((i) => i.uid === userId).length === 0 && (
                      <h4 style={{ textAlign: "center" }}>
                        No Job Found In Pending
                      </h4>
                    )}

                    {allJobs
                      .filter((i) => i.uid === userId && i.pending === true)
                      .map((e, index) => {
                        return (
                          <div
                            key={index}
                            class="single_jobs white-bg d-flex justify-content-between"
                          >
                            <div class="jobs_left d-flex align-items-center">
                              <div class="jobs_conetent">
                                <a href="job_details.html">
                                  <h4>{e.job_title} </h4>
                                </a>
                                <div class="links_locat d-flex align-items-center">
                                  <div class="location">
                                    <p>
                                      <i class="fa fa-clock-o"></i>
                                      {e.duration}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="jobs_right">
                              <div class="apply_now">
                                <Button
                                  className="mx-2"
                                  onClick={() => handleClick(e.id)}
                                >
                                  Continue
                                </Button>
                                <Button
                                  onClick={() => {
                                    setDeleteModalVisible(true);
                                    setDeleteJobId(e.id);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingJobs;
