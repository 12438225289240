import React, { useState, useRef, useContext, useEffect } from "react";
import { Avatar, Input, Divider, message, Tooltip, Modal } from "antd";
import { signOut } from "firebase/auth";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { myContext } from "../../App";
import { auth, db } from "../../Config";
import MyAllService from "../../services.json";
import { ChatContext } from "../ClientMessage/message/context/ChatContext";
import { doc, onSnapshot } from "firebase/firestore";

const { Search } = Input;

const text = (
  <span>
    <a href="#" style={{ color: "white", textDecoration: "none" }}>
      Profile
    </a>
    <div className="MyProfileNav">
      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Messages
      </a>
      <br />
      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Notification
      </a>
      <br />{" "}
      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Favorites
      </a>
      <br />{" "}
    </div>

    <div className="Isload">
      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Orders
      </a>

      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Become Seller
      </a>
      <br></br>
    </div>
  </span>
);
function AuthNavbar() {
  const navigate = useNavigate();
  const handleMassage = () => {
    navigate("/contact/chatBox");
  };
  const [isShowmOdal, setfirst] = useState(false);
  const handleModal = () => {
    setfirst();
  };
  const a = useContext(myContext);
  const UserUid = a.UserUid;
  const ProfileUrl = a.userProfile;
  const allJobs = a.allJobs;
  const username = a.username;
  console.log(ProfileUrl);
  const setIslloed = a.setIsLoggedIn;
  const status = a.status;
  const [IsClicked, setIsClicked] = useState("black");
  const [isOpen, setisOpen] = useState("none");
  const ShowNavbarHide = () => {
    setisOpen("block");
    setIsClicked("none");
    console.log("Cliecked");
  };

  const handleClose = () => {
    setisOpen("none");
    setIsClicked("flex");
    console.log("Cliecked");
  };

  const cardSetRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = (scrollOffset) => {
    const maxScrollPosition = (MyAllService.categories.length - 1) * 10;
    let newScrollPosition = scrollPosition + scrollOffset;
    if (newScrollPosition > maxScrollPosition) {
      newScrollPosition = 0;
    } else if (newScrollPosition < 0) {
      newScrollPosition = maxScrollPosition;
    }
    setScrollPosition(newScrollPosition);
  };
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const handleLogin = () => {
    if (username === "none") {
      navigate(`/status/${UserUid}`);
    } else {
      navigate("/profile/user");
    }
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    signOut(auth)
      .then(() => {
        setIslloed(false);
        navigate("/");
        message.success("Logged out");
      })
      .catch((error) => {
        setIslloed(true);
        message.error("Internal error occurred");
      });
  };

  const [messages, setMessages] = useState([]);
  const { data } = useContext(ChatContext);

  useEffect(() => {
    const unSub = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
      if (doc.exists()) {
        setMessages(doc.data().messages);
      }
    });

    return () => {
      unSub();
    };
  }, [data.chatId]);

  const handleFavorites = () => {
    navigate("/myFavorite");
  };
  const [TotaNotification, setTotaNotification] = useState([]);
  return (
    <div>
      <Modal open={isShowmOdal} onCancel={() => setfirst(false)} footer={null}>
        <p style={{ textAlign: "center", color: "gray" }}>
          No Notification found
        </p>
      </Modal>
      <nav class="navbar bg-body-tertiary">
        <div class="container-fluid">
          <a class="navbar-brand" id="LogoProvider">
            <img
              style={{ width: "7rem" }}
              src="/img/black_logo.png"
              alt=""
              srcset=""
            />
          </a>
          <div className="SiderProImages">
            <Tooltip placement="bottomLeft" title={text}>
              <Avatar
                onClick={handleLogin}
                style={{ margin: "auto", cursor: "pointer" }}
                src={ProfileUrl}
              />
            </Tooltip>{" "}
            <svg
              onClick={ShowNavbarHide}
              style={{ color: "black" }}
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </div>
          <Tooltip className="AvaterLogo" placement="bottomLeft" title={text}>
            <Avatar
              onClick={handleLogin}
              style={{ margin: "auto", cursor: "pointer" }}
              src={ProfileUrl}
            />
          </Tooltip>
          <Search
            className="MyPhoneSearchBox"
            placeholder="Search For Services"
            onSearch={onSearch}
            enterButton
          />
          <div className="Authmen">
            {" "}
            <a class=" position-relative" onClick={handleModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-bell"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
              </svg>{" "}
              <span
                class="position-absolute top-0 start-80 translate-middle badge rounded-pill bg-danger"
                style={{
                  marginTop: "-7px",
                  marginLeft: "-7px",
                  color: "white",
                }}
              >
                {TotaNotification.length}
              </span>
            </a>
            <a class=" position-relative">
              <svg
                onClick={handleMassage}
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-envelope"
                viewBox="0 0 16 16"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
              </svg>{" "}
              <span
                class="position-absolute top-0 start-80 translate-middle badge rounded-pill bg-success"
                style={{
                  marginTop: "-7px",
                  marginLeft: "-7px",
                  color: "white",
                }}
              >
                {messages.filter((i) => i.senderId !== UserUid).length}
              </span>
            </a>
            <a class=" position-relative">
              <svg
                type="button"
                onClick={handleFavorites}
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-heart position-relative"
                viewBox="0 0 16 16"
              >
                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
              </svg>
              <span
                class="position-absolute top-0 start-80 translate-middle badge rounded-pill bg-success"
                style={{
                  marginTop: "-7px",
                  marginLeft: "-7px",
                  color: "white",
                }}
              >
                {allJobs.filter((i) => i.uid === UserUid).length}
              </span>
            </a>
            {/* <svg
              type="button"
              onClick={handleFavorites}
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-heart position-relative"
              viewBox="0 0 16 16"
            >
              <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                99+
                <span class="visually-hidden">unread messages</span>
              </span>
            </svg> */}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <form class="d-flex" role="search">
              <li style={{ margin: "auto 15px" }}>
                <Link
                  className="showHomeContents"
                  style={{ color: "#33d4d6", fontWeight: "bold" }}
                  to={"/"}
                >
                  Home
                </Link>
              </li>{" "}
              {status === "Freelancer" ? (
                <>
                  {" "}
                  <li
                    className="showHomeContents"
                    style={{ margin: "auto 15px", fontWeight: "bold" }}
                  >
                    <Link to={"/Find-work"}>Find Work</Link>
                  </li>
                </>
              ) : (
                <>
                  {" "}
                  <li
                    className="showHomeContents"
                    style={{ margin: "auto 15px", fontWeight: "bold" }}
                  >
                    <Link to={"/Hire-Talent"}>Hire Talent</Link>
                  </li>
                </>
              )}
              <li
                id="showHomeContents"
                class="MoreBuuton"
                style={{ margin: "auto 15px", fontWeight: "bold" }}
              >
                <a href="#">
                  More <i class="ti-angle-down"></i>
                </a>
                <ul
                  class="submenu"
                  style={{
                    position: "fixed",
                    backgroundColor: "green",
                    padding: ".5rem",
                  }}
                >
                  <li style={{ marginTop: "-10px" }}>
                    <Link
                      style={{ color: "white", fontWeight: "bold" }}
                      to={"/whyChoose-us"}
                    >
                      Why Us
                    </Link>
                  </li>
                  <li style={{ marginTop: "1px" }}>
                    <Link
                      style={{ color: "white", fontWeight: "bold" }}
                      to={"/pricing"}
                    >
                      Pricing
                    </Link>
                  </li>
                  <li style={{ marginTop: "20px" }}>
                    <Link
                      style={{ color: "white", fontWeight: "bold" }}
                      to={"/blogs"}
                    >
                      Blogs
                    </Link>
                  </li>
                  <li style={{ marginTop: "0px" }}>
                    <Link
                      style={{ color: "white", fontWeight: "bold" }}
                      to={"/safe-pay"}
                    >
                      Safe Pay
                    </Link>
                  </li>{" "}
                  <li style={{ marginTop: "0px" }}>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Orders
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li
              id="MyISdIS"
              className="nav-link mx-1"
              style={{ color: "green", fontWeight: "bold" }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                Switch To Selling
              </Link>
            </li>  */}
              {/* <Tooltip className="ShowAvater" placement="bottomLeft" title={text}>
              <Avatar
                onClick={handleLogin}
                style={{ margin: "auto", cursor: "pointer" }}
                src={ProfileUrl}
              />
            </Tooltip> */}
            </form>
            <Tooltip className="ShowAvater" placement="bottomLeft" title={text}>
              <Avatar
                onClick={handleLogin}
                style={{
                  margin: "auto",
                  cursor: "pointer",
                  border: "2px solid green",
                }}
                src={ProfileUrl}
              />
            </Tooltip>{" "}
            <svg
              onClick={ShowNavbarHide}
              id="ShiwMobileMenue"
              style={{ color: "black" }}
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </div>
        </div>
      </nav>
      <div></div>

      <Divider style={{ marginTop: ".9rem" }} />

      <div className="ScrolableContainer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => handleScroll(-200)}
          style={{
            cursor: "pointer",
            backgroundColor: "white",
            marginTop: "-1rem",
          }}
          width="22"
          height="22"
          fill="currentColor"
          className="bi bi-chevron-compact-left"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223"
          />
        </svg>

        <div
          className="Scrolable"
          ref={cardSetRef}
          style={{
            transform: `translateX(-${scrollPosition}px)`,
            marginTop: "-1rem",
            width: "100%",
          }}
        >
          {MyAllService.categories.map((e, index) => (
            <a key={index} href="#" style={{ whiteSpace: "nowrap" }}>
              {e.name}
            </a>
          ))}
        </div>

        <svg
          onClick={() => handleScroll(200)}
          style={{
            cursor: "pointer",
            backgroundColor: "white",
            marginTop: "-1rem",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="currentColor"
          className="bi bi-chevron-compact-right"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"
          />
        </svg>
      </div>

      <Divider style={{ marginTop: "-0.01rem" }} />
      <Search
        className="MyPhoneSearchBox2"
        placeholder="Search For Services"
        onSearch={onSearch}
        enterButton
      />

      <div
        className="MyTopEer fixed-top"
        style={{ display: isOpen, backgroundColor: "white" }}
      >
        <div className="WrapNav">
          <img
            style={{ width: "30%", height: "100%" }}
            src="./img/black_logo.png"
            alt=""
            srcset=""
          />
          <div className="SiderWraooer">
            <div style={{ gap: "20px", display: "flex" }}>
              <svg
                onClick={handleModal}
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-bell"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
              </svg>{" "}
              <Link to={"/contact/chatBox"}>
                <svg
                  style={{ cursor: "pointer" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
              </Link>
              <svg
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-heart"
                viewBox="0 0 16 16"
              >
                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
              </svg>
            </div>
            <div className="mx-2">
              <li>
                <a href="#">
                  Language <i class="ti-angle-down"></i>
                </a>
                <ul class="submenu">
                  <li>
                    <a href="#">English</a>
                  </li>
                  <li>
                    <a href="#">Urdu</a>
                  </li>
                  <li>
                    <a href="#">Hindi</a>
                  </li>
                </ul>
              </li>
            </div>
            <svg
              onClick={handleClose}
              color="blue"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-x-lg mx-2"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </div>
        </div>
        <div className="enuLinks">
          <li>
            <Link to={"/Find-work"}>Find Work</Link>
          </li>
          <li>
            <Link to={"/Hire-Talent"} className="my-2">
              Hire Talent
            </Link>
          </li>
          <li id="mobHeadWhyUs">
            <Link to={"/whyChoose-us"}>Why Us</Link>
          </li>
          <li id="mobHeadBlogs">
            <Link to={"/blogs"} href="blog.html" className="my-2">
              Blogs
            </Link>
          </li>
          <li id="mobHeadPricings">
            <Link to={"/pricing"} className="my-2">
              Pricing
            </Link>
          </li>
          <li id="mobHeadSafePay">
            <Link to={"/safe-pay"}>Safe Pay</Link>
          </li>
        </div>
        <div class="MobileLoginAndRegster" style={{ marginTop: "3rem" }}>
          <Link
            onClick={handleSignOut}
            style={{
              fontWeight: "bold",
              textAlign: "center",
              border: "0.1rem solid #2083e0",
              padding: "0.2rem",
              color: "rgb(255, 255, 255)",
              borderRadius: "10px",
              width: "80%",
              backgroundColor: "#2083e0",
            }}
          >
            LogOut
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AuthNavbar;
