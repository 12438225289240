import React, { useState, useEffect, useContext } from "react";
import { doc, collection, getDocs, getDoc } from "firebase/firestore";
import { myContext } from "../../../App";
import { db } from "../../../Config";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
function History({ UserID }) {
  const a = useContext(myContext);

  const navigate = useNavigate();
  const isEmailVerify = true;
  const Gig_id = 1;
  const userId = a.UserUid;
  const [allJobs, setAllJobs] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const fetchJobs = async () => {
    setisLoading(true);
    try {
      const jobsCollection = await getDocs(collection(db, "JobApply"));
      const usersArray = []; // Create an array to store user data
      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          const userId = doc.data().uid;
          const user = await getUserData(userId);
          if (user && Object.keys(user).length > 0) {
            // Push user data into the array
            usersArray.push({
              id: userId,
              name: user.name !== "" ? user.name : "", // Use empty string as a fallback if name is undefined
              profilePicture: user.image !== "" ? user.image : "", // Use empty string as a fallback if profilePicture is undefined
            });
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
      setAllUsers(usersArray);
      setAllJobs(jobsArray);
      setisLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoading(false);
    }
  };
  const getUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "user", userId));
      return userDoc.exists() ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);
  useEffect(() => {
    console.log(allJobs);
  }, [allJobs]);
  useEffect(() => {
    console.log(AllUsers);
  }, [AllUsers]);

  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  return (
    <div>
      {isLoading && <p style={{ textAlign: "center" }}>Loading....</p>}
      {allJobs
        .filter((i) => i.uid === UserID)
        .map((e) => {
          return (
            <>
              <div class="card  my-2">
                <div class="card-body">
                  <p>
                    <Avatar />
                    You applied to {e.jobName} job .
                  </p>{" "}
                  <p style={{ marginTop: "-40px", float: "right" }}>
                    02/03/2002
                  </p>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
}

export default History;
