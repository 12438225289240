import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { message } from "antd";
import { auth } from "../../../Config";
import { myContext } from "../../../App";
import ForgotPassword from "./ForgotPassword";

function Login() {
  const [isLoading, setisLoading] = useState(false);
  const [IsRestPassword, setIsRestPassword] = useState(false);
  const navigate = useNavigate();
  const MyUserData = useContext(myContext);
  let setIsLoggedIn = MyUserData.setIsLoggedIn;let CallMe = MyUserData.RenderNe;

  const [email, setemail] = useState("");
  const [Passowrd, setPassowrd] = useState("");
  const handleLogin = (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      signInWithEmailAndPassword(auth, email, Passowrd)
        .then((response) => {
          message.success("Logged In Successfully");
          setIsLoggedIn(true);
          setPassowrd("");
          CallMe();
          setisLoading(false);
          navigate("/");
        })
        .catch((error) => {
          message.error("Invalid Email Or Password");
          console.log(error);
          setIsLoggedIn(false);
          setisLoading(false);
        });
    } catch (error) {
      message.error("Invalid Email Or Password");
      console.log(error);
      setIsLoggedIn(false);
      setisLoading(false);
    }
  };

  return (
    <div>
      {" "}
      <div style={{ display: IsRestPassword ? "" : "none" }}>
        {" "}
        <ForgotPassword setIsRestPassword={setIsRestPassword} />{" "}
      </div>
      <div
        class="container"
        style={{ marginTop: "4rem", display: IsRestPassword ? "none" : "" }}
      >
        <div class="card login-card">
          <img
            src="./img/black_logo.png"
            style={{ width: "70%", margin: "auto" }}
            alt="Logo"
          />

          <h2 class="text-center my-4">Welcome Back</h2>
          {isLoading && (
            <p style={{ textAlign: "center" }}>Checking Credential wait....</p>
          )}
          <form class="login-form" onSubmit={handleLogin}>
            <div class="form-row">
              <div class="col">
                <input
                  value={email}
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <input
                  value={Passowrd}
                  onChange={(e) => setPassowrd(e.target.value)}
                  type="password"
                  class="form-control"
                  placeholder="Password"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "r4em",
              }}
            >
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="rememberCheckbox"
                />
                <label class="form-check-label" for="rememberCheckbox">
                  Remember Me
                </label>
              </div>
              <div
                onClick={() => setIsRestPassword(true)}
                class="forgot-password"
              >
                <a style={{ cursor: "pointer" }}>Forgot Password?</a>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
            >
              <button type="submit" class="btn btn-primary btn-block">
                Login
              </button>
              <button type="button" class="btn btn-primary btn-block">
                <Link
                  to={"/"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Back To Home
                </Link>
              </button>
            </div>

            <hr />

            <p class="already-have-account">
              Don't have an account? <Link to={"/Register"}>Sign Up here</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
