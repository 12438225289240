import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message, Input } from "antd";
import { myContext } from "../../../../App";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../../../Config";
import AllServices from "../../../../services.json";
function Second({ setStepStage }) {
  const navigate = useNavigate();
  const { job_post_id } = useParams();
  const [jobTitle, setJobTitle] = useState("");
  const a = useContext(myContext);
  const postId = a.postId;
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    const fetchJobs = async () => {
      setisLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "UpdatedJobs"));
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          console.log(data);
          if (data.jobId === job_post_id) {
            setJobTitle(data.job_title);
            setisLoading(false);
          }
        });
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setisLoading(false);
      }
    };
    fetchJobs();
  }, [postId]);

  const [platform, setPlatform] = useState("");
  const [otherPlatform, setOtherPlatform] = useState("");
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const handlePlatformSelection = (value) => {
    const currentIndex = selectedPlatforms.indexOf(value);
    const newSelectedPlatforms = [...selectedPlatforms];
    if (currentIndex === -1) {
      newSelectedPlatforms.push(value);
    } else {
      newSelectedPlatforms.splice(currentIndex, 1);
    }

    setSelectedPlatforms(newSelectedPlatforms);
  };
  const [CustomePurpose, setCustomePurpose] = useState("");
  const [purpose, setPurpose] = useState("");
  const handlePurposeSelection = (value) => {
    setCustomePurpose("");
    setPurpose(value);
    console.log(value);
  };
  const GoToThird = async () => {
    // setisLoading(true);
    if (
      (selectedPlatforms.length !== 0 && purpose !== "") ||
      CustomePurpose !== ""
    ) {
      try {
        const userDocRef = doc(db, "UpdatedJobs", job_post_id);
        await updateDoc(userDocRef, {
          plateForm: selectedPlatforms,
          AppPurpose: purpose !== "other" ? purpose : CustomePurpose,
        });
        // setisLoading(false);
      } catch (error) {
        console.log(error);
        // setisLoading(false);
      }
      // setisLoading(false);
      setStepStage(3);
      navigate(`/Post-Job/${job_post_id}`);
    } else {
      // setisLoading(false);
      message.error("Please Submit the purpose and required plateForm");
    }
  };
  return (
    <div>
      {!isLoading ? (
        <>
          {" "}
          <div className="container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {" "}
              <h3>{jobTitle}</h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-pencil-fill"
                viewBox="0 0 16 16"
              >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
              </svg>
            </div>
            <div className="MySelction">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                <span>
                  What platform do you want the app to be develope for ?
                </span>
                <p>1 of 3</p>
              </div>

              {AllServices.categories
                .filter((i) => i.name.toLowerCase() === jobTitle.toLowerCase())
                .map(
                  (category) =>
                    category.subcategories &&
                    category.subcategories.map((e, index) => (
                      <div
                        key={index}
                        className="myMenusSelction my-2"
                        style={{ border: "1px solid blue" }}
                        onClick={() => handlePlatformSelection(e)}
                      >
                        <input
                          type="checkbox"
                          checked={selectedPlatforms.includes(e)}
                        />
                        {e}
                      </div>
                    ))
                )}

              <div
                className="myMenusSelction my-2"
                style={{ border: "1px solid blue" }}
                onClick={() => handlePlatformSelection("other")}
              >
                <input
                  type="checkbox"
                  checked={platform === "other"}
                  readOnly
                />
                Other(please specify)
              </div>
            </div>
            {platform === "other" && (
              <Input
                value={otherPlatform}
                onChange={(e) => setOtherPlatform(e.target.value)}
                placeholder="Specify Platform"
              />
            )}
            <div className="mainPurpose">
              <span>What Is The Main Purpose of the App?</span>
              <div className="myRowButtons">
                {AllServices.categories
                  .filter(
                    (i) => i.name.toLowerCase() === jobTitle.toLowerCase()
                  )
                  .map(
                    (category) =>
                      category.subcategories &&
                      category.purpose.map((e, index) => (
                        <div
                          key={index}
                          className="myMenusSelction4 my-2"
                          style={{
                            border: "1px solid blue",
                            borderRadius: "10px",
                          }}
                          onClick={() => handlePurposeSelection(e)}
                        >
                          <input
                            style={{ whiteSpace: "nowrap" }}
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id={`flexRadioDefault${index}`}
                            checked={purpose === e}
                            readOnly
                          />
                          {e}
                        </div>
                      ))
                  )}
              </div>
              <div
                className="myMenusSelction my-2"
                style={{ border: "1px solid blue" }}
                onClick={() => handlePurposeSelection("other")}
              >
                {" "}
                <input
                  checked={purpose === "other"}
                  class="form-check-input"
                  type="radio"
                  value=""
                  id="flexCheckDefault"
                />
                Other(please specify)
              </div>{" "}
            </div>{" "}
            {purpose === "other" && (
              <Input
                onChange={(e) => setCustomePurpose(e.target.value)}
                placeholder={`Enter the purpose of ${jobTitle}`}
              />
            )}
            <div style={{ display: "flex", gap: "20px" }}>
              <button
                onClick={GoToThird}
                type="success"
                className="btn btn-success my-3"
              >
                Next
              </button>
              {/* <button onClick={fetchJobs}>asdasdasdasdas d asd </button> */}
              <Link style={{ margin: "auto 2px " }} to={"/"}>
                Skip
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <h2>Loading Please Wait..........</h2>
        </>
      )}
    </div>
  );
}

export default Second;
