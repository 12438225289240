import React from "react";
import AuthPage from "./HomeCompnents/AuthPage";
function Home() {
  return (
    <div>
      <AuthPage />
    </div>
  );
}

export default Home;
