import React, { useState } from "react";

import HomMessages from "./pages/Home";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import AuthNavbar from "./messaeNav";
function ChatBox() {
  const { id } = useParams();
  const [handleShow, sethandleShow] = useState(true);
  return (
    <div>
      <AuthNavbar />
      <Modal
        footer={null}
        open={handleShow}
        onCancel={() => sethandleShow(false)}
      >
        <div
          class="alert alert-success"
          role="alert"
          style={{ padding: "20px 20px" }}
        >
          <h4 class="alert-heading">Welcome Dear Users!</h4>
          <p>
            Welcome to the chat box! To initiate a conversation with a selected
            user, please use the search feature to find and connect with them.
            Once you've found your desired user, you can start chatting
          </p>
          <hr />
          <p class="mb-0">
            If you encounter any abusive or offensive conduct, please report it
            to the platform administrators
          </p>
        </div>
      </Modal>

      <HomMessages id={id} />
    </div>
  );
}

export default ChatBox;
