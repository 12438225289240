// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAIPxGcjwsmy5zz-I0SjAMpyCQsi9lz-_U",
  authDomain: "ithange-d0a4c.firebaseapp.com",
  projectId: "ithange-d0a4c",
  storageBucket: "ithange-d0a4c.appspot.com",
  messagingSenderId: "187602083358",
  appId: "1:187602083358:web:18d430663314aef1dd980f",
  measurementId: "G-SEV0ZKF34X",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
export { app, auth };
export const genarateToken = async () => {
  const permissiona = await Notification.requestPermission();

  console.log(permissiona);
  if (permissiona === "granted") {
    const token = await getToken(messaging, {
      vapiKey:
        "BC_J7_ddEsenyW0S44VgiJz9rKLoEMYOKeueLxcCgqjBfIMa557UvBIHH_pHdj_URDvl6bkKh38Ooc4J1B2LJ_E",
    });
    console.log(token);
  }
};
