import React, { useContext, useState, useEffect } from "react";
import { Card, Row, Col, Progress } from "antd";
import { RightOutlined, EditOutlined } from "@ant-design/icons";
import AvailableJobs from "./AvailableJobs";
import "./Home.css";
import { myContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import AvailableTalent from "./AvailableTalent";
import TopSearch from "../../../Component/HireTalent/TopSearch";
import Main from "./AllUserGigiWrapper/Main";
import MyServicesLoader from "../../../Component/HomeComponents/myServices/MyServicesLoader";
function AuthHome() {
  const MyUserData = useContext(myContext);
  let Myusername = MyUserData.userName;
  let MyUid = MyUserData.UserUid;
  let isVerified = MyUserData.isEmailVerified;
  let userStatus = MyUserData.status;
  let description = MyUserData.description;
  let userProfile = MyUserData.userProfile;
  let skill = MyUserData.skill;
  let language = MyUserData.language;
  let Fullusername = MyUserData.username;
  let isEmailVerify = MyUserData.isEmailVerified;
  let status = MyUserData.status;
  const [BarShwo, setBarShwo] = useState(20);
  const navigate = useNavigate();

  const handleEdit = () => {
    console.log(isVerified);
    navigate(`/status/${MyUid}`);
  };

  useEffect(() => {
    let progress = 20; // Default progress

    // Check email verification
    if (isVerified) {
      progress += 30;
    } else {
      progress -= 30;
    }

    // Check other conditions
    if (
      description !== "none" &&
      userProfile !== "none" &&
      skill !== "none" &&
      language !== "none"
    ) {
      progress += 20;
    } else {
      // Subtract some percentage if conditions not met
      progress -= 20;
    }

    if (status !== "none" && status !== "pending") {
      progress += 20;
    } else {
      // Subtract some percentage if conditions not met
      progress -= 20;
    }

    if (Fullusername !== "none") {
      progress += 10;
    } else {
      progress -= 10;
    }
    progress = Math.max(0, Math.min(progress, 100));
    setBarShwo(progress);
  }, [
    isVerified,
    description,
    userProfile,
    skill,
    language,
    status,
    Fullusername,
  ]);
  return (
    <div>
      {" "}
      <div
        style={{
          display: BarShwo === 100 || status === "Client" ? "none" : "",
          width: 170,
        }}
      >
        <p>
          Profile Completion:{" "}
          <EditOutlined
            onClick={handleEdit}
            style={{ color: "blue", cursor: "pointer" }}
          />{" "}
          <Progress
            style={{ marginTop: "-2rem" }}
            percent={BarShwo}
            size="small"
            status="active"
          />
        </p>{" "}
      </div>
      <h1 className="HeadmyTo">Hey there, {Myusername}</h1>
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body row">
                {" "}
                <div class="icon mx-3">
                  <svg
                    style={{ alignItems: "right", color: "green" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    fill="currentColor"
                    class="bi bi-briefcase"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5" />
                  </svg>
                </div>
                <div class="text">
                  <h5 class="card-title">Tips To Earn More</h5>
                  <p class="card-text">
                    Ithange help you to saucer your future.
                  </p>
                </div>{" "}
                <div className="rightIcons">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    fill="currentColor"
                    class="bi bi-chevron-compact-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body row">
                {" "}
                <div class="icon mx-3">
                  <svg
                    style={{ alignItems: "right", color: "green" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    fill="currentColor"
                    class="bi bi-buildings"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z" />
                    <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z" />
                  </svg>
                </div>
                <div class="text">
                  <h5 class="card-title">Promote Your Business</h5>
                  <p class="card-text">
                    Ithange help you to promote your business.
                  </p>
                </div>{" "}
                <div className="rightIcons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    fill="currentColor"
                    class="bi bi-chevron-compact-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userStatus === "Freelancer" ? (
        <>
          <AvailableJobs />{" "}
          <div
            class="container-fluid"
            style={{
              width: "90%",
              marginTop: "4rem",
              backgroundColor: "lightblue",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <h1 style={{ margin: "auto 5px" }}>
                Advance Solution <br />
                And Professional <br />
                Talent For <br />
                Business
              </h1>
              <div style={{ height: "30%", width: "30%" }}>
                <MyServicesLoader />
                {/* <img src="./img/sider.png" class="img-fluid" alt="Your Image" /> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Main /> <TopSearch />
          <AvailableTalent />
        </>
      )}
    </div>
  );
}

export default AuthHome;
