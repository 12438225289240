import React from "react";
import UserInfo from "./UserInfo";
import ProfileNav from "./ProfileNav";
import Footer from "../../Component/CommenComponents/Footer";
function Profile() {
  return (
    <div>
      <ProfileNav />
      <UserInfo />
      <Footer />
    </div>
  );
}

export default Profile;
