import React, { useState, useRef, useContext, useEffect } from "react";
import { Input, Divider, message } from "antd";
import { signOut } from "firebase/auth";
import { auth } from "../../Config";
import "../AuthCommen/Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { myContext } from "../../App";
const text = (
  <span>
    <a href="#" style={{ color: "white", textDecoration: "none" }}>
      LogOut
    </a>
    <div className="MyProfileNav">
      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Messages
      </a>
      <br />
      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Notification
      </a>
      <br />{" "}
      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Favorites
      </a>
      <br />{" "}
    </div>

    <div className="Isload">
      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Orders
      </a>

      <a href="#" style={{ color: "white", textDecoration: "none" }}>
        Become Seller
      </a>
      <br></br>
    </div>
  </span>
);
function ProfileNav() {
  const navigate = useNavigate();
  const IsLogged = useContext(myContext);
  const setIslloed = IsLogged.setIsLoggedIn;
  let isloging = IsLogged.IsLoggedIn;
  const handleLogOut = (e) => {
    e.preventDefault();
    signOut(auth)
      .then(() => {
        setIslloed(false);
        navigate("/");
        message.success("Logged out");
      })
      .catch((error) => {
        setIslloed(true);
        message.error("Internal error occurred");
      });
  };
  return (
    <div>
      <nav class="navbar bg-body-tertiary">
        <div class="container-fluid">
          <a id="MyTopNavImageProfile" class="navbar-brand">
            <img
              style={{ width: "100%" }}
              src="/img/black_logo.png"
              alt=""
              srcset=""
            />
          </a>
          <li style={{ margin: "auto 15px", fontWeight: "bold" }}>
            <Link to={"/"}>Home</Link>
          </li>
          <form class="d-flex" role="search">
            <div className="Authmen mx-3" style={{ margin: "auto" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-bell"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-envelope"
                viewBox="0 0 16 16"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-heart"
                viewBox="0 0 16 16"
              >
                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
              </svg>
            </div>
            <button className="btn btn-danger" onClick={handleLogOut}>
              LogOut
            </button>
          </form>
        </div>
      </nav>
      <div></div>
      <Divider style={{ marginTop: ".9rem" }} />
    </div>
  );
}

export default ProfileNav;
