import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { myContext } from "../../App";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../Config";
import { message } from "antd";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

function Jobs() {
  const { clientJobid } = useParams();
  const a = useContext(myContext);
  let userName = a.userName;
  let setJobId = a.setJobId;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    cv: null, // Initialize cv as null
    coverLetter: "",
    jobName: "",
  });
  const [allJobs, setAllJobs] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const fetchJobs = async () => {
    setisLoading(true);
    try {
      const jobsCollection = await getDocs(collection(db, "UpdatedJobs"));
      const usersArray = []; // Create an array to store user data
      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          const userId = doc.data().uid;
          const user = await getUserData(userId);
          // Check if user data exists before accessing its properties
          if (user && Object.keys(user).length > 0) {
            // Push user data into the array
            usersArray.push({
              id: userId,
              name: user.name !== "" ? user.name : "",
              // Use empty string as a fallback if name is undefined
              profilePicture: user.image !== "" ? user.image : "", // Use empty string as a fallback if profilePicture is undefined
            });
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );

      // Set the AllUsers state after the map function has completed
      setAllUsers(usersArray);
      setAllJobs(jobsArray);
      setisLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoading(false);
    }
  };
  const getUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "user", userId));
      return userDoc.exists() ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);
  useEffect(() => {
    console.log(allJobs);
  }, [allJobs]);
  useEffect(() => {
    console.log(AllUsers);
  }, [AllUsers]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      cv: file, // Set cv to the selected file
    });
  };
  const [isApplied, setisApplied] = useState(false);
  const [FileLink, setFileLink] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setisApplied(true);
    console.log(formData);

    try {
      const storageRef = ref(storage, `AplliedCv/${formData.cv.name}`);
      const snapshot = await uploadBytes(storageRef, formData.cv.file);
      console.log("Uploaded a blob or file!", snapshot);
      const downloadURL = await getDownloadURL(snapshot.ref);
      message.success("File Uploaded");
      console.log("File available at", downloadURL);
      setFileLink(downloadURL);
      setisApplied(false);
      message.success("uploaded file");

      const user = auth.currentUser;
      const userId = user ? user.uid : null;

      // Find the job corresponding to clientJobid
      const selectedJob = allJobs.find((job) => job.id === clientJobid);
      console.log(selectedJob);
      const jobPostRef = await addDoc(collection(db, "JobApply"), {
        uid: userId,
        jobId: selectedJob ? selectedJob.uid : "",
        clientName: userName,
        clientJobId: clientJobid,
        userName: formData.name,
        jobName: selectedJob ? selectedJob.job_title : "", // Use selected job's title if found, otherwise use empty string
        userEmail: formData.email,
        coverLater: formData.coverLetter,
        cvLink: downloadURL,
      });
      // const jobId = jobPostRef.id;
      // setJobId(jobId);
      // await updateDoc(jobPostRef, { jobId: jobId });
      message.success("Gig details submitted successfully!");
      setisLoading(false);
    } catch (error) {
      setisApplied(false);
      message.error(
        "Error uploading file or submitting gig details. Please try again."
      );
      console.error(
        "Error uploading file or submitting gig details:",
        error.message
      );
      setisLoading(false);
    }
  };

  useEffect(() => {
    console.log(FileLink);
  }, [FileLink]);

  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  return (
    <div>
      <div class="bradcam_area bradcam_bg_1">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>Software Engineer</h3>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <h1 style={{ textAlign: "center" }}>
        {isLoading && (
          <>
            <h3>Loading Job Details........</h3>
          </>
        )}
      </h1>
      {allJobs
        .filter((data) => data.jobId === clientJobid)
        .map((e) => {
          return (
            <>
              <div class="job_details_area">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="job_details_header">
                        <div class="single_jobs white-bg d-flex justify-content-between">
                          <div class="jobs_left d-flex align-items-center">
                            <div class="thumb">
                              <img src="img/svg_icon/1.svg" alt="" />
                            </div>
                            <div class="jobs_conetent">
                              <a href="#">
                                <h4>{e.job_title}</h4>
                              </a>
                              <div class="links_locat d-flex align-items-center">
                                <div class="location">
                                  <p>
                                    <i class="fa fa-map-marker"></i>{" "}
                                    {e.location}
                                  </p>
                                </div>
                                <div class="location">
                                  <p>
                                    <i class="fa fa-clock-o"></i> {e.jobNature}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="jobs_right">
                            <div class="apply_now">
                              <a class="heart_mark" href="#">
                                <i class="ti-heart"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="descript_wrap white-bg">
                        <div class="single_wrap">
                          <h4>Job description</h4>
                          <p>{e.description}</p>
                          {/* <p>{e.otherRequirements}</p> */}
                        </div>
                        {/* <div class="single_wrap">
                          <h4>Responsibility</h4>
                          <p>{e.jobResponsibilities}</p>
                        </div> */}
                        <div class="single_wrap">
                          <h4>Qualifications</h4>
                          <p>{e.educationRequirements}</p>
                        </div>
                        <div class="single_wrap">
                          <h4>Gender</h4>
                          <p>{e.benefits}</p>
                        </div>
                      </div>
                      <div className="apply_job_form white-bg">
                        <h4>Apply for the job</h4>
                        <br />
                        {isApplied && <h4>Applying Wait......</h4>}
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input_field">
                                <input
                                  type="text"
                                  name="name"
                                  placeholder="Your name"
                                  value={formData.name}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input_field">
                                <input
                                  type="text"
                                  name="email"
                                  placeholder="Email"
                                  value={formData.email}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input_field">
                                <input
                                  type="text"
                                  name="website"
                                  placeholder="Website/Portfolio link"
                                  value={formData.website}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div
                              className="col-md-12"
                              style={{ display: "none" }}
                            >
                              <div className="input_field">
                                <input
                                  type="text"
                                  name="jobName"
                                  placeholder="Website/Portfolio link"
                                  value={e.title}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <button
                                    type="button"
                                    id="inputGroupFileAddon03"
                                  >
                                    <i
                                      className="fa fa-cloud-upload"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="inputGroupFile03"
                                    aria-describedby="inputGroupFileAddon03"
                                    onChange={handleFileChange}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="inputGroupFile03"
                                  >
                                    Upload CV
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input_field">
                                <textarea
                                  name="coverLetter"
                                  cols="30"
                                  rows="10"
                                  placeholder="Cover letter"
                                  value={formData.coverLetter}
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="submit_btn">
                                <button
                                  className="boxed-btn3 w-100"
                                  type="submit"
                                >
                                  Apply Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="job_sumary">
                        <div class="summery_header">
                          <h3>Job Summery</h3>
                        </div>
                        <div class="job_content">
                          <ul>
                            <li>
                              Published on: <span>12 Nov, 2019</span>
                            </li>
                            <li>
                              Job Type: <span>{e.contestOrProject} </span>
                            </li>
                            <li>
                              Salary: <span>{e.price}</span>
                            </li>
                            <li>
                              Required plateForm: <span>{e.plateForm}</span>
                            </li>
                            <li>
                              Required Functionality:{" "}
                              <span>{e.AppPurpose}</span>
                            </li>
                            <li>
                              Job Nature: <span> {e.jobNature}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="share_wrap d-flex">
                        <span>Share at:</span>
                        <ul>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-google-plus"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-envelope"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="job_location_wrap">
                        <div class="job_lok_inner">
                          <div id="map" style={{ height: "200px" }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
}

export default Jobs;
