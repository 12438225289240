import React, { useContext, useRef, useState } from "react";
import {
  Steps,
  Button,
  Form,
  Input,
  Select,
  Table,
  InputNumber,
  message,
  Avatar,
  Divider,
  Alert,
  Card,
} from "antd";

import MyAllServices from "../../../services.json";
import { UploadOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  doc,
  setDoc,
  updateDoc,
  addDoc,
  collection,
  getDoc,
} from "firebase/firestore";
import { storage, db, auth } from "../../../Config";
import { myContext } from "../../../App";
import { useNavigate } from "react-router-dom";

const { Step } = Steps;
const { TextArea } = Input;
const MyForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const onFinish = (values) => {
    // Handle the form submission logic here
    console.log("Form submitted:", values);
  };

  const steps = [
    { title: "Overview", content: GigDetailsForm },
    { title: "Pricing", content: OverviewForm },
    { title: "Gallery", content: PricingForm },
    { title: "Gallery", content: GalleryForm },
    { title: "Publish", content: VerificationForm },
  ];

  const ActiveStep = steps[currentStep].content;

  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <Steps current={currentStep} size="small">
        {steps.map((step, index) => (
          <Step key={index} title={step.title} />
        ))}
      </Steps>
      <div className="steps-content">
        <ActiveStep
          form={form}
          onFinish={onFinish}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      </div>
    </div>
  );
};

const GigDetailsForm = ({ form, nextStep }) => {
  const a = useContext(myContext);
  const UserName = a.userName;
  const setJobId = a.setJobId;
  const [selectedCategory, setSelectedCategory] = useState("");
  const UserProfile = a.userProfile;
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTag = () => {
    if (inputValue.trim() !== "") {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;
      const jobPostRef = await addDoc(collection(db, "jobs"), {
        uid: userId,
        title: e.target.gigTitle.value,
        category: e.target.category.value,
        subCategory: e.target.subcategory.value,
        tags,
        description: "",
        userName: UserName,
        userProfile: UserProfile,
        img1: "",
        img2: "",
        img3: "",
        myVideo: "",
        isEmailVerified: false,
        isComplete: false,
        basics: "",
        basics_description: "",
        basic_price: "",
        basic_revisions: "",
        basic_deleviery: "",
        standard: "",
        standard_description: "",
        standard_price: "",
        standard_revisions: "",
        standard_deleviery: "",
        premium: "",
        premium_description: "",
        premium_price: "",
        premium_revisions: "",
        premium_deleviery: "",
      });
      const jobId = jobPostRef.id;
      setJobId(jobId);
      // Now, update the document with the jobId
      await updateDoc(jobPostRef, { jobId: jobId });
      message.success("Gig details submitted successfully!");
      nextStep();
    } catch (error) {
      console.error("Error submitting gig details:", error.message);
      message.error("Error submitting gig details. Please try again.");
    }
  };
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
  return (
    <>
      <form onSubmit={handleSubmit} style={{ marginTop: "3rem" }}>
        <div className="form-group">
          <label htmlFor="gigTitle">Gig title</label>
          <textarea className="form-control" id="gigTitle" rows="3"></textarea>
          <small className="form-text text-muted">
            As your Gig storefront, your title is the most important place to
            include keywords that buyers would likely use to search for a
            service like yours.
          </small>
        </div>

        <div className="form-group">
          <label htmlFor="category">Select Category</label>
          <div className="row">
            <div className="col">
              <select
                className="form-control"
                id="category"
                onChange={handleCategoryChange}
              >
                <option value="">Select Category</option>
                {MyAllServices.categories.map((e, index) => (
                  <option key={index} value={e.name}>
                    {e.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col" style={{ marginTop: "-2rem" }}>
              {selectedCategory &&
                MyAllServices.categories.find(
                  (category) => category.name === selectedCategory
                ) && (
                  <>
                    <label htmlFor="subcategory">Subcategory</label>
                    <select className="form-control" id="subcategory">
                      <option value="">Select Subcategory</option>
                      {MyAllServices.categories
                        .find((category) => category.name === selectedCategory)
                        .subcategories.map((subcategory, index) => (
                          <option key={index} value={subcategory}>
                            {subcategory}
                          </option>
                        ))}
                    </select>
                  </>
                )}
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="tags">Tags</label>
          <div className="row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                id="tags"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddTag}
              >
                Add Tag
              </button>
            </div>
          </div>
          <div style={{ marginTop: "8px" }}>
            {tags.map((tag, index) => (
              <span
                key={index}
                className="badge badge-primary mr-2 mb-2"
                style={{ cursor: "pointer" }}
                onClick={() => handleRemoveTag(tag)}
              >
                {tag} &times;
              </span>
            ))}
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </form>
    </>
  );
};

const OverviewForm = ({ form, nextStep, prevStep }) => {
  const a = useContext(myContext);
  const UserId = a.UserUid;
  const myjobId = a.jobId;
  const [packageData, setPackageData] = useState({
    basic: { description: "", revisions: "", deliveryDays: 5, price: 0 },
    standard: { description: "", revisions: "", deliveryDays: 5, price: 0 },
    premium: { description: "", revisions: "", deliveryDays: 5, price: 0 },
  });

  const handleDescriptionChange = (packageType, value) => {
    setPackageData((prevData) => ({
      ...prevData,
      [packageType]: { ...prevData[packageType], description: value },
    }));
  };

  const handleRevisionChange = (packageType, value) => {
    setPackageData((prevData) => ({
      ...prevData,
      [packageType]: { ...prevData[packageType], revisions: value },
    }));
  };

  const handleDeliveryChange = (packageType, value) => {
    setPackageData((prevData) => ({
      ...prevData,
      [packageType]: { ...prevData[packageType], deliveryDays: value },
    }));
  };
  const handlePriceChange = (packageType, value) => {
    setPackageData((prevData) => ({
      ...prevData,
      [packageType]: { ...prevData[packageType], price: value },
    }));
  };

  const onFinish = async (values) => {
    console.log(myjobId);

    try {
      const userDocRef = doc(db, "jobs", myjobId);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const jobId = userDocSnapshot.data().jobId;
        console.log(jobId);
        if (jobId) {
          const jobPostRef = doc(db, "jobs", myjobId);
          await updateDoc(jobPostRef, {
            basics: "BASIC",
            basics_description: packageData.basic.description,
            basic_price: packageData.basic.price,
            basic_revisions: packageData.basic.revisions,
            basic_deleviery: packageData.basic.deliveryDays,
            standard: "STANDARD",
            standard_description: packageData.standard.description,
            standard_price: packageData.standard.price,
            standard_revisions: packageData.standard.revisions,
            standard_deleviery: packageData.standard.deliveryDays,
            premium: "PREMIUM",
            premium_description: packageData.standard.description,
            premium_price: packageData.standard.price,
            premium_deleviery: packageData.standard.deliveryDays,
            premium_revisions: packageData.standard.revisions,
          });

          message.success("Price Added Successfully");
          nextStep();
        } else {
          console.error(
            "Error: jobId is undefined or null in userGigs document"
          );
          message.error("Error updating gig details. jobId is missing.");
        }
      } else {
        console.error("Error: userDocSnapshot does not exist");
        message.error(
          "Error updating gig details. User document does not exist."
        );
      }
    } catch (error) {
      console.error("Error updating user status:", error.message);
      message.error("Error updating gig details. Please try again.");
    }
    const onFinish = async (values) => {
      console.log("Package Data:", packageData);
      console.log("Overview submitted:", values);
    };
  };

  const columns = [
    {
      title: "Package",
      dataIndex: "package",
      key: "package",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, record) => (
        <Input.TextArea
          rows={2}
          placeholder={`Enter description for ${record.package}`}
          onChange={(e) =>
            handleDescriptionChange(
              record.package.toLowerCase(),
              e.target.value
            )
          }
        />
      ),
    },
    {
      title: "Revisions",
      dataIndex: "revisions",
      key: "revisions",
      render: (_, record) => (
        <InputNumber
          min={0}
          onChange={(value) =>
            handleRevisionChange(record.package.toLowerCase(), value)
          }
        />
      ),
    },
    {
      title: "Delivery Days",
      dataIndex: "deliveryDays",
      key: "deliveryDays",
      render: (_, record) => (
        <Select
          defaultValue={packageData[record.package.toLowerCase()].deliveryDays}
          onChange={(value) =>
            handleDeliveryChange(record.package.toLowerCase(), value)
          }
        >
          <Select.Option value={5}>5</Select.Option>
          <Select.Option value={15}>15</Select.Option>
          <Select.Option value={30}>30</Select.Option>
          <Select.Option value={40}>40</Select.Option>
          <Select.Option value={60}>60</Select.Option>
        </Select>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => (
        <InputNumber
          min={0}
          onChange={(value) =>
            handlePriceChange(record.package.toLowerCase(), value)
          }
        />
      ),
    },
  ];

  const data = [
    {
      key: "1",
      package: "Basic",
    },
    {
      key: "2",
      package: "Standard",
    },
    {
      key: "3",
      package: "Premium",
    },
  ];

  return (
    <Form form={form} layout="vertical" style={{ marginTop: "3rem" }}>
      <Table columns={columns} dataSource={data} pagination={false} />

      <Form.Item>
        <Button onClick={prevStep}>Previous</Button>
        <Button className="mx-3" type="primary" onClick={onFinish}>
          Next
        </Button>
      </Form.Item>
    </Form>
  );
};
const PricingForm = ({ form, nextStep, prevStep }) => {
  const onFinish = (values) => {
    // Handle Pricing form submission logic here
    console.log("Pricing submitted:", values);
    nextStep();
  };
  const [editorContent, setEditorContent] = useState("");

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "list",
    "bullet",
    "link",
    "image",
  ];
  const a = useContext(myContext);
  const myjobId = a.jobId;
  const [Decsription, setDecsription] = useState("");

  const addDescription = async () => {
    if (Decsription !== "") {
      console.log(Decsription);
      try {
        const userDocRef = doc(db, "jobs", myjobId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const jobId = userDocSnapshot.data().jobId;
          console.log(jobId);

          if (jobId) {
            const jobPostRef = doc(db, "jobs", myjobId);
            await updateDoc(jobPostRef, {
              description: Decsription,
            });
            message.success("Description Added Successfully");
            nextStep();
          }
        }
      } catch (error) {
        message.error("Description not Added try again later");
        console.error("Error updating user status:", error.message);
      }
    } else {
      console.log("please enter description");
    }
  };
  const handleChange = (content) => {
    setEditorContent(content);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ marginTop: "2rem" }}
    >
      {/* <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={editorContent}
        onChange={handleChange}
      /> */}
      <TextArea
        minLength={100}
        onChange={(e) => setDecsription(e.target.value)}
      ></TextArea>
      <Form.Item className="my-3">
        <Button className="mx-3" type="primary" onClick={addDescription}>
          Next
        </Button>
        <Button onClick={prevStep}>Previous</Button>
      </Form.Item>
    </Form>
  );
};

const GalleryForm = ({ prevStep, nextStep }) => {
  const a = useContext(myContext);
  const UserId = a.UserUid;
  const [profilePic1, setProfilePic1] = useState(null);
  const [profilePic2, setProfilePic2] = useState(null);
  const [profilePic3, setProfilePic3] = useState(null);
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const handleAvatarClick = (fileInputRef, setProfilePic) => {
    fileInputRef.current.click();
  };

  const [ImageLoading, setImageLoading] = useState(false);
  const handleProfilePicChange = (file, setProfilePic) => {
    setImageLoading(true);
    if (file) {
      const storageRef = ref(storage, `UserGigsImages/${file.name}`);

      uploadBytes(storageRef, file)
        .then((snapshot) => {
          console.log("Uploaded a blob or file!", snapshot);
          return getDownloadURL(snapshot.ref);
        })
        .then((downloadURL) => {
          message.success("File Uploaded");
          console.log("File available at", downloadURL);
          setProfilePic(downloadURL);
          setImageLoading(false);
        })
        .catch((error) => {
          message.error("Error uploading file");
          console.error("Error uploading file: ", error);
          message.error(`${file.name} file upload failed.`);
          setImageLoading(false);
        });
    } else {
      message.error("No file selected");
      console.log("No file selected");
      setImageLoading(false);
    }
  };
  const [videoUrl, setVideoUrl] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const handleVideoUpload = (file) => {
    setisLoading(true);
    if (file) {
      const storageRef = ref(storage, `GigsVideo/${file.name}`);

      uploadBytes(storageRef, file)
        .then((snapshot) => {
          console.log("Uploaded a blob or file!", snapshot);
          return getDownloadURL(snapshot.ref);
        })
        .then((downloadURL) => {
          message.success("Video Uploaded");
          console.log("Video available at", downloadURL);
          setVideoUrl(downloadURL);
          message.success("Video Uploaded");
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
          message.error("Error uploading video");
          console.error("Error uploading video: ", error);
          message.error(`${file.name} video upload failed.`);
        });
    } else {
      message.error("No file selected");
      console.log("No file selected");
    }
  };

  const myjobId = a.jobId;
  const handleUploadGallery = async () => {
    try {
      const userDocRef = doc(db, "jobs", myjobId);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const jobId = userDocSnapshot.data().jobId;
        console.log(jobId);

        if (jobId) {
          const jobPostRef = doc(db, "jobs", myjobId);
          await updateDoc(jobPostRef, {
            img1: profilePic1, // You can set these to empty strings for now and update them later
            img2: profilePic2,
            img3: profilePic3,
            myVideo: "adhasdfsydfasdfsdhfgsdy",
          });
          message.success("Gallery Added Successfully");
          nextStep();
        }
      }
    } catch (error) {
      console.error("Error updating user status:", error.message);
    }
  };

  return (
    <>
      <div style={{ alignItems: "left", marginTop: "2rem" }}>
        {" "}
        <h2>Showcase Your Services In A Gig Gallery</h2>
        <br />{" "}
        <p style={{ marginTop: "-1rem" }}>
          Encourage buyers to choose your Gig by featuring a variety of your
          work
        </p>
        <div class="alert alert-success" role="alert">
          <h4 class="alert-heading">Dear iThange users!</h4>
          <p>
            Please ensure uploaded images distinctly showcase your work or hold
            necessary licenses. Avoid posting unrelated content. This ensures a
            quality platform for job posting and talent showcasing.
          </p>
          <hr />
          <p class="mb-0">
            Thank you for your cooperation in maintaining the integrity of
            iThange.ee.
          </p>
        </div>
        <h3>Images (up to 3)</h3>
        <br />
        <p style={{ marginTop: "-1rem" }}>
          Get noticed by the right buyers with visual examples of your services.
        </p>
        <br />
        {ImageLoading ? <>Loading....</> : ""}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "16px",
          marginTop: "1rem",
        }}
      >
        <div className="card" style={{ width: "200px", height: "240px" }}>
          <Form style={{ margin: "auto" }}>
            <Form.Item>
              <input
                type="file"
                name="image"
                ref={fileInputRef1}
                onChange={(e) =>
                  handleProfilePicChange(e.target.files[0], setProfilePic1)
                }
                style={{ display: "none" }}
              />
              <Avatar
                style={{ marginLeft: "1.1rem" }}
                size={64}
                icon={<UploadOutlined />}
                src={profilePic1}
                onClick={() => handleAvatarClick(fileInputRef1, setProfilePic1)}
              />{" "}
              <br />
              <p>Upload Photo</p>
            </Form.Item>
          </Form>
        </div>
        <div className="card" style={{ width: "200px", height: "240px" }}>
          <Form style={{ margin: "auto" }}>
            <Form.Item>
              <input
                type="file"
                name="image"
                ref={fileInputRef2}
                onChange={(e) =>
                  handleProfilePicChange(e.target.files[0], setProfilePic2)
                }
                style={{ display: "none" }}
              />
              <Avatar
                style={{ marginLeft: "1.1rem" }}
                size={64}
                icon={<UploadOutlined />}
                src={profilePic2}
                onClick={() => handleAvatarClick(fileInputRef2, setProfilePic2)}
              />{" "}
              <br />
              <p>Upload Photo</p>
            </Form.Item>
          </Form>
        </div>
        <div className="card" style={{ width: "200px", height: "240px" }}>
          <Form style={{ margin: "auto" }}>
            <Form.Item>
              <input
                type="file"
                name="image"
                ref={fileInputRef3}
                onChange={(e) =>
                  handleProfilePicChange(e.target.files[0], setProfilePic3)
                }
                style={{ display: "none" }}
              />
              <Avatar
                style={{ marginLeft: "1.1rem" }}
                size={64}
                icon={<UploadOutlined />}
                src={profilePic3}
                onClick={() => handleAvatarClick(fileInputRef3, setProfilePic3)}
              />{" "}
              <br />
              <p>Upload Photo</p>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Divider />
      <h3 style={{ marginTop: "2rem" }}> Video one only</h3> <br />{" "}
      <p style={{ fontSize: "1.3rem", marginTop: "-1rem" }}>
        Capture buyers' attention with a video that showcases your service.
      </p>{" "}
      <br />{" "}
      <p style={{ fontWeight: "bold", marginTop: "-1.8rem" }}>
        Please choose a video shorter than 75 seconds and smaller than 50M
      </p>{" "}
      {isLoading ? (
        <>
          <p>Uploading.....</p>
        </>
      ) : (
        ""
      )}
      {/* <div className="card" style={{ width: "230px", height: "270px" }}>
        <Form style={{ margin: "auto" }}>
          <Form.Item>
            <input
              type="file"
              name="video"
              ref={fileInputRef}
              onChange={(e) => handleVideoUpload(e.target.files[0])}
              accept="video/*"
              style={{ display: "none" }}
            />
            <Avatar
              style={{ marginLeft: "1.1rem" }}
              size={84}
              icon={<VideoCameraOutlined />}
              src={videoUrl}
              onClick={() => handleAvatarClick(fileInputRef)}
            />
          </Form.Item>
        </Form>
        <Button type="primary" disabled={!videoUrl}>
          Update Video
        </Button>
      </div>{" "} */}
      <Button className="mx-3" type="primary" onClick={handleUploadGallery}>
        Next
      </Button>
      <Button onClick={prevStep}>Previous</Button>
    </>
  );
};
const VerificationForm = ({ form, nextStep, prevStep }) => {
  const navigate = useNavigate();
  const a = useContext(myContext);
  const myjobId = a.jobId;
  const isVerifyUser = a.isEmailVerified;
  const UserId = a.UserUid;
  const onFinish = async () => {
    try {
      const userDocRef = doc(db, "jobs", myjobId);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const jobId = userDocSnapshot.data().jobId;
        console.log(jobId);

        if (jobId) {
          const jobPostRef = doc(db, "jobs", myjobId);
          await updateDoc(jobPostRef, {
            isEmailVerified: isVerifyUser,
            isComplete: true,
          });
          message.success("Congratulation! Your gig is successfully published");
          setTimeout(() => {
            navigate("/profile/khan");
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Error updating user status:", error.message);
    }
  };

  return (
    <Form form={form} layout="vertical" style={{ marginTop: "2rem" }}>
      <h2>Email Verification</h2>

      <Card>
        {isVerifyUser ? (
          <>
            {" "}
            <Alert
              message="Email Already Verified"
              description="You have already verified your email during signUp Process Verified email lead to high selling on Ithange.ee."
              type="success"
              showIcon
            />{" "}
          </>
        ) : (
          <>
            <Alert
              message="Email Not Verified"
              description="You have not verified your email during signUp Process keep in mind non verified email lead to low selling on Ithange.ee."
              type="alert"
            />
          </>
        )}{" "}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            margin: "auto",
          }}
        >
          <div style={{ width: "280px", height: "380px", margin: "auto" }}>
            {" "}
            <img
              style={{ width: "100%", height: "100%" }}
              src="https://img.freepik.com/free-photo/rag-doll-with-large-green-pencil_1156-208.jpg?w=360&t=st=1707029022~exp=1707029622~hmac=a6df414bae25215a7f74a63f4a9811b44261a83c4e52ad512409e49765db07b8"
              alt="loading"
            />
          </div>
          <div style={{ margin: "-2rem auto" }}>
            <h2 style={{ textAlign: "center" }}>You're almost there!</h2>
            <br />
            <p style={{ textAlign: "center", marginTop: "-1.4rem" }}>
              Let’s publish your Gig and get you ready to start selling.
            </p>
          </div>
        </div>
      </Card>

      <Form.Item style={{ marginTop: "1.5rem" }}>
        {" "}
        <Button className="mx-3" onClick={prevStep}>
          Previous
        </Button>
        <Button
          style={{ backgroundColor: "black", color: "white" }}
          onClick={onFinish}
        >
          Publish Now
        </Button>
      </Form.Item>
    </Form>
  );
};
export default MyForm;
