import React, { useContext } from "react";
import Talent from "./Talent";
import Navbar from "../CommenComponents/Navbar";
import Footer from "../CommenComponents/Footer";
import { myContext } from "../../App";
import AuthNavbar from "../../AuthComponents/AuthCommen/AuthNavbar";
import New from "./New";

function HireTalentMain() {
  const ContextData = useContext(myContext);
  const isAuth = ContextData.IsLoggedIn;
  return (
    <div>
      {" "}
      {isAuth ? (
        <>
          <AuthNavbar /> <New />
          <Talent />
        </>
      ) : (
        <>
          <Navbar />
          <Talent />
          <New />
        </>
      )}
      <Footer />
    </div>
  );
}

export default HireTalentMain;
