import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../Config";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Badge } from "antd";
function TopSearch() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [location, setLocation] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [AllGigs, setAllGigs] = useState([]);
  const fetchAllGigs = async () => {
    try {
      const jobCollection = collection(db, "jobs"); // Reference to the "jobs" collection
      const snapshot = await getDocs(jobCollection); // Fetch data from Firestore
      const jobsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return jobsData;
    } catch (error) {
      console.error("Error fetching jobs:", error);
      throw error;
    }
  };

  const [NoFoundText, setNoFoundText] = useState("");
  let Verify;
  const handleSearch = async () => {
    console.log(priceRange);
    try {
      if (location === "verified") {
        Verify = true;
      } else {
        Verify = false;
      }
      const jobsData = await fetchAllGigs();
      const filteredGigs = jobsData.filter(
        (gig) =>
          gig.tags.includes(searchQuery) ||
          gig.isEmailVerified === Verify ||
          gig.basic_price <= parseInt(priceRange)
      );
      if (filteredGigs.length === 0) {
        setNoFoundText("No gigs found matching the search criteria");
        setTimeout(() => {
          setNoFoundText("");
        }, 3000);
      }
      setAllGigs(filteredGigs);
      console.log("Filtered Jobs with Users:", filteredGigs);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleChangeLocation = (e) => {
    setLocation(e.target.value);
  };

  const handleChangePriceRange = (e) => {
    setPriceRange(e.target.value);
  };

  const handleGoToProfile = (userId) => {
    navigate(`/Hire-Talent/user-profile/${userId}`);
  };
  return (
    <div style={{ marginTop: "-2rem" }}>
      <div class="catagory_area">
        <div class="container">
          <div class="row cat_search">
            <div class="col-lg-3 col-md-4">
              <div class="single_input">
                <input
                  onChange={handleChangeSearchQuery}
                  type="text"
                  placeholder="Search keyword"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="form-row">
                <div class="col">
                  <select
                    class="form-control"
                    onChange={handleChangeLocation}
                    style={{ height: "3rem" }}
                  >
                    <option value="">Combined Users</option>
                    <option value="verified">Verified</option>
                    <option value="non-verified">Non-Verified</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="single_input">
                <div class="form-row">
                  <div class="col">
                    <select
                      class="form-control"
                      onChange={handleChangePriceRange}
                      style={{ height: "3rem" }}
                    >
                      <option value="">Price Range</option>
                      <option value="50">0-$50</option>
                      <option value="250">50-$250</option>
                      <option value="500">250-$500</option>
                      <option value="More">More then $500</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="job_btn">
                <button class="boxed-btn3" onClick={handleSearch}>
                  Find Freelancer
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="popular_search d-flex align-items-center">
                <span>Popular Search:</span>
                <ul>
                  <li>
                    <a href="#">Design & Creative</a>
                  </li>
                  <li>
                    <a href="#">Marketing</a>
                  </li>
                  <li>
                    <a href="#">Administration</a>
                  </li>
                  <li>
                    <a href="#">Teaching & Education</a>
                  </li>
                  <li>
                    <a href="#">Engineering</a>
                  </li>
                  <li>
                    <a href="#">Software & Web</a>
                  </li>
                  <li>
                    <a href="#">Telemarketing</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "16px",
          marginTop: "2rem",
        }}
      >
        {AllGigs.length === 0 ? (
          <>
            {" "}
            <h2 style={{ color: "gray", fontStyle: "italic" }}>
              {NoFoundText}
            </h2>{" "}
          </>
        ) : (
          <>
            {searchQuery !== "" || priceRange !== "" || location !== "" ? (
              <>
                {AllGigs.map((e, index) => {
                  const carouselId = `carouselExampleIndicators-${index}`;
                  const carouselTarget = `#carouselExampleIndicators-${index}`;
                  return (
                    <div className="card" id="MyUserCradsService" key={index}>
                      <div
                        className="carousel slide"
                        data-ride="carousel"
                        id={carouselId}
                      >
                        <div class="carousel-inner" style={{ width: "100%" }}>
                          <div class="carousel-item active">
                            <img
                              class="d-block w-100"
                              src={e.img1}
                              alt="First slide"
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              class="d-block w-100"
                              src={e.img2}
                              alt="Second slide"
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              class="d-block w-100"
                              src={e.img3}
                              alt="Third slide"
                            />
                          </div>
                        </div>
                        <a
                          style={{ marginTop: "-6rem" }}
                          className="carousel-control-prev"
                          href={carouselTarget}
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          style={{ marginTop: "-6rem" }}
                          className="carousel-control-next"
                          href={carouselTarget}
                          role="button"
                          data-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>

                      <div class="card-body">
                        {" "}
                        <div class="d-flex">
                          <div class="user-profile-picture mr-3">
                            <Badge
                              dot
                              color="green"
                              style={{ marginRight: "4px", marginTop: "31px" }}
                            >
                              <Avatar
                                onClick={() => handleGoToProfile(e.uid)}
                                style={{ cursor: "pointer" }}
                                size={40}
                                src={
                                  e.userProfile !== "none"
                                    ? e.userProfile
                                    : "./img/candiateds/1.png"
                                }
                              />{" "}
                              <div
                                style={{
                                  position: "absolute",
                                  top: 74,
                                  right: 8,
                                  // background: "#52c41a",
                                  borderRadius: "50%",
                                  width: 12,
                                  height: 12,
                                }}
                              ></div>
                            </Badge>
                          </div>
                          <div class="user-detail">
                            <h6 style={{ margin: "auto" }}>
                              {e.userName}{" "}
                              {e.isEmailVerified ? (
                                <>
                                  <svg
                                    style={{ color: "blue" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="currentColor"
                                    class="bi bi-check-circle-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                </>
                              ) : (
                                ""
                              )}
                            </h6>
                            <Link style={{ margin: "auto", fontSize: ".8rem" }}>
                              Web Developer
                            </Link>
                          </div>
                        </div>
                        <Link
                          to={`/Gigs-details/${e.jobId}`}
                          style={{
                            textAlign: "left",
                            marginTop: "3rem",
                            textDecoration: "underline",
                          }}
                        >
                          {e.description && e.description.slice(0, 60)}.....
                        </Link>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "1.5rem",
                            cursor: "pointer",
                          }}
                        >
                          <svg
                            style={{ color: "green" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-chat-left-text"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                          </svg>
                          <svg
                            style={{ color: "red" }}
                            // onClick={fetchJobs}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-heart"
                            viewBox="0 0 16 16"
                          >
                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default TopSearch;
