import React, { useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Chat from '../components/Chat';
import { useState } from 'react';
import '../style.css'
const Home = ({id}) => {
  const [isShow, setisShow] = useState(true);const [isSlider, setisSlider] = useState(true)
console.log(isShow); 

  return (
    <>
      <div className='home'>
        <div className="container"> 
           <div className={isShow ?   "mySider" : "mysekSider"}  >
            <Sidebar id={id} setisShow={setisShow} isShow={ isShow} />   
          </div>  
         
          <div  className= {isShow ?   "myShowSider" : "myHideSider"}  >
            <Chat setisShow={setisShow}/>
          </div>   
        </div>
      </div>
    </>
  );
}

export default Home;
