import React, { useState, useEffect } from "react";
import First from "./PostSubCom/First";
import Second from "./PostSubCom/Second";
import Third from "./PostSubCom/Third";
import Fourth from "./PostSubCom/Fourth";
import Six from "./PostSubCom/Six";
import { useParams } from "react-router-dom";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../../Config";

function PostMainComponent() {
  const { job_post_id } = useParams();
  const [StepStage, setStepStage] = useState(1);
  const [jobData, setJobData] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "UpdatedJobs"));
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.jobId === job_post_id) {
            setJobData(data);
            if (data.AppPurpose !== "" && data.plateForm !== "") {
              setStepStage(3);
            } else if (data.description !== "") {
              setStepStage(4);
            } else if (
              data.price !== "" &&
              data.contestOrProject !== "" &&
              data.duration !== "" &&
              data.pakage !== ""
            ) {
              setStepStage(6);
            } else if (data.job_title !== "") {
              setStepStage(2);
            }
          }
        });
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    if (job_post_id !== "job_post") {
      fetchJobs();
    }
  }, [job_post_id]);

  return (
    <div>
      {StepStage === 1 && <First setStepStage={setStepStage} />}
      {StepStage === 2 && <Second setStepStage={setStepStage} />}
      {StepStage === 3 && <Third setStepStage={setStepStage} />}
      {StepStage === 4 && <Fourth setStepStage={setStepStage} />}
      {StepStage === 6 && <Six setStepStage={setStepStage} />}
    </div>
  );
}

export default PostMainComponent;
