import React from "react";

function Choose() {
  return (
    <div>
      <div class="bradcam_area bradcam_bg_1">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>Why Choose Us</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="container mt-5">
        <h2 class="text-center mb-4">Why Choose Us</h2>

        <div class="row">
          <div class="col-lg-4">
            <div class="feature-item">
              <img
                style={{ height: "7rem" }}
                src="https://img.freepik.com/free-photo/business-man-sitting-using-laptop_1401-475.jpg?w=360&t=st=1706168437~exp=1706169037~hmac=eeb01ac5030f5a09eda9a07bfa52819bfc1de7e88c4ead69c9f2bca7d4dc31d9"
                alt="Feature Icon"
                class="feature-icon"
              />

              <h4>Wide Range of Jobs</h4>
              <p>
                Explore a diverse range of freelance jobs tailored to your
                skills and expertise.
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="feature-item">
              <img
                style={{ height: "7rem" }}
                src="https://img.freepik.com/free-photo/3d-render-online-payment-transaction-security_107791-16637.jpg?w=360&t=st=1706168167~exp=1706168767~hmac=48cd018b1bbfa60fd86b32fbd32447eb3441d43c9bdbe463da706b558d558d58"
                alt="Feature Icon"
                class="feature-icon"
              />
              <h4>Secure Payment System</h4>
              <p>
                Our platform ensures safe and secure payment transactions for
                both employers and freelancers.
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="feature-item">
              <img
                style={{ height: "7rem" }}
                src="https://img.freepik.com/free-vector/nice-infographic-about-teamwork_1010-536.jpg?w=360&t=st=1706168262~exp=1706168862~hmac=9dbe5db703ed751987436921be1d7dd8bee6c9cc00b3bd46fd119ca5a3767cca"
                alt="Feature Icon"
                class="feature-icon"
              />
              <h4>Efficient Communication</h4>
              <p>
                Stay connected with employers or freelancers through our
                efficient communication tools.
              </p>
            </div>
          </div>
        </div>
      </section>

      <h3 style={{ textAlign: "center" }}>
        We Have Experienced Developers And Designer
      </h3>
      <div class="featured_candidates_area candidate_page_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/1.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/2.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/3.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/4.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/5.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/6.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/7.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/8.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/9.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/10.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/3.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/4.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="pagination_wrap">
                <ul>
                  <li>
                    <a href="/">
                      {" "}
                      <i class="ti-angle-left"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <span>01</span>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <span>02</span>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      {" "}
                      <i class="ti-angle-right"></i>{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Choose;
