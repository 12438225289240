import React, { useState, useEffect } from "react";
import "./style.css";
import { Divider } from "antd";
function Five({ setSelectedNames }) {
  const [selectedItems, setSelectedItems] = useState({});
  const arr = [
    {
      description:
        "   Lorem ipsum dolor sit amet, consectetur adipisicing elit.  cupiditate, quam dignissimos quod hic at fugit est animi dolore quoid placeat eveniet. Quasi perferendis fugiat facere, a reiciendis nisi. nisi.",
      name: "GUARANTEED",
      status: "FREE",
    },
    {
      description:
        "   Lorem ipsum dolor sit amet, consectetur adipisicing elit.  cupiditate, quam dignissimos quod hic at fugit est animi dolore quoid placeat eveniet. Quasi perferendis fugiat facere, a reiciendis nisi. nisi.",
      name: "FEATURED",
      status: "$300.00",
    },
    {
      description:
        "   Lorem ipsum dolor sit amet, consectetur adipisicing elit.  cupiditate, quam dignissimos quod hic at fugit est animi dolore quoid placeat eveniet. Quasi perferendis fugiat facere, a reiciendis nisi. nisi.",
      name: "Top CONTEST",
      status: "$300.00",
    },
    {
      description:
        "   Lorem ipsum dolor sit amet, consectetur adipisicing elit.  cupiditate, quam dignissimos quod hic at fugit est animi dolore quoid placeat eveniet. Quasi perferendis fugiat facere, a reiciendis nisi. nisi.",
      name: "HIGHLIGHT",
      status: "$300.00",
    },
    {
      description:
        "   Lorem ipsum dolor sit amet, consectetur adipisicing elit.  cupiditate, quam dignissimos quod hic at fugit est animi dolore quoid placeat eveniet. Quasi perferendis fugiat facere, a reiciendis nisi. nisi.",
      name: "PRIVATE",
      status: "$300.00",
    },
  ];
  const handleCheckboxChange = (e, name) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [name]: e.target.checked,
    }));
  };

  const handleConfirmSelection = () => {
    const selectedNames = Object.keys(selectedItems).filter(
      (name) => selectedItems[name]
    );
    setSelectedNames(selectedNames);
  };
  useEffect(() => {
    handleConfirmSelection();
  }, [selectedItems]);
  return (
    <div>
      <div className="container" style={{ margin: "auto", width: "90%" }}>
        {arr.map((e) => {
          return (
            <>
              <div className="myWidthCards">
                {" "}
                <div style={{ margin: "auto" }}>
                  {" "}
                  <input
                    class="form-check-input"
                    type="checkbox"
                    onChange={(event) => handleCheckboxChange(event, e.name)}
                  />
                  <p
                    style={{
                      width: "150px",
                      padding: "2px 10px",
                      color: "white",
                      textAlign: "center",
                      backgroundColor:
                        e.name === "FREE"
                          ? "green"
                          : e.name === "FEATURED"
                          ? "red"
                          : e.name === "Top CONTEST"
                          ? "rgb(68, 55, 55)"
                          : e.name === "HIGHLIGHT"
                          ? "blue"
                          : e.name === "PRIVATE"
                          ? "rgb(159, 149, 35)"
                          : "green",
                      borderRadius: "20px",
                      //   position: "absolute",
                    }}
                  >
                    {e.name}
                  </p>
                </div>
                <p className="f mx-2" style={{ textAlign: "center" }}>
                  {e.description}
                </p>
                <p style={{ fontWeight: "bold", color: "black" }}>{e.status}</p>
              </div>
              <Divider />
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Five;
