import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
function Testamonial() {
  const users = [
    {
      name: "Micky Mouse",
      img: "/img/testmonial/author.png",
      testimonial:
        "Working in conjunction with humanitarian aid agencies, we have supported programmes to help alleviate human suffering through animal welfare when people might depend on livestock as their only source of income or food.",
    },
    {
      name: "Lee",
      img: "/img/property/1.png",
      testimonial:
        "Working in conjunction with humanitarian aid agencies, we have supported programmes to help alleviate human suffering through animal welfare when people might depend on livestock as their only source of income or food.",
    },
    // Add more users as needed
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    AOS.init({
      duration: 3000, // You can customize the duration here
    });
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % users.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? users.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    AOS.refresh();
  }, [currentIndex]); // Refresh AOS when currentIndex changes
  return (
    <div>
      <div
        id="AllContent"
        class="container"
        style={{ backgroundColor: "#1b67aa" }}
      >
        <div class="MyCatsdasd-container">
          <div class="heading-column">
            <h1
              style={{ color: "white", padding: "1rem", textAlign: "center" }}
            >
              Get Work Done By <br />
              By Ithange
              <br />
              Freelancers
            </h1>
          </div>

          <div
            class="list-column"
            style={{
              display: "flex",
              margin: "auto",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div class="column mx-4">
              <ul class="list">
                <li class="list-item">Web Development</li>
                <li class="list-item">App Development</li>
                <li class="list-item">Android Development</li>
                <li class="list-item">Graphics</li>
                <li class="list-item">Digital Marketing</li>
              </ul>
            </div>
            <div class="column mx-4">
              <ul class="list">
                <li class="list-item">Logo Design</li>
                <li class="list-item">Content Writing</li>
                <li class="list-item">PhotoGraphy</li>
                <li class="list-item">Video Editing</li>
                <li class="list-item">Picture Editing</li>
              </ul>
            </div>
            <div class="column mx-4">
              <ul class="list">
                <li class="list-item">Logo Design</li>
                <li class="list-item">Content Writing</li>
                <li class="list-item">PhotoGraphy</li>
                <li class="list-item">Video Editing</li>
                <a href="/" class="list-item" style={{ color: "white" }}>
                  See More
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <h2 className="text-center my-4">Featured Candidates Of Ithange</h2>
      <div class="featured_candidates_area candidate_page_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/1.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/2.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/3.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/4.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/5.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/6.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/7.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/8.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/9.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/10.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/3.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single_candidates text-center">
                <div class="thumb">
                  <img src="img/candiateds/4.png" alt="" />
                </div>
                <a href="/">
                  <h4>Markary Jondon</h4>
                </a>
                <p>Software Engineer</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="pagination_wrap">
                <ul>
                  <li>
                    <a href="/">
                      {" "}
                      <i class="ti-angle-left"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <span>01</span>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <span>02</span>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      {" "}
                      <i class="ti-angle-right"></i>{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="top_companies_area">
          <div class="container">
            <div class="row align-items-center mb-40">
              <div class="col-lg-6 col-md-6">
                <div class="section_title">
                  <h3>Top Companies</h3>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="brouse_job text-right">
                  <a href="jobs.html" class="boxed-btn4">
                    Browse More Job
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-xl-3 col-md-6">
                <div class="single_company">
                  <div class="thumb">
                    <img src="img/svg_icon/5.svg" alt="" />
                  </div>
                  <a href="jobs.html">
                    <h3>Snack Studio</h3>
                  </a>
                  <p>
                    <span>50</span> Available position
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-xl-3 col-md-6">
                <div class="single_company">
                  <div class="thumb">
                    <img src="img/svg_icon/4.svg" alt="" />
                  </div>
                  <a href="jobs.html">
                    <h3>Snack Studio</h3>
                  </a>
                  <p>
                    <span>50</span> Available position
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-xl-3 col-md-6">
                <div class="single_company">
                  <div class="thumb">
                    <img src="img/svg_icon/3.svg" alt="" />
                  </div>
                  <a href="jobs.html">
                    <h3>Snack Studio</h3>
                  </a>
                  <p>
                    <span>50</span> Available position
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-xl-3 col-md-6">
                <div class="single_company">
                  <div class="thumb">
                    <img src="img/svg_icon/1.svg" alt="" />
                  </div>
                  <a href="jobs.html">
                    <h3>Snack Studio</h3>
                  </a>
                  <p>
                    <span>50</span> Available position
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="job_searcing_wrap overlay">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 offset-lg-1 col-md-6">
                <div class="searching_text">
                  <h3>Looking for a Job?</h3>
                  <p>
                    We provide online instant cash loans with quick approval
                  </p>
                  <a href="/" class="boxed-btn3">
                    Browse Job
                  </a>
                </div>
              </div>
              <div class="col-lg-5 offset-lg-1 col-md-6">
                <div class="searching_text">
                  <h3>Looking for a Expert?</h3>
                  <p>
                    We provide online instant cash loans with quick approval
                  </p>
                  <a href="/" class="boxed-btn3">
                    Post a Job
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="testimonial_area">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="section_title text-center mb-40">
                  <h3>Testimonial</h3>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="">
                  <div className="single_carousel" data-aos="fade-left">
                    <div className="row">
                      <div className="col-lg-11">
                        <div className="single_testmonial d-flex align-items-center">
                          <div className="thumb">
                            <img src={users[currentIndex].img} alt="" />
                            <div className="quote_icon">
                              <i className="Flaticon flaticon-quote"></i>
                            </div>
                          </div>
                          <div className="info">
                            <p>{users[currentIndex].testimonial}</p>
                            <span>- {users[currentIndex].name}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1">
                        <button
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0)",
                            border: "none",
                          }}
                          onClick={handlePrev}
                        >
                          {" "}
                          <span
                            style={{
                              backgroundColor: "lightblue",
                              padding: ".5rem",
                              borderRadius: "50%",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-left-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                              />
                            </svg>
                          </span>
                        </button>
                        <button
                          className="my-4"
                          onClick={handleNext}
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0)",
                            border: "none",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              backgroundColor: "lightblue",
                              padding: ".5rem",
                              borderRadius: "50%",
                            }}
                          >
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-right-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testamonial;
