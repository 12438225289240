import React, { useContext } from "react";
import GigsDetails from "./GigsDetails";
import { myContext } from "../../../App";
import AuthNavbar from "../../../AuthComponents/AuthCommen/AuthNavbar";
import Navbar from "../../CommenComponents/Navbar";
import Footer from "../../CommenComponents/Footer";

function GigDetailsMain() {
  const a = useContext(myContext);
  const isAuth = a.IsLoggedIn;
  return (
    <div>
      {isAuth ? (
        <>
          <AuthNavbar />
          <GigsDetails />
        </>
      ) : (
        <>
          <Navbar />
          <GigsDetails />
        </>
      )}

      <Footer />
    </div>
  );
}

export default GigDetailsMain;
