import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { myContext } from "../../../App";
import { db } from "../../../Config";
import { collection, getDocs } from "firebase/firestore";
import "./gig.css";
function PendingGig() {
  const a = useContext(myContext);
  const userId = a.UserUid;
  const [allJobs, setAllJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobsCollection = await getDocs(collection(db, "jobs"));
        const jobsArray = jobsCollection.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((job) => job.uid === userId && job.isComplete === false);
        setAllJobs(jobsArray);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    console.log(allJobs);
  }, [allJobs]);

  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        {allJobs.map((e, index) => {
          return (
            <div className="card" style={{ width: "230px" }} key={index}>
              <img src={e.img1} class="card-img-top" alt="..."></img>
              <div class="card-body">
                <p style={{ textAlign: "left" }} class="card-text">
                  {e.description && e.description.slice(0, 60)}.....
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <svg
                  style={{ margin: "auto 10px", color: "gray" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-three-dots"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                </svg>

                <p
                  style={{
                    color: "gray",
                    fontFamily: "sans-serif",
                    fontSize: ".9rem",
                    margin: "auto 10px",
                  }}
                >
                  Starting at{" "}
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    {" "}
                    ${e.basic_price}
                  </span>{" "}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PendingGig;
