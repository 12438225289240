import React, { useContext, useEffect, useState } from "react";
import { myContext } from "../../../App";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../Config";
import { Link } from "react-router-dom";
import AuthNavbar from "../../AuthCommen/AuthNavbar";
import { Modal, Button } from "antd";
function Favorits() {
  const [isLoaiding, setisLoaiding] = useState(false);
  const a = useContext(myContext);
  const UserUid = a.UserUid;
  const UpdatefetchJobs = a.fetchJobs;
  const [allJobs, setAllJobs] = useState([]);
  const fetchJobs = async () => {
    setisLoaiding(true);
    try {
      const jobsCollection = await getDocs(collection(db, "MyFavorites"));
      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
      setAllJobs(jobsArray);
      setisLoaiding(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoaiding(false);
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);
  const [deleteJobId, setDeleteJobId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const deleteJob = async () => {
    try {
      await deleteDoc(doc(db, "MyFavorites", deleteJobId)); // Delete the document
      setDeleteModalVisible(false); // Close the modal
      fetchJobs();
      UpdatefetchJobs();
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };
  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  return (
    <div>
      <Modal
        title="Confirm Deletion"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setDeleteModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={deleteJob}>
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this Gig?
      </Modal>
      <AuthNavbar />
      <div class="job_listing_area plus_padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <h1 style={{ textAlign: "center" }}>
                {isLoaiding && (
                  <>
                    <img
                      style={{ width: "10%", height: "10%" }}
                      src="loader.gif"
                      alt=""
                      srcset=""
                    />
                    <h3>Loading Getting Details........</h3>
                  </>
                )}
              </h1>
              <div class="job_lists m-0">
                <div class="row">
                  {" "}
                  {allJobs.filter((i) => i.uid === UserUid).length === 0 && (
                    <h1 style={{ margin: "auto" }}>Sorry No Favorites Found</h1>
                  )}
                  {allJobs.filter((i) => i.uid === UserUid).length !== 0 && (
                    <h1 style={{ margin: "auto" }}>Your Favorites</h1>
                  )}
                  {allJobs
                    .filter((i) => i.uid === UserUid)
                    .map((e, index) => {
                      return (
                        <>
                          <div key={index} class="col-lg-12 col-md-12">
                            {" "}
                            <a
                              onClick={() => {
                                setDeleteModalVisible(true);
                                setDeleteJobId(e.id);
                              }}
                              style={{
                                position: "fixed",
                                color: "red",
                                cursor: "pointer",
                                padding: "10px 10px",
                              }}
                            >
                              <i class="fa fa-remove"></i>
                            </a>
                            <div class="single_jobs white-bg d-flex justify-content-between">
                              <div class="jobs_left d-flex align-items-center">
                                <div class="jobs_conetent">
                                  <a href="job_details.html">
                                    <h4>{e.title}</h4>
                                  </a>
                                  <div class="links_locat d-flex align-items-center">
                                    <div class="location">
                                      <p>
                                        <i class="fa fa-eye"></i> {e.category}
                                      </p>
                                    </div>
                                    <div class="location">
                                      <p>
                                        <i class="fa fa-user"></i> {e.userName}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="jobs_right">
                                <div class="apply_now">
                                  <Link
                                    to={`/Gigs-details/${e.jobId}`}
                                    class="boxed-btn3"
                                  >
                                    Apply Now
                                  </Link>
                                </div>
                                <div class="date">
                                  <p>Posted On: {e.postedDate}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="pagination_wrap">
                      <ul>
                        <li>
                          <a href="#">
                            {" "}
                            <i class="ti-angle-left"></i>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>01</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>02</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {" "}
                            <i class="ti-angle-right"></i>{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Favorits;
