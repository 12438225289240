import React, { useContext, useState, useEffect } from "react";
import { Badge, Avatar } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { myContext } from "../../../App";
import { getDoc, doc, collection, getDocs } from "firebase/firestore";
import { db } from "../../../Config";
function GigsDetails() {
  const { id } = useParams();
  const a = useContext(myContext);
  let isVerify = a.isEmailVerified;
  const navigate = useNavigate();
  const isEmailVerify = true;
  const [isBasic, setisBasic] = useState(true);
  const [isStandered, setisStandered] = useState(false);
  const [isPremum, setisPremum] = useState(false);
  const handleStandered = () => {
    setisStandered(true);
    setisBasic(false);
    setisPremum(false);
  };
  const handleBasic = () => {
    setisStandered(false);
    setisBasic(true);
    setisPremum(false);
  };
  const handlePremum = () => {
    setisStandered(false);
    setisBasic(false);
    setisPremum(true);
  };
  const [allJobs, setAllJobs] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const fetchJobs = async () => {
    setisLoading(true);
    try {
      const jobsCollection = await getDocs(collection(db, "jobs"));
      const usersArray = []; // Create an array to store user data

      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          const userId = doc.data().uid;
          const user = await getUserData(userId);

          // Check if user data exists before accessing its properties
          if (user && Object.keys(user).length > 0) {
            // Push user data into the array
            usersArray.push({
              id: userId,
              name: user.name !== "" ? user.name : "", // Use empty string as a fallback if name is undefined
              profilePicture: user.image !== "" ? user.image : "", // Use empty string as a fallback if profilePicture is undefined
            });
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );

      // Set the AllUsers state after the map function has completed
      setAllUsers(usersArray);
      setAllJobs(jobsArray);
      setisLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoading(false);
    }
  };
  const getUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "user", userId));
      return userDoc.exists() ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);
  useEffect(() => {
    console.log(allJobs);
  }, [allJobs]);
  useEffect(() => {
    console.log(AllUsers);
  }, [AllUsers]);

  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }
  const handleGoToProfile = () => {};
  return (
    <div>
      <div class="bradcam_area bradcam_bg_1">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>Software Engineer</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 style={{ textAlign: "center" }}>
        {isLoading && (
          <>
            <h3>Loading Getting Details........</h3>
          </>
        )}
      </h1>
      {allJobs
        .filter((data) => data.jobId === id)
        .map((e) => {
          return (
            <>
              <div class="job_details_area">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="job_details_header">
                        <div class="single_jobs white-bg d-flex justify-content-between">
                          <div class="jobs_left d-flex align-items-center">
                            <Badge
                              dot
                              color="green"
                              style={{ marginRight: "10px", marginTop: "72px" }}
                            >
                              <Avatar
                                onClick={handleGoToProfile}
                                style={{ cursor: "pointer" }}
                                size={90}
                                src={
                                  e.userProfile !== "none"
                                    ? ""
                                    : "./img/candiateds/1.png"
                                }
                              />{" "}
                              <div
                                style={{
                                  position: "absolute",
                                  top: 74,
                                  right: 8,
                                  // background: "#52c41a",
                                  borderRadius: "50%",
                                  width: 12,
                                  height: 12,
                                }}
                              ></div>
                            </Badge>

                            <div class="jobs_conetent">
                              <div class="user-detail">
                                <h3 style={{ margin: "auto" }}>
                                  {e.userName}{" "}
                                  {e.isEmailVerified ? (
                                    <>
                                      <svg
                                        style={{ color: "blue" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="currentColor"
                                        class="bi bi-check-circle-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                      </svg>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </h3>
                                <Link
                                  style={{ margin: "auto", fontSize: "1.3rem" }}
                                >
                                  Web Developer
                                </Link>
                              </div>
                              <div class="links_locat d-flex align-items-center"></div>
                            </div>
                          </div>
                          <div class="jobs_right">
                            <div class="apply_now">
                              <a class="heart_mark" href="#">
                                <i class="ti-heart"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="descript_wrap white-bg">
                        <div class="single_wrap">
                          <h4>{e.title}</h4>
                        </div>
                        <div class="single_wrap">
                          <h4>Benefits</h4>
                          <p>{e.Description}</p>
                        </div>
                        <div class="single_wrap">
                          {isBasic && (
                            <>
                              {" "}
                              <h4>Basic Description</h4>
                              <p>{e.basics_description}</p>
                            </>
                          )}
                          {isStandered && (
                            <>
                              {" "}
                              <h4>Standard Description</h4>
                              <p>{e.standard_description}</p>
                            </>
                          )}{" "}
                          {isPremum && (
                            <>
                              {" "}
                              <h4>Premium Description</h4>
                              <p>{e.premium_description}</p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="job_sumary">
                        <div class="summery_header">
                          <h3>Contact With Freelancer</h3>
                          <button className="btn btn-success">
                            Message Now
                          </button>
                        </div>
                        <div class="job_content">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <a
                              onClick={handleBasic}
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                                color: isBasic ? "blue" : "black",
                                fontWeight: isBasic ? "bold" : "normal",
                              }}
                            >
                              Basic
                            </a>
                            <a
                              onClick={handleStandered}
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                                color: isStandered ? "blue" : "black",
                                fontWeight: isStandered ? "bold" : "normal",
                              }}
                            >
                              Standard
                            </a>
                            <a
                              onClick={handlePremum}
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                                color: isPremum ? "blue" : "black",
                                fontWeight: isPremum ? "bold" : "normal",
                              }}
                            >
                              Premium
                            </a>
                          </div>
                          <ul
                            style={{
                              display: isBasic ? "" : "none",
                              marginTop: "40px",
                            }}
                          >
                            <li>
                              Published on: <span>12 Nov, 2019</span>
                            </li>
                            <li>
                              Price :<span>${e.basic_price}</span>
                            </li>
                            <li>
                              Revision : <span>{e.basic_revisions}</span>
                            </li>
                            <li>
                              Delivery: <span>{e.basic_deleviery}</span>
                            </li>
                          </ul>
                          <ul
                            style={{
                              display: isStandered ? "" : "none",
                              marginTop: "40px",
                            }}
                          >
                            <li>
                              Published on: <span>12 Nov, 2019</span>
                            </li>
                            <li>
                              Price :<span>${e.standard_price}</span>
                            </li>
                            <li>
                              Revision : <span>{e.standard_revisions}</span>
                            </li>
                            <li>
                              Delivery: <span>{e.standard_deleviery}</span>
                            </li>
                          </ul>{" "}
                          <ul
                            style={{
                              display: isPremum ? "" : "none",
                              marginTop: "40px",
                            }}
                          >
                            <li>
                              Published on: <span>12 Nov, 2019</span>
                            </li>
                            <li>
                              Price :<span>${e.premium_price}</span>
                            </li>
                            <li>
                              Revision : <span>{e.premium_revisions}</span>
                            </li>
                            <li>
                              Delivery: <span>{e.premium_deleviery}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="share_wrap d-flex">
                        <span>Share at:</span>
                        <ul>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-google-plus"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <i class="fa fa-envelope"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="job_location_wrap">
                        <div class="job_lok_inner">
                          <div id="map" style={{ height: "200px" }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
}

export default GigsDetails;
