import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { useNavigate } from "react-router-dom";import { Avatar } from 'antd';
import { db } from "../../../../Config";
import { myContext } from "../../../../App";
const Chats = ({ setisShow }) => { 
  const [chats, setChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);
  const navigate = useNavigate();
  useEffect(() => {
 
    console.log("i am dispatch: "+dispatch)
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        setChats(doc.data());
      });
      return () => {
        unsub();
      };
    };
    currentUser.uid && getChats();
  }, [currentUser.uid]);
  const handleSelect = (u) => {
    console.log("Hi I am Stange");
    console.log(u);
    console.log(currentUser);
    dispatch({ type: "CHANGE_USER", payload: u });
    console.log(chats); setisShow(false);
  };

  return (
  <>
    <div class="topvhats">
      <div className="chats" style={{ backgroundColor: "#5d5b8d", display: "flex", flexDirection: "column", overflow: "scroll", height: "48rem" }}>
        {chats && Object.entries(chats)?.sort((a, b) => b[1].date - a[1].date).map((chat) => (
          <div
            className="userChat"
            key={chat[0]}
            onClick={() => handleSelect(chat[1].userInfo)}
          >
            <div className="userChatInfo">
              {chat[1].userInfo ? ( <> <Avatar style={{ margin:"auto" }} src= {chat[1].userInfo.profilePic} /> <span>{chat[1].userInfo.name} <br /><p style={{ marginLeft:"28px",marginTop:"-10px" }}>{chat[1].lastMessage?.text.slice(0,20)}</p></span></>
               
              ) : (
                <span>Anonmus</span>
              )}
            
            </div>
          </div>
        ))}
      </div>
    </div>
  </>
);

};

export default Chats;














