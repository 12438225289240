// Home.js

import React, { useState, useEffect } from "react";

const Home = () => {
  let btnBackgroundColor = "brown";
  let btnBorderColor = "rgba(111, 113, 112, 0.644)";

  const [bannerImage, setBannerImage] = useState("./img/banner/image1.png"); // Initial image path

  useEffect(() => {
    const imageArray = [
      "./img/banner/image1.png",
      "./img/banner/image2.png",
      "./img/banner/image3.png",
    ]; // Add more images as needed
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % imageArray.length;
      setBannerImage(imageArray[currentIndex]);
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup the interval when the component is unmounted
  }, []);
  return (
    <div className="slider_area">
      <div className="single_slider d-flex align-items-center slider_bg_1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6">
              <div className="MyhOMEbANNER">
                <div className="slider_text">
                  <h5
                    className="wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                  >
                    Find or Give a Job
                  </h5>
                  <h3
                    className="wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".3s"
                  >
                    Thousand Of Services & Offers
                  </h3>
                  <p
                    className="wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".4s"
                  >
                    Find a job or give job to professionals talent <br />
                    Right place to find IT specialist
                  </p>
                  <div
                    className="sldier_btn wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".5s"
                  >
                    <div className="input-group flex-nowrap">
                      <span className="input-group-text" id="addon-wrapping">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                      </span>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Job"
                        aria-label="Username"
                        aria-describedby="addon-wrapping"
                      />
                    </div>
                    <div className="poplr">
                      <a
                        href="#"
                        style={{
                          border: "0.1rem solid white",
                          padding: "0.2rem",
                          color: "white",
                          borderRadius: "10px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search mx-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                        App Development
                      </a>
                      <a
                        href="#"
                        style={{
                          border: "0.1rem solid white",
                          padding: "0.2rem",
                          color: "white",
                          borderRadius: "10px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search mx-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                        IOS Development
                      </a>
                      <a
                        href="#"
                        style={{
                          border: "0.1rem solid white",
                          padding: "0.2rem",
                          color: "white",
                          borderRadius: "10px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search mx-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                        Graphics
                      </a>
                      <a
                        href="#"
                        style={{
                          border: "0.1rem solid white",
                          padding: "0.2rem",
                          color: "white",
                          borderRadius: "10px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search mx-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                        Digital Marketing
                      </a>
                    </div>
                    <div className="mobbtn">
                      <button
                        className="btn"
                        style={{
                          backgroundColor: btnBackgroundColor,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        Become Freelancer
                      </button>
                      <button
                        className="btn"
                        style={{
                          backgroundColor: btnBorderColor,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        Hire Freelancer
                      </button>
                    </div>
                  </div>
                </div>
                <div className="MyImageIntOP">
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={bannerImage}
                    alt=""
                    srcSet=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
