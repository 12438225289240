import React, { useContext, useState, useEffect } from "react";
import {
  Avatar,
  Row,
  Col,
  Badge,
  Modal,
  Form,
  Button,
  message,
  Select,
  Tag,
  Input,
  Spin,
  AutoComplete,
} from "antd";
import services from "../../services.json";
import "./UserProfile.css";
import PublishedJob from "./UserJobs/PublishedJob";
import PendingJobs from "./UserJobs/PendingJobs";
import { myContext } from "../../App";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import MyUpload from "./MyUpload";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../Config";
import PublishedGig from "./UserGigs/PublishedGig";
import PendingGig from "./UserGigs/PendingGig";
import History from "./JobsHistory/History";

const { Option } = Select;
const { TextArea } = Input;
const UserInfo = () => {
  const [HistoryModal, setHistoryModal] = useState(false);
  const MyUserData = useContext(myContext);
  let userStatus = MyUserData.status;
  let Myusername = MyUserData.username;
  let description = MyUserData.description;
  let userProfile = MyUserData.userProfile;
  let email = MyUserData.userEmail;
  let skill = MyUserData.skill;
  let userName = MyUserData.userName;
  let language = MyUserData.language;
  let isEmailVerify = MyUserData.isEmailVerified;
  let UserID = MyUserData.UserUid;
  let RenderNe = MyUserData.RenderNe;
  let UserLocation = MyUserData.UserLocation;

  const user = {
    profilePicture:
      userProfile !== "none" ? userProfile : "../img/candiateds/1.png",
    name: userName,
    username: Myusername,
    userStatus: userStatus,
    description: description,
    skill: skill,
    language: language,
    email: email,
    isEmailVerify: isEmailVerify,
    MyLocation: UserLocation,
    totalJobsPosted: 10,
    totalJobsApproved: 5,
  };
  const [showPublishJobs, setShowPublishJobs] = useState(true);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const handleShowPublishJobs = () => {
    setShowPublishJobs(true);
  };

  const handleShowPendingJobs = () => {
    setShowPublishJobs(false);
  };
  const handleEditProfileClick = () => {
    setIsProfileModalVisible(true);
  };

  const handleProfileModalCancel = () => {
    setIsProfileModalVisible(false);
  };

  const handleProfileUploadComplete = (downloadURL) => {
    // Handle profile picture upload completion
    console.log("Profile picture uploaded:", downloadURL);
    setIsProfileModalVisible(false);
  };
  const [isNameOpen, setisNameOpen] = useState(false);
  const [UserStatusUpdated, setUserStatusUpdated] = useState("");
  const handleName = () => {
    setisNameOpen(true);
  };
  const updateUserStatus = async () => {
    if (UserStatusUpdated !== "") {
      try {
        const userDocRef = doc(db, "user", UserID);
        await updateDoc(userDocRef, {
          status: UserStatusUpdated,
        });
        message.success("Status Updated");
        RenderNe();
        setisNameOpen(false);
        console.log("User status updated:" + UserStatusUpdated);
      } catch (error) {
        message.error("Status Not Updated");
        console.error("Error updating user status:", error.message);
      }
    }
  };
  const onCancel = () => {
    setisNameOpen(false);
  };

  const [Mylanguage, setMyLanguage] = useState("");
  const [languageLevel, setLanguageLevel] = useState("Select Language Level");
  const [languages, setLanguages] = useState([]);
  const [isLanguageUpdate, setisLanguageUpdate] = useState(false);
  const handleLanguage = () => {
    setisLanguageUpdate(true);
  };
  const handlenegClose = () => {
    setisLanguageUpdate(false);
  };
  const handleLanguageDelete = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
  };
  const handleLanguageSubmit = () => {
    if (Mylanguage && languageLevel) {
      const newLanguage = `${Mylanguage} - ${languageLevel}`;
      setLanguages([...languages, newLanguage]);
      setMyLanguage("");
      setLanguageLevel("");
    }
  };
  const handleSelectChange = (e) => {
    console.log(e);
    setMyLanguage(e);
  };
  const handleSelectMyChange = (e) => {
    console.log(e);
    setMyskill(e);
  };
  const [isLanguageAddLoading, setisLanguageAddLoading] = useState(false);
  const HandleUpdateLanguage = () => {
    setisLanguageAddLoading(true);
    handleLanguageSubmit();
    console.log(languages);
    try {
      const userDocRef = doc(db, "user", UserID);
      updateDoc(userDocRef, {
        language: languages,
      })
        .then((res) => {
          message.success("Language Added");
          RenderNe();
          setisLanguageUpdate(false);
          setisLanguageAddLoading(false);
        })
        .catch((err) => {
          message.error("Sorry Some Error Occurred");
          setisLanguageAddLoading(false);
        });
    } catch (error) {
      message.error("Sorry Some Error Occurred");
      setisLanguageAddLoading(false);
    }
  };

  const [isSkillOpen, setisSkillOpen] = useState(false);
  const [Myskill, setMyskill] = useState("");
  const [skills, setSkills] = useState([]);
  const handleKill = () => {
    setisSkillOpen(true);
  };
  const handlesKILCLOSE = () => {
    setisSkillOpen(false);
  };
  const handleSkillSubmit = () => {
    if (Myskill) {
      setSkills([...skills, Myskill]);
      setMyskill("");
    }
  };

  const [SkillLoading, setSkillLoading] = useState(false);
  const HandleUpdateSkill = () => {
    setSkillLoading(true);
    handleSkillSubmit();
    console.log(skills);
    try {
      const userDocRef = doc(db, "user", UserID);
      updateDoc(userDocRef, {
        skill: skills,
      })
        .then((res) => {
          message.success("Skill Added");
          RenderNe();
          setSkills([]);
          setMyskill("");
          setisSkillOpen(false);
          setSkillLoading(false);
        })
        .catch((err) => {
          message.error("Sorry Some Error Occurred");
          setSkillLoading(false);
        });
    } catch (error) {
      message.error("Sorry Some Error Occurred");
      setSkillLoading(false);
    }
  };
  const handleSkillDelete = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
  };
  const [isLocationOpen, setisLocationOpen] = useState(false);
  const [LocationValue, setLocationValue] = useState("");
  const handleOpenLocation = () => {
    setisLocationOpen(true);
  };
  const handleCloseLocation = () => {
    setisLocationOpen(false);
  };

  const handleLocation = () => {
    if (LocationValue !== "") {
      try {
        const userDocRef = doc(db, "user", UserID);
        updateDoc(userDocRef, {
          location: LocationValue,
        });
        RenderNe();
        message.success("location Added");
        setisLocationOpen(false);
      } catch (error) {
        message.error("Sorry Some Error Occurred");
      }
    }
  };

  const [isDescriptionOpen, setisDescriptionOpen] = useState(false);
  const [Mydescription, setMyDescription] = useState("");
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 600) {
      setMyDescription(value);
    }
  };
  const getCharacterCount = (text) => {
    return text.length;
  };
  const handleOPenDescription = () => {
    setisDescriptionOpen(true);
  };
  const handleCloseDescription = () => {
    setisDescriptionOpen(false);
  };
  const [DescriptionLoading, setDescriptionLoading] = useState(false);
  const handleDescriprion = () => {
    setDescriptionLoading(true);
    if (Mydescription !== "") {
      try {
        const userDocRef = doc(db, "user", UserID);
        updateDoc(userDocRef, {
          description: Mydescription,
        })
          .then((res) => {
            message.success("Description Added");
            RenderNe();
            setisDescriptionOpen(false);
            setDescriptionLoading(false);
          })
          .catch((err) => {
            message.error("Sorry Some Error Occurred");
            setDescriptionLoading(false);
          });
      } catch (error) {
        message.error("Sorry Some Error Occurred");
        setDescriptionLoading(false);
      }
    }
  };
  const onMyCancel = () => {
    setHistoryModal(false);
  };
  const LangauesAvailable = [
    "English",
    "Mandarin Chinese",
    "Hindi",
    "Spanish",
    "French",
    "Standard Arabic",
    "Bengali",
    "Russian",
    "Portuguese",
    "Urdu",
    "Indonesian",
    "German",
    "Japanese",
    "Swahili",
    "Telugu",
    "Marathi",
    "Turkish",
    "Tamil",
    "Vietnamese",
    "Korean",
    "Italian",
    "Yoruba",
    "Thai",
    "Guarani",
    "Persian",
    "Pashto",
    "Kannada",
    "Malayalam",
    "Sundanese",
    "Hausa",
    "Odia (Oriya)",
    "Burmese",
    "Malay",
    "Swedish",
    "Dutch",
    "Simplified Chinese",
    "Romanian",
    "Greek",
    "Kazakh",
    "Tagalog",
    "Nepali",
    "Azerbaijani",
    "Amharic",
    "Sindhi",
    "Farsi",
    "Bulgarian",
    "Serbian",
    "Ukrainian",
    "Slovak",
    "Norwegian",
    "Czech",
    "Malagasy",
    "Hungarian",
    "Finnish",
    "Hebrew",
    "Tajik",
    "Haitian Creole",
    "Swahili (Kiswahili)",
    "Kinyarwanda",
    "Lithuanian",
    "Kurdish",
    "Latvian",
    "Slovene",
    "Tigrinya",
    "Armenian",
    "Albanian",
    "Zulu",
    "Macedonian",
    "Lao",
    "Lingala",
    "Xhosa",
    "Afrikaans",
    "Igbo",
    "Croatian",
    "Bosnian",
    "Kirundi",
    "Akan",
    "Fijian",
    "Somali",
    "Gujarati",
    "Uzbek",
    "Sinhala",
    "Turkmen",
    "Bambara",
    "Mongolian",
    "Kinyarwanda",
    "Zarma",
    "Bhojpuri",
    "Malagasy",
    "Sesotho",
    "Kinyarwanda",
    "Bislama",
    "Bemba",
    "Hmong",
    "Ilokano",
    "Balinese",
    "Chamorro",
    "Kiribati",
    "Tongan",
    "Marshallese",
    "Palauan",
    "Maori",
    "Fijian",
    "Samoan",
    "Hawaiian",
    "Tahitian",
    "Guamanian",
    "Chuukese",
    "Niuean",
    "Cook Islands Maori",
    "Tok Pisin",
    "Tetum",
    "Kiribati",
    "Nauruan",
    "Pohnpeian",
    "Yapese",
    "Kosraean",
    "Chamorro",
    "Gilbertese",
    "Tahitian",
    "Tongan",
    "Samoan",
    "Palauan",
    "Chuukese",
    "Marshallese",
    "Nauruan",
    "Pohnpeian",
    "Yapese",
    "Kosraean",
    "Bislama",
    "Fijian",
    "Rotuman",
    "Tahitian",
    "Niuean",
    "Cook Islands Maori",
    "Tokelauan",
    "Tuvaluan",
    "Wallisian",
    "Futunan",
    "Anuta",
    "Drehu",
    "Iaai",
    "Kanak",
    "Nengone",
    "Paici",
    "Xaracuu",
    "Yalayu",
    "Caldoche",
    "Pwapwâ",
    "Hawaiian",
    "Samoan",
    "Tongan",
    "Cook Islands Maori",
    "Tahitian",
    "Marquesan",
    "Tuamotuan",
    "Rapa Nui",
    "Moriori",
    "Rapa",
    "Kapingamarangi",
    "Nukuoro",
    "Nukuria",
    "Takuu",
    "Tikopia",
    "Sakao",
    "Drehu",
    "Iaai",
    "Nengone",
    "Paici",
    "Xaracuu",
    "Yalayu",
    "Atayal",
    "Amis",
    "Bunun",
    "Tsou",
    "Paiwan",
    "Puyuma",
    "Rukai",
    "Saisiyat",
    "Saaroa",
    "Thao",
    "Kavalan",
    "Taroko",
    "Truku",
    "Tao",
    "Luilang",
    "Qauqaut",
    "Drekay",
    "Korakay",
    "Trikay",
    "Atayal",
    "Bunun",
    "Kavalan",
    "Paiwan",
    "Puyuma",
    "Rukai",
    "Saisiyat",
    "Thao",
    "Tsou",
    "Yami",
    "Thao",
    "Saaroa",
    "Yami",
    "Korean Sign Language",
    "British Sign Language",
    "American Sign Language",
    "French Sign Language",
    "Malaysian Sign Language",
    "Irish Sign Language",
    "Russian Sign Language",
    "Indian Sign Language",
    "Italian Sign Language",
    "German Sign Language",
    "Japanese Sign Language",
    "Chinese Sign Language",
    "Spanish Sign Language",
    "Brazilian Sign Language",
    "Mexican Sign Language",
    "Argentinian Sign Language",
    "Colombian Sign Language",
    "Chilean Sign Language",
    "Peruvian Sign Language",
    "Venezuelan Sign Language",
    "Cuban Sign Language",
    "Costa Rican Sign Language",
    "Honduran Sign Language",
    "Nicaraguan Sign Language",
    "Guatemalan Sign Language",
    "Ecuadorian Sign Language",
    "Paraguayan Sign Language",
    "Uruguayan Sign Language",
    "Bolivian Sign Language",
    "Panamanian Sign Language",
    "Salvadoran Sign Language",
    "Belizean Sign Language",
    "Puerto Rican Sign Language",
    "Dominican Sign Language",
    "Jamaican Sign Language",
    "Bahamian Sign Language",
    "Guyanese Sign Language",
    "Surinamese Sign Language",
    "French Guianese Sign Language",
    "Greenlandic Sign Language",
    "Kalaallisut",
    "Kalaallisut",
    "Kalaallisut",
    "Kalaallisut",
    "Kalaallisut",
    "Inuktikut",
    "Inuktikut",
    "Inuktikut",
    "Inuktikut",
    "Inuktikut",
    "Inuktikut",
    "Inuktikut",
    "Inuktikut",
    "Inuktikut",
    "Inuktikut",
    "Koyukon",
    "Upper Kuskokwim",
    "Lower Tanana",
    "Tanacross",
    "Tetlin",
    "Tlingit",
    "Eyak",
    "Ahtna",
    "Deg Hit'an",
    "Dena'ina",
    "Holikachuk",
    "Koyukon",
    "Lower Tanana",
    "Tanacross",
    "Tetlin",
    "Tlingit",
    "Tsimshian",
    "Nisga'a",
    "Tahltan",
    "Tsetsaut",
    "Wet'suwet'en",
    "Haisla",
    "Heiltsuk",
    "Bella Coola",
    "Lillooet",
    "Secwepemctsin",
    "Thompson",
    "Shuswap",
    "Okanagan",
    "Sinkaietk",
    "Nicola",
    "Tsilhqot'in",
    "Kutenai",
    "Carrier",
    "Babine",
    "Chilcotin",
    "Nuxalk",
    "Nuu-chah-nulth",
    "Kwak'wala",
    "Halkomelem",
    "Saanich",
    "Comox",
    "Nooksack",
    "Lushootseed",
    "Quinault",
    "Cowlitz",
    "Chehalis",
    "Shoshoni",
    "Gosiute",
    "Paiute",
    "Ute",
    "Comanche",
    "Kutenai",
    "Sahaptin",
    "Nez Perce",
    "Yakama",
    "Chinook",
    "Kalapuya",
    "Takelma",
    "Siletz",
    "Klamath",
    "Modoc",
    "Tubatulabal",
    "Chumash",
    "Salinan",
    "Esselen",
    "Rumsen",
    "Ohlone",
    "Costanoan",
    "Karuk",
    "Hupa",
    "Yurok",
    "Wiyot",
    "Mattole",
    "Tolowa",
    "Wintu",
    "Maidu",
    "Nisenan",
    "Patwin",
    "Wappo",
    "Pomo",
    "Miwok",
    "Coast Miwok",
    "Lake Miwok",
    "Wappo",
    "Pomo",
    "Miwok",
    "Coast Miwok",
    "Lake Miwok",
    "Ohlone",
    "Esselen",
    "Rumsen",
    "Kumeyaay",
    "Ipai",
    "Tipai",
    "Cupeño",
    "Luiseno",
    "Gabrielino",
    "Fernandeño",
    "Ventureño",
    "Serrano",
    "Cahuilla",
    "Serrano",
    "Cahuilla",
    "Tongva",
    "Kumeyaay",
    "Chumash",
    "Salinan",
    "Esselen",
    "Rumsen",
    "Ohlone",
    "Yuki",
    "Pomo",
    "Miwok",
    "Karuk",
    "Hupa",
    "Yurok",
    "Wiyot",
    "Mattole",
    "Tolowa",
    "Wintu",
    "Maidu",
    "Nisenan",
    "Patwin",
    "Wappo",
    "Pomo",
    "Miwok",
    "Coast Miwok",
    "Lake Miwok",
    "Ohlone",
    "Esselen",
    "Rumsen",
    "Kumeyaay",
    "Ipai",
    "Tipai",
    "Cupeño",
    "Luiseno",
    "Gabrielino",
    "Fernandeño",
    "Ventureño",
    "Serrano",
    "Cahuilla",
    "Serrano",
    "Cahuilla",
    "Tongva",
    "Kumeyaay",
    "Chumash",
    "Salinan",
    "Esselen",
    "Rumsen",
    "Ohlone",
    "Yuki",
    "Pomo",
    "Miwok",
    "Karuk",
    "Hupa",
    "Yurok",
    "Wiyot",
    "Mattole",
    "Tolowa",
    "Wintu",
    "Maidu",
    "Nisenan",
    "Patwin",
    "Wappo",
    "Pomo",
    "Miwok",
    "Coast Miwok",
    "Lake Miwok",
    "Ohlone",
    "Esselen",
    "Rumsen",
    "Kumeyaay",
    "Ipai",
    "Tipai",
    "Cupeño",
    "Luiseno",
    "Gabrielino",
    "Fernandeño",
    "Ventureño",
    "Serrano",
    "Cahuilla",
    "Serrano",
    "Cahuilla",
    "Tongva",
    "Kumeyaay",
    "Chumash",
    "Salinan",
    "Esselen",
    "Rumsen",
    "Ohlone",
    "Yuki",
    "Pomo",
    "Miwok",
    "Karuk",
    "Hupa",
    "Yurok",
    "Wiyot",
    "Mattole",
    "Tolowa",
    "Wintu",
    "Maidu",
    "Nisenan",
    "Patwin",
    "Wapp",
  ];

  return (
    <div style={{ backgroundColor: "#ece9e9", marginTop: "-2rem" }}>
      <Modal footer={null} open={HistoryModal} onCancel={onMyCancel}>
        <History UserID={UserID} />
      </Modal>

      <MyUpload
        visible={isProfileModalVisible}
        onCancel={handleProfileModalCancel}
        userId={UserID}
        onComplete={handleProfileUploadComplete}
      />
      <Row className="user-profile-container">
        {/* User Details Column */}
        <Col className="user-details">
          <Badge dot>
            <Avatar size={100} src={user.profilePicture} />{" "}
            <div
              style={{
                position: "absolute",
                top: 8,
                right: 8,
                // background: "#52c41a",
                borderRadius: "50%",
                width: 12,
                height: 12,
              }}
            >
              {" "}
              {user.isEmailVerify ? (
                <>
                  <svg
                    style={{ color: "blue" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                </>
              ) : (
                ""
              )}
            </div>{" "}
            <div
              style={{
                position: "absolute",
                top: 74,
                right: 8,
                // background: "#52c41a",
                borderRadius: "50%",
                width: 12,
                height: 12,
              }}
            >
              {" "}
              <EditOutlined
                style={{
                  float: "right",
                  fontSize: "1.5rem",
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={handleEditProfileClick}
              />
            </div>
          </Badge>

          <div className="user-info">
            <h3 style={{ fontFamily: "sans-serif", color: "gray" }}>
              {user.name}
            </h3>
            <p>@{user.username}</p>
            <p>
              {user.isEmailVerify ? (
                <>
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    Verified
                  </span>
                </>
              ) : (
                <>
                  <Link
                    to={"/status/email-verification"}
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    *Not Verified* click me to verify
                  </Link>
                </>
              )}
            </p>
            <div className="UserAdditionalInfo">
              <div
                className="my-3"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>Email</span>
                <span style={{ fontFamily: "sans-serif", color: "gray" }}>
                  {user.email}
                </span>
              </div>
              <div
                className="my-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  Location
                  <EditOutlined
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={handleOpenLocation}
                  ></EditOutlined>
                </span>
                <span style={{ fontFamily: "sans-serif", color: "gray" }}>
                  {user.MyLocation}
                </span>
              </div>
              <div
                style={{
                  display: isLocationOpen ? "" : "none",
                  backgroundColor: "lightgray",
                }}
              >
                <Form onFinish={handleLocation}>
                  {" "}
                  <Input
                    className="my-3"
                    style={{ width: "60%" }}
                    placeholder="Enter Location"
                    value={LocationValue}
                    onChange={(e) => setLocationValue(e.target.value)}
                  />{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor:
                          LocationValue !== "" ? "green" : "lightgreen",
                        color: "white",
                      }}
                      disabled={LocationValue !== "" ? false : true}
                      classNames={"mx-1 success "}
                      type="success"
                      htmlType="submit"
                    >
                      Add location
                    </Button>
                    <Button
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={() => {
                        setisLocationOpen(false);
                        setLocationValue("");
                      }}
                      classNames={"mx-1 danger"}
                      type="danger"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
              <div
                className="my-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <span>
                  Account Status{" "}
                  <EditOutlined
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={handleName}
                  />
                </span>
                <span style={{ fontFamily: "sans-serif", color: "gray" }}>
                  {user.userStatus}
                </span>
              </div>{" "}
              <div
                style={{
                  display: isNameOpen ? "" : "none",
                  backgroundColor: "lightgray",
                }}
              >
                {" "}
                <Form onFinish={updateUserStatus}>
                  <Form.Item
                    label="Update Status"
                    style={{ marginTop: "10px" }}
                  >
                    <Select
                      style={{ width: "70%" }}
                      placeholder="Level"
                      value={UserStatusUpdated}
                      onChange={(value) => setUserStatusUpdated(value)}
                    >
                      <Option value="Client">Client</Option>
                      <Option value="Freelancer">Freelancer</Option>
                    </Select>
                  </Form.Item>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor:
                          UserStatusUpdated !== "" ? "green" : "lightgreen",
                        color: "white",
                      }}
                      classNames={"mx-1 success "}
                      type="success"
                      htmlType="submit"
                      disabled={UserStatusUpdated !== "" ? false : true}
                    >
                      Update Status
                    </Button>
                    <Button
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={() => {
                        setisNameOpen(false);
                        setUserStatusUpdated("");
                      }}
                      classNames={"mx-1 danger"}
                      type="danger"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
              <div
                className="my-3"
                style={{
                  display: user.userStatus === "Client" ? "none" : "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  Skills
                  <EditOutlined
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={handleKill}
                  />
                </span>
                <span style={{ fontFamily: "sans-serif", color: "gray" }}>
                  {" "}
                  {Array.isArray(user.skill) ? (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      {user.skill.map((e, index) => (
                        <li key={index} style={{ textAlign: "left" }}>
                          {e}
                        </li>
                      ))}
                    </span>
                  ) : (
                    "Please Add Skill"
                  )}
                </span>
              </div>{" "}
              <div
                style={{
                  display: isSkillOpen ? "" : "none",
                  backgroundColor: "lightgray",
                }}
              >
                {" "}
                <Form onFinish={HandleUpdateSkill}>
                  <Form.Item style={{ padding: "5px 10px" }}>
                    <div style={{ display: "flex" }}>
                      <AutoComplete
                        style={{ width: "100%" }}
                        options={services.categories.map((category) => ({
                          value: category.name,
                        }))}
                        onSelect={handleSelectMyChange}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        <Input
                          value={Myskill}
                          onChange={(e) => setMyskill(e.target.value)}
                          placeholder="Add Your Skill"
                        />
                      </AutoComplete>{" "}
                      {/* <svg
                        style={{ margin: "auto 3px", cursor: "pointer" }}
                        onClick={handleSkillSubmit}
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        class="bi bi-plus-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                      </svg> */}
                    </div>
                    <div>
                      {skills.map((sk, index) => (
                        <Tag color="green" key={index}>
                          {sk}
                          <span onClick={() => handleSkillDelete(index)}>
                            ×
                          </span>
                        </Tag>
                      ))}
                    </div>
                  </Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor:
                          Myskill !== "" ? "green" : "lightgreen",
                        color: "white",
                      }}
                      disabled={Myskill !== "" ? false : true}
                      classNames={"mx-1 success "}
                      type="success"
                      htmlType="submit"
                    >
                      Add Skill {SkillLoading && <Spin />}
                    </Button>
                    <Button
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={() => {
                        setisSkillOpen(false);
                        setMyskill("");
                      }}
                      classNames={"mx-1 danger"}
                      type="danger"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
              <div
                style={{
                  display: user.userStatus === "Client" ? "none" : "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <span style={{ textAlign: "left" }}>
                  Description
                  <EditOutlined
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={handleOPenDescription}
                  ></EditOutlined>{" "}
                </span>
                <p style={{ textAlign: "left" }}>{user.description}</p>
              </div>
              <div
                style={{
                  display: isDescriptionOpen ? "" : "none",
                  backgroundColor: "lightgray",
                }}
              >
                {" "}
                <Form onFinish={handleDescriprion}>
                  <Form.Item style={{ padding: "5px 5px" }}>
                    <TextArea
                      rows={3}
                      value={Mydescription}
                      onChange={handleDescriptionChange}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {Mydescription.length > 600 && (
                        <div style={{ color: "red" }}>
                          Description must not exceed 600 characters.
                        </div>
                      )}
                      <div>
                        {getCharacterCount(Mydescription)}/600 characters
                      </div>
                    </div>
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor:
                          Mydescription !== "" ? "green" : "lightgreen",
                        color: "white",
                      }}
                      disabled={Mydescription !== "" ? false : true}
                      classNames={"mx-1 success "}
                      type="success"
                      htmlType="submit"
                    >
                      Add Description {DescriptionLoading && <Spin />}
                    </Button>
                    <Button
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={() => {
                        setisDescriptionOpen(false);
                        setMyDescription("");
                      }}
                      classNames={"mx-1 danger"}
                      type="danger"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
              <div
                className="my-3"
                style={{
                  display: user.userStatus === "Client" ? "none" : "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  Language
                  <EditOutlined
                    onClick={handleLanguage}
                    style={{
                      fontSize: "1.3rem",
                    }}
                  ></EditOutlined>
                </span>
                <span>
                  {" "}
                  {Array.isArray(user.language) ? (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      {user.language.map((e, index) => (
                        <li key={index} style={{ textAlign: "left" }}>
                          {e}
                        </li>
                      ))}
                    </span>
                  ) : (
                    "Please add language"
                  )}
                </span>
              </div>{" "}
              <div
                style={{
                  display: isLanguageUpdate ? "" : "none",
                  backgroundColor: "lightgray",
                }}
              >
                <Form onFinish={HandleUpdateLanguage}>
                  <Form.Item style={{ padding: "3px 5px" }}>
                    <Input.Group compact>
                      {" "}
                      <AutoComplete
                        style={{ width: "100%", marginBottom: "20px" }}
                        options={LangauesAvailable.filter((language) =>
                          language
                            .toLowerCase()
                            .startsWith(Mylanguage.toLowerCase())
                        ).map((category) => ({ value: category }))}
                        onSelect={handleSelectChange}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                        }
                      >
                        <Input
                          value={Mylanguage}
                          onChange={(e) => setMyLanguage(e.target.value)}
                          placeholder="Add Your Spoken Language"
                        />
                      </AutoComplete>
                      <Select
                        className="my-3"
                        style={{
                          width: "100%",
                          display: Mylanguage !== "" ? "" : "none",
                        }}
                        placeholder="Level"
                        value={languageLevel}
                        onChange={(value) => setLanguageLevel(value)}
                      >
                        <Option value="Beginner">Beginner</Option>
                        <Option value="Intermediate">Intermediate</Option>
                        <Option value="Advanced">Advanced</Option>
                      </Select>
                    </Input.Group>
                  </Form.Item>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor:
                          setLanguageLevel !== "Select Language Level" &&
                          Mylanguage !== ""
                            ? "green"
                            : "lightgreen",
                        color: "white",
                      }}
                      classNames={"mx-1 success "}
                      htmlType="submit"
                      disabled={
                        setLanguageLevel !== "Select Language Level" &&
                        (Mylanguage !== "") !== 0
                          ? false
                          : true
                      }
                    >
                      Update Language
                      {isLanguageAddLoading && <Spin />}
                    </Button>
                    <Button
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={() => {
                        setisLanguageUpdate(false);
                        setLanguages([]);
                      }}
                      classNames={"mx-1 danger"}
                      type="danger"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
        {/* User Activities Column */}
        <Col
          className="user-activities"
          style={{ display: user.userStatus === "Client" ? "" : "none" }}
        >
          <div className="MyTopBtn">
            {" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <h5
                style={{ cursor: "pointer" }}
                className="mx-3 mb-2"
                type={showPublishJobs ? "primary" : "default"}
                onClick={handleShowPublishJobs}
              >
                Publish Jobs
              </h5>{" "}
              {showPublishJobs && (
                <div>
                  <div
                    style={{
                      height: "2px",
                      backgroundColor: "green",
                      width: "80%",
                      margin: "auto",
                    }}
                  ></div>
                </div>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <h5
                style={{ cursor: "pointer" }}
                className="mx-3 mb-2"
                type={!showPublishJobs ? "primary" : "default"}
                onClick={handleShowPendingJobs}
              >
                Pending Jobs
              </h5>{" "}
              {!showPublishJobs && (
                <div>
                  <div
                    style={{
                      height: "2px",
                      backgroundColor: "green",
                      width: "80%",
                      margin: "auto",
                    }}
                  ></div>
                </div>
              )}
            </div>{" "}
          </div>
          <div className="activity-info">
            {showPublishJobs && (
              <div>
                <PublishedJob />
              </div>
            )}
            {!showPublishJobs && (
              <div>
                <PendingJobs />
              </div>
            )}
            {/* Add other activity details here */}
          </div>
        </Col>{" "}
        <Col
          className="user-activities my-4"
          style={{ display: user.userStatus === "" ? "" : "none" }}
        >
          <div className="MyTopBtn">
            {" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <h5
                style={{ cursor: "pointer" }}
                className="mx-3 mb-2"
                type={showPublishJobs ? "primary" : "default"}
                onClick={handleShowPublishJobs}
              >
                Publish Jobs
              </h5>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <h5
                style={{ cursor: "pointer" }}
                className="mx-3 mb-2"
                type={!showPublishJobs ? "primary" : "default"}
                onClick={handleShowPendingJobs}
              >
                Pending Jobs
              </h5>{" "}
              {!showPublishJobs && (
                <div>
                  <div
                    style={{
                      height: "2px",
                      backgroundColor: "green",
                      width: "80%",
                      margin: "auto",
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>

          <Button type="default" style={{ marginLeft: "10px" }}>
            <Link to={"/Post-Job"}> Post Jobs</Link>
          </Button>
          <div className="activity-info">
            {showPublishJobs && (
              <div>
                <PublishedJob />
              </div>
            )}
            {!showPublishJobs && (
              <div>
                <PendingJobs />
              </div>
            )}
            {/* Add other activity details here */}
          </div>
        </Col>
        <Col
          className="user-activities"
          style={{ display: user.userStatus === "Freelancer" ? "" : "none" }}
        >
          {" "}
          <div className="MyTopBtn">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <h5
                style={{ cursor: "pointer" }}
                className="mx-3 mb-2"
                type={showPublishJobs ? "primary" : "default"}
                onClick={handleShowPublishJobs}
              >
                Publish Gigs
              </h5>{" "}
              {showPublishJobs && (
                <div>
                  <div
                    style={{
                      height: "2px",
                      backgroundColor: "green",
                      width: "80%",
                      margin: "auto",
                    }}
                  ></div>
                </div>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <h5
                style={{ cursor: "pointer" }}
                className="mx-3"
                type={!showPublishJobs ? "primary" : "default"}
                onClick={handleShowPendingJobs}
              >
                Pending Gigs
              </h5>{" "}
              {!showPublishJobs && (
                <div>
                  <div
                    style={{
                      height: "2px",
                      backgroundColor: "green",
                      width: "80%",
                      margin: "auto",
                    }}
                  ></div>
                </div>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <h5
                style={{ cursor: "pointer" }}
                className="mx-3"
                onClick={() => setHistoryModal(true)}
              >
                Job History
              </h5>{" "}
            </div>
          </div>
          <div className="activity-info">
            {showPublishJobs && (
              <div>
                <PublishedGig />
              </div>
            )}
            {!showPublishJobs && (
              <div>
                <PendingGig />
              </div>
            )}
            {/* Add other activity details here */}
          </div>
        </Col>{" "}
        <Col
          className="user-activities"
          style={{ display: user.userStatus === "" ? "" : "none" }}
        >
          {" "}
          <div className="MyTopBtn">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <h5
                style={{ cursor: "pointer" }}
                className="mx-3 mb-2"
                type={showPublishJobs ? "primary" : "default"}
                onClick={handleShowPublishJobs}
              >
                Publish Jobs
              </h5>
              {!showPublishJobs && (
                <div>
                  <div
                    style={{
                      height: "2px",
                      backgroundColor: "green",
                      width: "80%",
                      margin: "auto",
                    }}
                  ></div>
                </div>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <h5
                style={{ cursor: "pointer", marginLeft: "10px" }}
                className="mx-3 mb-2"
                type={!showPublishJobs ? "primary" : "default"}
                onClick={handleShowPendingJobs}
              >
                Jobs History
              </h5>{" "}
              {!showPublishJobs && (
                <div>
                  <div
                    style={{
                      height: "2px",
                      backgroundColor: "green",
                      width: "80%",
                      margin: "auto",
                    }}
                  ></div>
                </div>
              )}
            </div>

            <Button type="default" style={{ marginLeft: "10px" }}>
              <Link to={"/Post-gigs"}> Post Job</Link>
            </Button>
          </div>
          <div className="activity-info">
            {showPublishJobs && (
              <div>
                <PublishedGig />
              </div>
            )}
            {!showPublishJobs && (
              <div>
                <PendingGig />
              </div>
            )}
            {/* Add other activity details here */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserInfo;
