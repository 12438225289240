import React, { useContext } from "react";
import Work from "./Work";
import Navbar from "../CommenComponents/Navbar";
import Footer from "../CommenComponents/Footer";
import { myContext } from "../../App";
import AuthNavbar from "../../AuthComponents/AuthCommen/AuthNavbar";
function FindWorkMain() {
  const ContextData = useContext(myContext);
  const isAuth = ContextData.IsLoggedIn;
  return (
    <div>
      {isAuth ? (
        <>
          <AuthNavbar />
          <Work />
        </>
      ) : (
        <>
          <Navbar />
          <Work />
        </>
      )}

      <Footer />
    </div>
  );
}

export default FindWorkMain;
