import React, { useContext } from "react";
import Pricing from "./Pricing";
import Footer from "../CommenComponents/Footer";
import Navbar from "../CommenComponents/Navbar";
import { myContext } from "../../App";
import AuthNavbar from "../../AuthComponents/AuthCommen/AuthNavbar";
function PricingMain() {
  const a = useContext(myContext);
  const isAuth = a.IsLoggedIn;
  return (
    <div>
      {isAuth ? (
        <>
          <AuthNavbar />
          <Pricing />
        </>
      ) : (
        <>
          <Navbar />
          <Pricing />
        </>
      )}

      <Footer />
    </div>
  );
}

export default PricingMain;
