// ProfileImageUpload.js
import React, { useContext, useRef, useState } from "react";
import { Modal, Avatar, message, Form, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { storage, db } from "../../Config";
import { myContext } from "../../App";
const MyUpload = ({ visible, onCancel, userId, onComplete }) => {
  const fileInputRef = useRef(null);
  const UserData = useContext(myContext);
  const CallMe = UserData.RenderNe;
  const [profilePic, setProfilePic] = useState(null);

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `RegisterUserImages/${file.name}`);

      uploadBytes(storageRef, file)
        .then((snapshot) => {
          console.log("Uploaded a blob or file!", snapshot);
          return getDownloadURL(snapshot.ref);
        })
        .then((downloadURL) => {
          message.success("File Uploaded Click Update Button To Save");
          console.log("File available at", downloadURL);
          setProfilePic(downloadURL);
        })
        .catch((error) => {
          message.error("Error uploading file");
          console.error("Error uploading file: ", error);
          message.error(`${file.name} file upload failed.`);
        });
    } else {
      message.error("No file selected");
      console.log("No file selected");
    }
  };

  const HandleFinnish = () => {
    console.log(profilePic);
    try {
      const userDocRef = doc(db, "user", userId);
      updateDoc(userDocRef, {
        image: profilePic,
      })
        .then((res) => {
          message.success("Profile Updated Successfully");
          CallMe();
        })
        .catch((err) => {
          console.log(err);
          message.error("Sorry Some Error Occurred");
        });
    } catch (error) {
      message.error("Sorry Some Error Occurred");
      console.error("Error updating user status:", error.message);
    }
  };
  const handleAvatarClick = () => {
    // Trigger click event on file input
    fileInputRef.current.click();
  };

  return (
    <>
      {" "}
      <Modal
        title="Upload Profile Picture"
        open={visible}
        onCancel={onCancel}
        footer={null}
      >
        {" "}
        <Form onFinish={HandleFinnish}>
          <Form.Item label="Profile Picture">
            <input
              type="file"
              name="image"
              ref={fileInputRef}
              onChange={handleProfilePicChange}
              style={{ display: "none" }}
            />
            <Avatar
              size={64}
              icon={<UploadOutlined />}
              src={profilePic}
              onClick={handleAvatarClick}
            />
          </Form.Item>{" "}
          <Button type="primary" htmlType="submit">
            Update Profile
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default MyUpload;
