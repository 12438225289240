import React, { useRef, useState, useEffect } from "react";
import "./Caro.css";
import { Link } from "react-router-dom";
import ServicesArray from "../../services.json";

import "../HireTalent/Hire.css";
import { Carousel } from "antd";
import MyServicesLoader from "./myServices/MyServicesLoader";
function Service() {
  const cardSetRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const renderCard = (item, index) => (
    <div className="crollerMorecard" style={{ width: "250px" }} key={index}>
      <Carousel autoplay>
        <div>
          <img
            style={{ height: "9rem" }}
            className="d-block w-100"
            src={item.icon}
            alt="First slide"
          />
        </div>
        <div>
          <img
            style={{ height: "9rem" }}
            className="d-block w-100"
            src={item.icon}
            alt="First slide"
          />
        </div>
        <div>
          <img
            style={{ height: "9rem" }}
            className="d-block w-100"
            src={item.icon}
            alt="First slide"
          />
        </div>
      </Carousel>

      <div className="card-body">
        <div className="d-flex">
          <div className="user-detail">
            <h4 style={{ margin: "auto" }}>{item.name}</h4>
            <p
              style={{
                color: "gray",
                fontFamily: "sans-serif",
                fontSize: ".9rem",
                margin: "auto 0px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-people-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
              </svg>{" "}
              <span style={{ margin: "auto 0px" }}> +199000</span>
            </p>
          </div>
        </div>
        <p className="card-text">
          Some quick example text to build on the card title and
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1.5rem",
            cursor: "pointer",
          }}
        >
          <svg
            style={{ color: "green" }}
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            className="bi bi-chat-left-text"
            viewBox="0 0 16 16"
          >
            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
          </svg>{" "}
          <p
            style={{
              color: "gray",
              fontFamily: "sans-serif",
              fontSize: ".9rem",
              margin: "auto 10px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-stickies-fill"
              viewBox="0 0 16 16"
            >
              <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5" />
              <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177z" />
            </svg>

            <span style={{ margin: "auto 0px" }}> +1900</span>
          </p>
        </div>
      </div>
    </div>
  );

  // State to keep track of the chunk size
  const [chunkSize, setChunkSize] = useState(5);
  const [myPosition, setmyPosition] = useState("absolute");
  // Function to update chunk size based on window width
  const updateChunkSize = () => {
    if (window.innerWidth >= 1100) {
      setChunkSize(5);
    } else if (window.innerWidth >= 800) {
      setChunkSize(3);
      setmyPosition("unset");
    } else if (window.innerWidth >= 500) {
      setChunkSize(2);
    } else {
      setChunkSize(1);
    }
  };

  // Update chunk size on initial render and window resize
  useEffect(() => {
    updateChunkSize();
    window.addEventListener("resize", updateChunkSize);
    return () => {
      window.removeEventListener("resize", updateChunkSize);
    };
  }, []);

  // Split the array into chunks based on the current chunk size
  const chunkedArray = ServicesArray.categories.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    []
  );

  return (
    <div>
      <div class="catagory_area">
        <div class="container">
          <div class="row cat_search">
            <div class="col-lg-3 col-md-4">
              <div class="single_input">
                <input type="text" placeholder="Search keyword" />
              </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="form-row">
                <div class="col">
                  <select class="form-control">
                    <option value="">Location</option>
                    <option value="full-time">Pakistan</option>
                    <option value="part-time">India</option>
                    <option value="contract">US</option>
                    <option value="freelance">UK</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="single_input">
                <div class="form-row">
                  <div class="col">
                    <select class="form-control">
                      <option value="">Job Nature</option>
                      <option value="full-time">Full Time</option>
                      <option value="part-time">Part Time</option>
                      <option value="contract">Contract</option>
                      <option value="freelance">Freelance</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="job_btn">
                <a href="#" class="boxed-btn3">
                  Find Job
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="popular_search d-flex align-items-center">
                <span>Popular Search:</span>
                <ul>
                  <li>
                    <a href="#">Design & Creative</a>
                  </li>
                  <li>
                    <a href="#">Marketing</a>
                  </li>
                  <li>
                    <a href="#">Administration</a>
                  </li>
                  <li>
                    <a href="#">Teaching & Education</a>
                  </li>
                  <li>
                    <a href="#">Engineering</a>
                  </li>
                  <li>
                    <a href="#">Software & Web</a>
                  </li>
                  <li>
                    <a href="#">Telemarketing</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------My Services Sliders --------------------------*/}
      <h2 style={{ padding: "4px 15px" }}>Popular Ithange Services</h2>
      <div
        style={{ backgroundColor: "white" }}
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          {chunkedArray.map((chunk, index) => (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              key={index}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: ".5rem",
                  justifyContent: "center",
                }}
              >
                {chunk.map((item, index) => renderCard(item, index))}
              </div>
            </div>
          ))}
        </div>{" "}
        <div
          style={{
            position: myPosition === "unset" ? myPosition : "none",
            marginTop: "-9rem",
            paddingBottom: "7rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <a
            id="mYprevousnutton"
            style={{
              backgroundColor: "gray",
              padding: "5px",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              top: "50%",
              left: "1%",
              position: myPosition,
            }}
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              style={{
                backgroundColor: "gray",
                borderRadius: "50%",
              }}
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            style={{
              backgroundColor: "gray",
              padding: "5px",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              top: "50%",
              right: "1%",
            }}
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              style={{
                color: "black",
                borderRadius: "50%",
              }}
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        <div style={{ display: myPosition === "absolute" ? "" : "none" }}>
          {" "}
          <a
            id="mYprevousnutton"
            style={{
              backgroundColor: "gray",
              padding: "5px",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              top: "50%",
              left: "1%",
              position: myPosition,
            }}
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              style={{
                backgroundColor: "gray",
                borderRadius: "50%",
              }}
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            style={{
              backgroundColor: "gray",
              padding: "5px",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              top: "50%",
              right: "1%",
            }}
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              style={{
                color: "black",
                borderRadius: "50%",
              }}
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
      {/*---------------------------- Sliders End--------------------------------- */}
      <div
        class="container-fluid"
        style={{
          width: "90%",
          marginTop: "4rem",
          backgroundColor: "lightblue",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h1 style={{ margin: "auto 5px" }}>
            Advance Solution <br />
            And Professional <br />
            Talent For <br />
            Business
          </h1>
          <div style={{ height: "30%", width: "30%" }}>
            <MyServicesLoader />
            {/* <img src="./img/sider.png" class="img-fluid" alt="Your Image" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
