import HomeMain from "./Component/HomeComponents/HomeMain";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FindWorkMain from "./Component/FindWork/FindWorkMain";
import HireTalentMain from "./Component/HireTalent/HireTalentMain";
import WhyChooseMain from "./Component/WhyChooseUs/WhyChooseMain";
import PricingMain from "./Component/Pricing/PricingMain";
import BlogsMai from "./Component/BlogsComponents/blogsMai";
import SafePayMain from "./Component/SafePay/SafePayMain";
import Login from "./Component/UserAuthentications/LoginAuthUser/Login";
import SignUp from "./Component/UserAuthentications/RegisterNewUser/SignUp";
import Status from "./Component/UserAuthentications/RegisterNewUser/Status";
import MianPost from "./Component/PostJobComponent/MianPost";
import Jobs from "./Component/Jobs-details/JobsDetailsMain";
import { createContext, useEffect, useState } from "react";
import Profile from "./AuthComponents/Profile/Profile";
import ChatBox from "./AuthComponents/ClientMessage/message/ChatBox";
import { auth, db } from "./Config";
import { message } from "antd";
import { onAuthStateChanged } from "firebase/auth";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import EmailVerification from "./Component/UserAuthentications/RegisterNewUser/EmailVerification";
import GigsDetailsMain from "./Component/HireTalent/GigsDetails/GigDetailsMain";
import SearchUser from "./AuthComponents/SearchUserProfile/SearchUser";
import GigsPostMain from "./AuthComponents/Profile/UserGigPostForm/GigsPostMain";
import JobsApply from "./AuthComponents/Profile/UserJobs/ViewJobapplyDetails/jobsApply";
import Favorits from "./AuthComponents/HomeAuth/HomeCompnents/Favorits";
import MainLoader from "./MainLoader/MainLoader";
const myContext = createContext();
function App() {
  const [IsLoader, setIsLoader] = useState(false);
  useEffect(() => {
    setTimeout(() => {}, 2000);
  }, []);
  const [IsLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userEmail, setuserEmail] = useState(null);
  const [userProfile, setuserProfile] = useState(null);
  const [status, setstatus] = useState(null);
  const [username, setusername] = useState(null);
  const [language, setlanguage] = useState([]);
  const [description, setdescription] = useState(null);
  const [UserLocation, setUserLocation] = useState(null);
  const [skill, setskill] = useState([]);
  const [UserUid, setUserUid] = useState(null);
  const [isEmailVerified, setisEmailVerified] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        const userId = user.uid;
        console.log("User:", user);
        if (user.emailVerified) {
          setisEmailVerified(true);
        } else {
          setisEmailVerified(false);
        }
        setUserUid(userId);
        try {
          const userDocRef = doc(db, "user", userId);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            const name = userDocSnapshot.data().name;
            console.log("User Name:", name);
            setUserName(name);
            setuserEmail(userDocSnapshot.data().email);
            setuserProfile(userDocSnapshot.data().image);
            setstatus(userDocSnapshot.data().status);
            setusername(userDocSnapshot.data().username);
            setlanguage(userDocSnapshot.data().language);
            setdescription(userDocSnapshot.data().description);
            setskill(userDocSnapshot.data().skill);
            setUserLocation(userDocSnapshot.data().location);
            console.log("User Email:", userEmail);
            console.log("User profile:", userProfile);
            setIsLoggedIn(true);
          }
        } catch (error) {
          setIsLoggedIn(false);
          console.error("Error fetching user name:", error);
        }
      } else {
        setIsLoggedIn(false);
        console.log("No user is logged in");
      }
    });
  }, []);

  const RenderNe = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userId = user.uid;
        console.log("User:", user);
        if (user.emailVerified) {
          setisEmailVerified(true);
        } else {
          setisEmailVerified(false);
        }
        setUserUid(userId);
        try {
          const userDocRef = doc(db, "user", userId);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            const name = userDocSnapshot.data().name;
            console.log("User Name:", name);
            setUserName(name);
            setuserEmail(userDocSnapshot.data().email);
            setuserProfile(userDocSnapshot.data().image);
            setstatus(userDocSnapshot.data().status);
            setusername(userDocSnapshot.data().username);
            setlanguage(userDocSnapshot.data().language);
            setdescription(userDocSnapshot.data().description);
            setskill(userDocSnapshot.data().skill);
            setUserLocation(userDocSnapshot.data().location);
            console.log("User Email:", userEmail);
            console.log("User profile:", userProfile);
            setIsLoggedIn(true);
          }
        } catch (error) {
          setIsLoggedIn(false);
          console.error("Error fetching user name:", error);
        }
      } else {
        setIsLoggedIn(false);
        console.log("No user is logged in");
      }
    });
  };
  const [jobId, setJobId] = useState(null);
  const [postId, setpostId] = useState(null);
  const [allJobs, setAllJobs] = useState([]);
  const fetchJobs = async () => {
    try {
      const jobsCollection = await getDocs(collection(db, "MyFavorites"));
      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
      setAllJobs(jobsArray);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);
  return (
    <div>
      {" "}
      {IsLoader ? (
        <>
          {" "}
          <MainLoader />{" "}
        </>
      ) : (
        <>
          {" "}
          <myContext.Provider
            value={{
              fetchJobs,
              postId,
              setpostId,
              setJobId,
              jobId,
              RenderNe,
              IsLoggedIn,
              userName,
              userProfile,
              status,
              language,
              description,
              skill,
              username,
              setIsLoggedIn,
              UserUid,
              isEmailVerified,
              userEmail,
              UserLocation,
              currentUser,
              allJobs,
            }}
          >
            <Router>
              <Routes>
                <Route path="/" element={<HomeMain />} />
                <Route path="/Find-work" element={<FindWorkMain />} />
                <Route path="/Hire-Talent" element={<HireTalentMain />} />
                <Route path="/whyChoose-us" element={<WhyChooseMain />} />
                <Route path="/pricing" element={<PricingMain />} />
                <Route path="/blogs" element={<BlogsMai />} />
                <Route path="/safe-pay" element={<SafePayMain />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/Register" element={<SignUp />} />
                <Route path="/status/:userId" element={<Status />} />
                <Route path="/Post-Job/:job_post_id" element={<MianPost />} />
                <Route path="/Jobs-details/:clientJobid" element={<Jobs />} />
                <Route path="/profile/:name" element={<Profile />} />{" "}
                <Route path="/Gigs-details/:id" element={<GigsDetailsMain />} />{" "}
                <Route path="/Post-gigs" element={<GigsPostMain />} />{" "}
                <Route
                  path="/Hire-Talent/user-profile/:user_id"
                  element={<SearchUser />}
                />
                <Route
                  path="/status/email-verification"
                  element={<EmailVerification />}
                />{" "}
                <Route path="/jobApply/:jobApplyId" element={<JobsApply />} />{" "}
                <Route
                  path="/contact/:id"
                  element={<ChatBox IsLoggedIn={IsLoggedIn} />}
                />{" "}
                <Route path="/myFavorite" element={<Favorits />} />{" "}
              </Routes>
            </Router>
          </myContext.Provider>
        </>
      )}
    </div>
  );
}

export { myContext };
export default App;
