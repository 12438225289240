import React, { useContext, useState } from "react";
import "./MyStyle.css";
import { Link } from "react-router-dom";
import { myContext } from "../../App";

function Navbar() {
  const [IsClicked, setIsClicked] = useState("black");
  const [isOpen, setisOpen] = useState("none");
  const ContextData = useContext(myContext);
  const IsLoggedIn = ContextData.IsLoggedIn;
  const handleOpen = () => {
    setisOpen("block");
    setIsClicked("none");
    console.log("Cliecked");
  };

  const handleClose = () => {
    setisOpen("none");
    setIsClicked("flex");
    console.log("Cliecked");
  };

  return (
    <div>
      <header>
        <div class="header-area">
          <div id="sticky-header" class="main-header-area">
            <div class="container-fluid">
              <div class="header_bottom_border">
                <div
                  class="row align-items-center"
                  id="setBack"
                  style={{ justifyContent: "space-between" }}
                >
                  <div class="col-xl-3 col-lg-2">
                    <div id="MyTopManue" style={{ display: IsClicked }}>
                      <Link
                        class="MobView"
                        to={"/Register"}
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          marginTop: "9px",
                        }}
                      >
                        Join Us
                      </Link>
                      <a
                        class="MobView"
                        // style={{ marginRight: "2.5rem" }}
                        href="index.html"
                      >
                        <img
                          style={{ width: "13rem" }}
                          src="img/logo.png"
                          alt=""
                        />
                      </a>
                      <svg
                        onClick={handleOpen}
                        id="SiderMenuOfTop"
                        style={{ color: "white" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-list"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                        />
                      </svg>
                      <a id="MyLapView" class="lapView" href="index.html">
                        <img
                          id="HideMyLogo"
                          style={{ width: "7rem" }}
                          src="img/logo.png"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-7">
                    <div class="main-menu d-none d-lg-block">
                      <nav>
                        <ul id="navigation">
                          <div
                            class="myMobHeader"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: ".4rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: ".3rem",
                                justifyContent: "flex-end",
                              }}
                            >
                              <svg
                                id="DrpMob"
                                style={{ color: "black", margin: "11px 0px" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down "
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                                />
                              </svg>

                              <button
                                onclick="toggleSlicknavNav()"
                                class="CancelMobBuuton"
                              >
                                <svg
                                  style={{
                                    color: "#2083e0",
                                    cursor: "pointer",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="36"
                                  height="36"
                                  fill="currentColor"
                                  class="bi bi-x CancelMobBuuton"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <li>
                            <Link to={"/"}>Home</Link>
                          </li>
                          <li>
                            <Link to={"/Find-work"}>Find Work</Link>
                          </li>
                          <li>
                            <Link to={"/Hire-Talent"}>Hire Talent</Link>
                          </li>

                          <li class="MoreBuuton">
                            <a href="#">
                              More <i class="ti-angle-down"></i>
                            </a>
                            <ul class="submenu">
                              <li>
                                <Link to={"/whyChoose-us"}>Why Us</Link>
                              </li>
                              <li>
                                <Link to={"/pricing"}>Pricing</Link>
                              </li>
                              <li>
                                <Link to={"/blogs"}>Blogs</Link>
                              </li>
                              <li>
                                <Link to={"/safe-pay"}>Safe Pay</Link>
                              </li>
                            </ul>
                          </li>

                          <li id="mobHeadWhyUs">
                            <a href="WhyChoose.html">Why Us</a>
                          </li>
                          <li id="mobHeadBlogs">
                            <a href="blog.html">Blogs</a>
                          </li>
                          <li id="mobHeadPricings">
                            <a href="pricing.html">Pricing</a>
                          </li>
                          <li id="mobHeadSafePay">
                            <a href="safePay.html">Safe Pay</a>
                          </li>

                          <li style={{ display: IsLoggedIn ? "none" : "" }}>
                            <Link to={"/Login"}>Sign In</Link>
                          </li>
                          <button
                            class="RegDesk"
                            type="submit"
                            style={{
                              display: IsLoggedIn ? "none" : "",
                              backgroundColor: "#00d363",
                              border: "0.1rem solid white",
                              borderRadius: "10px",
                              padding: " 0.4rem 0.7rem",
                              fontWeight: "bold",
                            }}
                          >
                            <Link
                              to={"/Register"}
                              style={{ color: "white", padding: "0.3rem" }}
                            >
                              Join Us
                            </Link>
                          </button>
                          <li style={{ display: !IsLoggedIn ? "none" : "" }}>
                            <button
                              class="RegDesk"
                              type="submit"
                              style={{
                                backgroundColor: "#00d363",
                                border: "0.1rem solid white",
                                borderRadius: "10px",
                                padding: " 0.4rem 0.7rem",
                                fontWeight: "bold",
                              }}
                            >
                              <a
                                href="register.html"
                                style={{ color: "white", padding: "0.3rem" }}
                              >
                                Profile
                              </a>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <div class="col-12">
                    <div
                      style={{ margin: "auto" }}
                      class="mobile_menu d-block d-lg-none"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        className="MyTopEer fixed-top"
        style={{ display: isOpen, backgroundColor: "white" }}
      >
        <div className="WrapNav">
          <img
            style={{ width: "30%", height: "100%" }}
            src="./img/black_logo.png"
            alt=""
            srcset=""
          />
          <div className="SiderWraooer">
            <svg
              color="blue"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-envelope"
              viewBox="0 0 16 16"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
            </svg>
            <div className="mx-2">
              <li>
                <a href="#">
                  Language <i class="ti-angle-down"></i>
                </a>
                <ul class="submenu">
                  <li>
                    <a href="#">English</a>
                  </li>
                  <li>
                    <a href="#">Urdu</a>
                  </li>
                  <li>
                    <a href="#">Hindi</a>
                  </li>
                </ul>
              </li>
            </div>
            <svg
              onClick={handleClose}
              color="blue"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-x-lg mx-2"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </div>
        </div>
        <div className="enuLinks">
          <li>
            <Link to={"/Find-work"}>Find Work</Link>
          </li>
          <li>
            <Link to={"/Hire-Talent"} className="my-2">
              Hire Talent
            </Link>
          </li>
          <li id="mobHeadWhyUs">
            <Link to={"/whyChoose-us"}>Why Us</Link>
          </li>
          <li id="mobHeadBlogs">
            <Link to={"/blogs"} href="blog.html" className="my-2">
              Blogs
            </Link>
          </li>
          <li id="mobHeadPricings">
            <Link to={"/pricing"} className="my-2">
              Pricing
            </Link>
          </li>
          <li id="mobHeadSafePay">
            <Link to={"/safe-pay"}>Safe Pay</Link>
          </li>
        </div>
        <div class="MobileLoginAndRegster">
          <Link
            style={{
              fontWeight: "bold",
              textAlign: "center",
              border: "0.1rem solid #2083e0",
              padding: "0.2rem",
              color: "rgb(255, 255, 255)",
              borderRadius: "10px",
              width: "80%",
              backgroundColor: "#2083e0",
            }}
            to={"/Register"}
          >
            Login
          </Link>
          <Link
            class="my-3"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              border: "0.1rem solid #black",
              padding: "0.2rem",
              color: "black",
              borderRadius: "10px",
              width: "80%",
              backgroundColor: "white",
            }}
            to={"/Register"}
          >
            Join Us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
