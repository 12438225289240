import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../../Config";
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { message } from "antd";
import { myContext } from "../../../App";

function SignUp() {
  const MyUserData = useContext(myContext);
  let setIsLoggedIn = MyUserData.setIsLoggedIn;
  let RenderNe = MyUserData.RenderNe;
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useState(""); // New state for user status
  const [userUid, setUserUid] = useState("");
  const [UserData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeCheckbox: false,
  });
  const [passwordError, setPasswordError] = useState("");
  const [LoggedInFaceBook, setLoggedInFaceBook] = useState(false);
  const handleSignUpData = async (e) => {
    setLoggedInFaceBook(true);
    e.preventDefault();
    try {
      console.log("Form Data:", UserData);
      if (UserData.password !== UserData.confirmPassword) {
        setPasswordError("Confirm Your Password Again");
        setLoggedInFaceBook(true);
        setTimeout(() => {
          setPasswordError("");
        }, 3000);
        return;
      }
      const fullName = `${UserData.firstName} ${UserData.lastName}`;
      const { email, password } = UserData;
      const res = await createUserWithEmailAndPassword(auth, email, password);

      const userDocRef = doc(db, "user", res.user.uid);
      await setDoc(doc(db, "userChats", res.user.uid), {});
      await setDoc(userDocRef, {
        uid: res.user.uid,
        name: fullName,
        email: email,
        status: "pending",
        image: "none",
        description: "none",
        language: "none",
        speakingLevel: "none",
        skill: "none",
        username: "none",
        location: "none",
      });
      await sendEmailVerification(res.user);
      console.log("Verification email sent.");
      message.success("Verification email has been sent please verify email");
      RenderNe();
      message.success("User registered Success");
      console.log("User Completely registered");
      setUserStatus(UserData.agreeCheckbox ? "freelancer" : "client"); // Set user status
      setUserUid(res.user.uid);
      navigate(`/status/${res.user.uid}`);
      setUserData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        agreeCheckbox: false,
      });
      setIsLoggedIn(true);
      setPasswordError("");
      setLoggedInFaceBook(true);
    } catch (error) {
      setIsLoggedIn(false);
      console.error("Error during user registration:", error.message);
      setLoggedInFaceBook(true);
      // Log the specific error message to help identify the issue
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setUserData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  let FaceBookUser;
  console.log(FaceBookUser);

  let FaceBookProvider = new FacebookAuthProvider();
  const handleFacebook = async () => {
    setLoggedInFaceBook(true);
    try {
      const res = await signInWithPopup(auth, FaceBookProvider).then(
        async (e) => {
          const userDocRef = doc(db, "user", e.user.uid);

          await setDoc(userDocRef, {
            uid: e.user.uid,
            name: e.user.displayName,
            email: e.user.email,
            status: "pending",
            image: "none",
            description: "none",
            language: "none",
            speakingLevel: "none",
            skill: "none",
            username: "none",
            location: "none",
          });
          await setDoc(doc(db, "userChats", e.user.uid), {});
          message.success("User registered Success");
          console.log("User Completely registered");
          setUserStatus(UserData.agreeCheckbox ? "freelancer" : "client"); // Set user status
          setUserUid(res.user.uid);
          navigate(`/status/${res.user.uid}`);
        }
      );
      FaceBookUser = res.user;

      message.success("Logged in successfully");
    } catch (error) {
      console.error(error);
      setLoggedInFaceBook(false);
      message.error("Error Occurred");
    }
  };
  let provider = new GoogleAuthProvider();
  const handleGoogle = () => {
    setLoggedInFaceBook(true);
    signInWithPopup(auth, provider)
      .then((res) => {
        const userDocRef = doc(db, "user", res.user.uid);
        setDoc(userDocRef, {
          uid: res.user.uid,
          name: res.user.displayName,
          email: res.user.email,
          status: "pending",
          image: "none",
          description: "none",
          language: "none",
          speakingLevel: "none",
          skill: "none",
          username: "none",
          location: "none",
        });
        setDoc(doc(db, "userChats", res.user.uid), {});

        console.log("User Completely registered");
        setUserStatus(UserData.agreeCheckbox ? "freelancer" : "client"); // Set user status
        setUserUid(res.user.uid); // Set user UID

        navigate(`/status/${res.user.uid}`);
        message.success("Logged in successfully");
        setLoggedInFaceBook(false);
      })
      .catch((error) => {
        message.error("Sorry internal error occure");
        setLoggedInFaceBook(false);
      });
  };

  const handleApple = () => {};
  return (
    <div>
      <div class="container" style={{ marginTop: "4rem" }}>
        <div class="card login-card">
          <div style={{ display: "flex", margin: "auto" }}>
            <img
              src="./img/black_logo.png"
              style={{ width: "50%", margin: "auto" }}
              alt="Logo"
              class="img-fluid"
            />
          </div>
          <p class="text-center">Sign Up For Free</p>{" "}
          {LoggedInFaceBook && (
            <p class="text-center">Loading please wait.....</p>
          )}
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: "2rem",
              marginTop: "-2rem",
            }}
          >
            <svg
              onClick={handleFacebook}
              style={{ color: "blue", cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-facebook mx-2"
              viewBox="0 0 16 16"
            >
              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
            </svg>
            <svg
              onClick={handleGoogle}
              style={{ color: "green", cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-google mx-2"
              viewBox="0 0 16 16"
            >
              <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
            </svg>
            <svg
              onClick={handleApple}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-apple mx-2"
              viewBox="0 0 16 16"
            >
              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
            </svg>
          </div>
          {passwordError && (
            <div className="alert alert-danger" role="alert">
              {passwordError}
            </div>
          )}
          <form class="login-form" onSubmit={handleSignUpData}>
            <div class="form-row">
              <div class="col">
                <input
                  name="firstName" // Corrected name attribute
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  onChange={handleInputChange}
                />
              </div>
              <div class="col">
                <input
                  name="lastName"
                  type="text"
                  class="form-control"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <input
                  name="email"
                  onChange={handleInputChange}
                  type="email"
                  class="form-control"
                  placeholder="Email"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <input
                  name="password"
                  onChange={handleInputChange}
                  type="password"
                  class="form-control"
                  placeholder="Password"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <input
                  name="confirmPassword"
                  type="password"
                  class="form-control"
                  placeholder="Confirm Password"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div class="form-check agree-checkbox">
              <input
                type="checkbox"
                className="form-check-input"
                id="agreeCheckbox"
                name="agreeCheckbox"
                checked={UserData.agreeCheckbox}
                onChange={handleInputChange}
              />
              <label className="form-check-label" htmlFor="agreeCheckbox">
                I agree with the terms and conditions
              </label>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
            >
              <button type="submit" className="btn btn-primary btn-block">
                {/* <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to={"/status"}
                > */}
                Create Account
                {/* </Link> */}
              </button>
              <button type="button" class="btn btn-primary btn-block">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to={"/"}
                >
                  Back To Home
                </Link>
              </button>
            </div>

            <hr />

            <p class="already-have-account">
              Already have an account? <Link to={"/Login"}>Login here</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
