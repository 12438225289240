import React, { useEffect, useState } from "react";
import "./style.css";
import { Input, message, Modal, Spin } from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../Config";
import Preview from "./Preview";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
function Six() {
  const navigate = useNavigate();
  const { job_post_id } = useParams();
  const [jobs, setJobs] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [myDescription, setMyDescription] = useState("");
  const [JobId, setJobId] = useState(null);
  const [myTitle, setMyTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  // const TestPayment = async (e) => {
  //   setisLoading(true);
  //   const stripe = await loadStripe(
  //     "pk_test_51O23NvCAlNqJafxefxg3WWtPsoyFmnjPivwGUgNhdSbMh6ic3Hw7lMGI7oYvxCsXyVKPArG1Lc45eRModrCFjKC800nRls879g"
  //   );
  //   const priceInCents = e;
  //   if (!isNaN(priceInCents)) {
  //     const body = {
  //       products: [
  //         {
  //           id: cart.id,
  //           title: "Ithange CheckOut",
  //           price: priceInCents, // Send the price in cents
  //         },
  //       ],
  //     };
  //     const header = {
  //       "Content-Type": "application/json",
  //     };
  //     const response = await fetch(
  //       "http://localhost:5000/api/create-checkout-session",
  //       {
  //         method: "POST",
  //         headers: header,
  //         body: JSON.stringify(body),
  //       }
  //     );
  //     const session = await response.json(); // Await the response.json() function
  //     const result = await stripe.redirectToCheckout({
  //       sessionId: session.id,
  //     });
  //     if (result.error) {
  //       console.log(result.error);
  //       setisLoading(false);
  //     } else {
  //       console.log("API Fetch Done");
  //       setisLoading(false);
  //     }
  //     setisLoading(false);
  //   } else {
  //     console.error("Invalid cart price:", cart.price);
  //     setisLoading(false);
  //   }
  // };
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "UpdatedJobs"));
        const fetchedJobs = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.jobId === job_post_id) {
            fetchedJobs.push(data);
          }
        });
        setJobs(fetchedJobs);
        if (fetchedJobs.length > 0) {
          setMyTitle(fetchedJobs[0].job_title);
          setMyDescription(fetchedJobs[0].description);
          setJobId(fetchedJobs[0].jobId);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };
    fetchJobs();
  }, [job_post_id]);
  const [AllUsers, setAllUsers] = useState([]);
  const getAllUsers = async () => {
    const usersCollection = collection(db, "user");
    const querySnapshot = await getDocs(usersCollection);
    const users = [];

    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    return users;
  };
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const users = await getAllUsers();
        const userEmails = users.map((user) => user.email);
        setAllUsers(userEmails);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchAllUsers();
  }, []);
  const handlellDummyUsers = () => {
    console.log(AllUsers);
  };

  const [myMessage, setMyMessage] = useState("");
  const constructMessage = (title, description, JobId) => {
    return `<h1 style="color: gray; text-align:center;">Hello Dear Ithange User New Job Posted</h1>  <br/>      <img
            style="width: 20%; height: 20%;"
            src="https://firebasestorage.googleapis.com/v0/b/ithange-d0a4c.appspot.com/o/RegisterUserImages%2Fblack_logo.png?alt=media&token=6499d5fb-c66e-4393-9ed0-6b7fd6ebec8a"
            alt="laoding"
          />  <br/> 
      <h3 style="color: green;">${title}</h3>
      <p style="color: gray;">${description}</p> <br/>
      <a style="color: green;" class="btn btn-success" href="https://ithange.ee/Jobs-details/${JobId}">View Details</a>

      <p style="color: gray;">Regarded:Ithange Team</p>
    `;
  };
  useEffect(() => {
    const message = constructMessage(myTitle, myDescription, JobId);
    setMyMessage(message);
  }, [myTitle, myDescription, JobId]);
  const handleSendEmails = async () => {
    try {
      await axios.post("http://localhost:5000/send-emails", {
        emails: AllUsers,
        message: myMessage,
      });
      // alert("Emails sent successfully!");
    } catch (error) {
      // alert("Failed to send emails.");
    }
  };
  const SubmitFinal = async () => {
    console.log(myDescription);
    console.log(myTitle);
    try {
      const currentDate = new Date(); // Get the current date
      const userDocRef = doc(db, "UpdatedJobs", job_post_id);
      await updateDoc(userDocRef, {
        pending: false,
        job_title: myTitle,
        description: myDescription,
        postedDate: currentDate.toISOString(),
      });
      await handleSendEmails();
      message.success("Job Posted Successfully");
      navigate("/");
    } catch (error) {
      console.error(error);
      message.error("Sorry, some error occurred");
    }
  };

  const toggleEdit = () => {
    setShowForm(!showForm);
  };

  const constructDescription = (
    title,
    purpose,
    platform,
    skill,
    tags,
    description
  ) => {
    const skillList = Array.isArray(skill)
      ? skill.join(", ")
      : "No skills specified";
    const tagList = Array.isArray(tags) ? tags.join(", ") : "No tags specified";

    return ` ${description}

  Required Skills: ${skillList}
  
  Additional Tags: ${tagList}`;
  };

  return (
    <div>
      {" "}
      <Modal
        width={1200}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <Preview jobs={jobs} />
      </Modal>
      {jobs &&
        jobs.map((e, i) => {
          return (
            <>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  padding: "3px 10px",
                }}
              >
                <h3 style={{ textAlign: "center" }}>
                  Are These Details Are Correct?{" "}
                  <EditOutlined onClick={() => setShowForm(true)} />{" "}
                </h3>
              </div>
              <div className="myFinalCrads">
                <div className="myLeftSide">
                  <svg
                    style={{ color: "blue" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="56"
                    fill="currentColor"
                    class="bi bi-pc-display-horizontal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5M12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0m2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0M1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1M1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25" />
                  </svg>
                  <p style={{ textAlign: "center", color: "gray" }}>
                    {e.duration} <br />
                    {e.price}
                  </p>
                </div>
                <div className="myRightSide">
                  {" "}
                  <div style={{ display: showForm ? "" : "none" }}>
                    <Input
                      value={myTitle}
                      onChange={(e) => setMyTitle(e.target.value)}
                    />
                    <Input.TextArea
                      onChange={(e) => setMyDescription(e.target.value)}
                      className="my-4"
                      style={{ height: "13rem" }}
                      value={myDescription}
                    />
                  </div>
                  <h4 style={{ display: showForm ? "none" : "" }}>
                    {e.job_title}
                  </h4>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "1.4rem",
                      display: showForm ? "none" : "",
                    }}
                  >
                    {constructDescription(
                      e.job_title,
                      e.AppPurpose,
                      e.plateForm,
                      e.skill,
                      e.tags,
                      e.description
                    )}
                  </p>
                  <br />{" "}
                  <div
                    className="myMenusSelction3 my-4"
                    style={{ border: "1px solid blue" }}
                  >
                    {" "}
                    {jobs.map(
                      (category) =>
                        category.tags &&
                        category.tags.map((e, index) => (
                          <p
                            className="mx-2"
                            style={{
                              border: "1px solid gray",
                              borderRadius: "20px",
                              padding: "5px 10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {e}
                          </p>
                        ))
                    )}
                    <Input placeholder="Enter your tags" />
                  </div>{" "}
                  <div
                    style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                  >
                    {jobs.map(
                      (category) =>
                        category.tags &&
                        category.pakage.map((e, index) => (
                          <p
                            style={{
                              width: "150px",
                              padding: "2px 10px",
                              color: "white",
                              textAlign: "center",
                              backgroundColor:
                                e === "FREE"
                                  ? "green"
                                  : e === "FEATURED"
                                  ? "red"
                                  : e === "Top CONTEST"
                                  ? "rgb(68, 55, 55)"
                                  : e === "HIGHLIGHT"
                                  ? "blue"
                                  : e === "PRIVATE"
                                  ? "rgb(159, 149, 35)"
                                  : "green",
                              borderRadius: "20px",
                              //   position: "absolute",
                            }}
                          >
                            {e}
                          </p>
                        ))
                    )}
                  </div>
                </div>
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  marginLeft: "20px",
                  justifyContent: "space-between",
                }}
              >
                <button
                  onClick={SubmitFinal}
                  type="success"
                  className="btn btn-success my-3"
                >
                  Post a Job
                </button>
                {/* <button
                  onClick={() => TestPayment(e.price)}
                  type="success"
                  className="btn btn-success my-3"
                >
                  Test Payment {isLoading && <Spin />}
                </button> */}
                <button
                  onClick={() => setIsModalOpen(true)}
                  type="success"
                  className="btn btn-success my-3"
                >
                  See Preview
                </button>
              </div>
            </>
          );
        })}
    </div>
  );
}

export default Six;
