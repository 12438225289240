import React from "react";
import Lottie from "lottie-react";
import MyLoader from "./Animation - 1708597425672 (1).json";

function MainLoader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Lottie
        style={{ height: "30%" }}
        animationData={MyLoader}
        autoplay
        loop
      />
    </div>
  );
}

export default MainLoader;
