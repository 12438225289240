import React from "react";
import Navbar from "./Navbar"
import Search from "./Search"
import Chats from "./Chats"

const Sidebar = ({setisShow,id,isShow}) => {
  return (
    <>
     
      <div className="sidebar">
      <Navbar />
        <Search id={ id} />
        <Chats setisShow={setisShow} isShow={ isShow} />
      </div>
     
    </>
   
  );
};

export default Sidebar;
