import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  collection,
  getDocs,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../../Config";
import { myContext } from "../../../../App";
import { Button, Modal } from "antd";
import AuthNavbar from "../../../AuthCommen/AuthNavbar";
import Footer from "../../../../Component/CommenComponents/Footer";
import "./apply.css";
function JobsApply() {
  const { jobApplyId } = useParams();
  const a = useContext(myContext);
  let UserID = a.UserUid;
  const [allJobs, setAllJobs] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false); // State to manage the visibility of the delete confirmation modal
  const [deleteJobId, setDeleteJobId] = useState(null);

  const deleteJob = async () => {
    try {
      await deleteDoc(doc(db, "JobApply", deleteJobId)); // Delete the document
      setDeleteModalVisible(false); // Close the modal
      fetchJobs(); // Refetch the jobs after deletion
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };
  const fetchJobs = async () => {
    setisLoading(true);
    try {
      const jobsCollection = await getDocs(collection(db, "JobApply"));
      const usersArray = []; // Create an array to store user data
      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          const userId = doc.data().uid;
          const user = await getUserData(userId);
          if (user && Object.keys(user).length > 0) {
            // Push user data into the array
            usersArray.push({
              id: userId,
              name: user.name !== "" ? user.name : "", // Use empty string as a fallback if name is undefined
              profilePicture: user.image !== "" ? user.image : "", // Use empty string as a fallback if profilePicture is undefined
            });
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
      setAllUsers(usersArray);
      setAllJobs(jobsArray);
      setisLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoading(false);
    }
  };
  const getUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "user", userId));
      return userDoc.exists() ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);
  useEffect(() => {
    console.log(allJobs);
  }, [allJobs]);
  useEffect(() => {
    console.log(AllUsers);
  }, [AllUsers]);

  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  return (
    <div>
      <AuthNavbar />{" "}
      <Modal
        title="Confirm Deletion"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setDeleteModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={deleteJob}>
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this apply this apply will permanently
        delete?
      </Modal>
      <h2 style={{ textAlign: "center", fontFamily: "sans-serif" }}>
        Total Users Apply To Your Jobs
      </h2>
      <br />
      <p
        style={{
          textAlign: "center",
          fontFamily: "sans-serif",
          color: "green",
          marginTop: "-20px",
        }}
      >
        Explore More With Ithange.ee
      </p>
      {/* <h2>{UserID}</h2> */}
      {isLoading && <p style={{ textAlign: "center" }}>Loading....</p>}
      {allJobs
        .filter((i) => i.jobId === UserID)
        .map((e) => {
          return (
            <>
              <div class="card  my-4" id="myApplyUserCard">
                <div class="card-body">
                  <p>User Name: {e.userName} </p>
                  <p>User Email: {e.userEmail}</p>
                  <p>
                    {e.coverLater}
                    <a
                      className="btn btn-primary"
                      href={e.cvLink}
                      target="_blank"
                      //   rel="noopener noreferrer" // Recommended security measure when using target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                      </svg>{" "}
                      Download Cv
                    </a>
                  </p>
                  <button
                    className="btn  btn-danger"
                    onClick={() => {
                      setDeleteModalVisible(true);
                      setDeleteJobId(e.id);
                    }}
                  >
                    Reject Apply
                  </button>
                </div>
              </div>
            </>
          );
        })}
      <Footer />
    </div>
  );
}

export default JobsApply;
