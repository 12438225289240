import React, { useContext, useState } from "react";
import Cam from "../img/cam.png";
import Add from "../img/add.png";
import More from "../img/more.png";
import Messages from "./Messages"; import { Avatar } from 'antd';
import Input from "./Input";import '../style.css'
import { ChatContext } from "../context/ChatContext";
const Chat = ({setisShow}) => {
  
  const { data } = useContext(ChatContext);
  const handleChange = () => {
    setisShow(true);
  }
  return (
    <>
      <div className="chat">
        <div className="chatInfo">
          
         
          <span className="myIcon" style={{display:"flex"}} onClick={handleChange}>
          <svg className="myChoranIconTopToBack" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-chevron-compact-left backButton" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
            </svg> <Avatar src= {data.user?.profilePic} />
            {data.user?.name}
          </span>
          <div className="chatIcons">
            <img src={Cam} alt="" />
            <img src={Add} alt="" />
            <img src={More} alt="" />
          </div>
        </div>
        <Messages />
        <Input />
      </div>
    </>
  );
};

export default Chat;
