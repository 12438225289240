import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../Config";
import "./sta.css";
import UserLastForm from "./UserLastForm";
import EmailVerification from "./EmailVerification";
import { myContext } from "../../../App";
import { message } from "antd";
function Status() {
  const navi = useNavigate();
  const MyUserData = useContext(myContext);
  let RenderNe = MyUserData.RenderNe;
  let userStatus = MyUserData.status;
  let Myusername = MyUserData.username;
  let description = MyUserData.description;
  let userProfile = MyUserData.userProfile;
  let skill = MyUserData.skill;
  let language = MyUserData.language;
  let isEmailVerify = MyUserData.isEmailVerified;
  let IsLoggedIn = MyUserData.IsLoggedIn;
  const { userId } = useParams();
  const location = useLocation();
  const [IsStatusSelected, setIsStatusSelected] = useState(false);
  const [IsUsernameSelected, setIsUsernameSelected] = useState(false);
  const [IsRegComplete, setIsRegComplete] = useState(false);
  const [buttonText, setButtonText] = useState("Create Account");
  const [UserStatus, setUserStatus] = useState("Freelancer");

  const HandleFreelancer = () => {
    setButtonText("Join as Freelancer");
  };
  const handleChange = (c) => {
    if (c === "Join as Client") {
      setUserStatus("Client");
    } else {
      setUserStatus("Freelancer");
    }

    setButtonText(c);
  };

  const [suggestedNames, setSuggestedNames] = useState([]);

  const updateUserStatus = async () => {
    try {
      const userDocRef = doc(db, "user", userId);
      await updateDoc(userDocRef, {
        status: UserStatus,
      });
      setIsStatusSelected(true);
      RenderNe();
      console.log("User status updated:" + UserStatus);
    } catch (error) {
      setIsStatusSelected(false);
      console.error("Error updating user status:", error.message);
    }
  };
  const [username, setUsername] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleADusername = async () => {
    if (username !== "") {
      try {
        const usernameExistsQuery = query(
          collection(db, "user"),
          where("username", "==", username)
        );
        const existingUserSnapshot = await getDocs(usernameExistsQuery);
        console.log(existingUserSnapshot);
        if (!existingUserSnapshot.empty) {
          message.error(
            "Username is already taken. Please choose another one."
          );
          console.error(
            "Username is already taken. Please choose another one."
          );
          return;
        }
        const userDocRef = doc(db, "user", userId);
        await updateDoc(userDocRef, {
          username: username,
        });
        message.success("Username Update Success");
        setIsUsernameSelected(true);
        // navi("/");
        // console.log("Username updated:", username);
        RenderNe();
      } catch (error) {
        console.error("Error updating username:", error.message);
      }
    }
  };
  useEffect(() => {
    generateSuggestedNames();
  }, [username]);

  const generateRandomNumber = () => {
    return Math.floor(1000 + Math.random() * 9000);
  };
  const generateSuggestedNames = () => {
    if (username !== "") {
      const suffixes = ["a", "b", "s", "m"];
      const suggestions = suffixes.map(
        (suffix) => `${username}${generateRandomNumber()}${suffix}`
      );
      setSuggestedNames(suggestions);
    } else {
      setSuggestedNames([]);
    }
  };
  const handleSuggestedNameClick = async (e) => {
    setUsername(e);
  };
  return (
    <div className="container mt-5">
      <div
        style={{
          display:
            !IsStatusSelected && !IsUsernameSelected && !IsRegComplete
              ? "flex"
              : "none",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "2rem",
            marginTop: "3rem",
          }}
        >
          <div
            style={{
              padding: "1rem",
              borderRadius: "20px",
              width: "300px",
              border:
                UserStatus == "Freelancer"
                  ? "0.1rem solid green"
                  : "0.1rem solid gray",
              backgroundColor:
                UserStatus == "Freelancer" ? "lightgreen" : "white",
            }}
            onClick={HandleFreelancer}
          >
            <label className="custom-radio" style={{ float: "right" }}>
              <input
                type="radio"
                name="freelancerType"
                value="Join as Freelancer"
                onChange={(e) => handleChange(e.target.value)}
              />
              <span className="checkmark"></span>
            </label>
            <div className="card-body">
              <img
                style={{ width: "30%", height: "22%" }}
                src="../img/partnership.png"
                alt="loading"
              />
              <h5 className="card-title" style={{ color: "green" }}>
                I Am Freelancer,Looking
                <br />
                For Work
              </h5>
            </div>
          </div>
          <div
            style={{
              padding: "1rem",
              borderRadius: "20px",
              width: "300px",
              border:
                UserStatus == "Client"
                  ? "0.1rem solid green"
                  : "0.1rem solid gray",
              backgroundColor: UserStatus == "Client" ? "lightgreen" : "white",
            }}
            onClick={() => setButtonText("Join as Client")}
          >
            <label className="custom-radio" style={{ float: "right" }}>
              <input
                type="radio"
                name="freelancerType"
                value="Join as Client"
                onChange={(e) => handleChange(e.target.value)}
              />
              <span className="checkmark"></span>
            </label>
            <div className="card-body">
              <img
                style={{ width: "22%", height: "22%" }}
                src="../img/landing-page.png"
                alt="loading"
              />
              <h5 className="card-title" style={{ color: "green" }}>
                I Am Client,Hiring
                <br />
                For a Project
              </h5>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <button
            onClick={updateUserStatus}
            id="joinButton"
            className="btn btn-primary"
            style={{ margin: "2rem auto" }}
          >
            {buttonText}
          </button>
          <p className="already-have-account my-4" style={{ margin: "auto" }}>
            Already have an account? <Link to={"/Login"}>Login here</Link>
          </p>
        </div>
      </div>
      <div>
        <div
          style={{
            display:
              IsStatusSelected && !IsRegComplete && !IsUsernameSelected
                ? "flex"
                : "none",
          }}
        >
          <div id="MyUserNameCards">
            <div className="MyImageSiders">
              <img
                style={{ height: "100%", width: "100%" }}
                src="../img/log.png"
                alt="Cover Image"
                className="img-fluid"
              />
            </div>

            <div className="MyLeftSider">
              <button className="btn btn-sm btn-primary float-end">
                &#8592; Back
              </button>
              <div className="MyText">
                <h2 className="heading">Get your profile started</h2>
                <p className="text" style={{ fontSize: "0.7rem" }}>
                  Add a username that's unique to you; this is how you'll appear
                  to others.
                </p>
                <span className="warning-text">
                  You can't change your username, so choose wisely.
                </span>
                <br></br>
                <input
                  style={{ marginTop: "2rem" }}
                  type="text"
                  placeholder="Enter your username"
                  className="form-control mt-3"
                  value={username}
                  onChange={handleUsernameChange}
                />
                <br />
                <div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {" "}
                    {suggestedNames.length !== 0 && (
                      <p style={{ marginTop: "-10px" }} className="mx-2">
                        Suggested Name:{" "}
                      </p>
                    )}
                    {suggestedNames.map((name, index) => (
                      <span
                        key={index}
                        style={{
                          cursor: "pointer",
                          marginTop: "-10px",
                          color: "green",
                        }}
                        onClick={() => handleSuggestedNameClick(name)}
                      >
                        {name}/
                      </span>
                    ))}
                  </div>
                </div>
                <span style={{ fontWeight: "bold", color: "red" }}>
                  username must begin with a letter or numbers and underscores.
                </span>
                <br />
                <button
                  className="my-3 btn btn-primary"
                  style={{ marginTop: "2rem" }}
                  type="submit"
                  onClick={handleADusername}
                >
                  Create My Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ShowMe">
        <div
          className="MobileCards"
          style={{
            display:
              IsStatusSelected && !IsRegComplete && !IsUsernameSelected
                ? "block"
                : "none",
          }}
        >
          <button className="btn btn-sm btn-primary float-end">
            &#8592; Back
          </button>
          <div className="MyText">
            <h2 className="heading">Get your profile started</h2>
            <p className="text">
              Add a username that's unique to you; this is how you'll appear to
              others.
            </p>
            <span className="warning-text">
              You can't change your username, so choose wisely.
            </span>
            <br></br>
            <input
              style={{ marginTop: "2rem" }}
              type="text"
              placeholder="Enter your username"
              className="form-control mt-3"
              value={username}
              onChange={handleUsernameChange}
            />
            <br />{" "}
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {" "}
              {suggestedNames.length !== 0 && (
                <p style={{ marginTop: "-10px" }} className="mx-2">
                  Suggested Name:{" "}
                </p>
              )}
              {suggestedNames.map((name, index) => (
                <span
                  key={index}
                  style={{
                    cursor: "pointer",
                    marginTop: "-10px",
                    color: "green",
                  }}
                  onClick={() => handleSuggestedNameClick(name)}
                >
                  {name}/
                </span>
              ))}
            </div>
            {/* <span style={{ fontWeight: "bold", color: "red" }}>
              Your username must begin with a letter and can include numbers and
              underscores.
            </span> */}
            <br />
            <button
              onClick={handleADusername}
              className="my-3"
              style={{ marginTop: "2rem" }}
              type="submit"
            >
              Create My Account
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          display:
            IsUsernameSelected &&
            IsStatusSelected &&
            !IsRegComplete &&
            userStatus !== "Client"
              ? "block"
              : "none",
        }}
      >
        <UserLastForm userId={userId} setIsRegComplete={setIsRegComplete} />
      </div>

      <div
        style={{
          display:
            IsStatusSelected &&
            IsUsernameSelected &&
            IsRegComplete &&
            !isEmailVerify
              ? ""
              : "none",
        }}
      >
        <EmailVerification
          RenderNe={RenderNe}
          userStatus={userStatus}
          isEmailVerify={isEmailVerify}
          Myusername={Myusername}
        />
      </div>
    </div>
  );
}

export default Status;
