import React from "react";
import AuthNavbar from "../../AuthCommen/AuthNavbar";
import MyForm from "./MyForm";

function Gig() {
  return (
    <div>
      <AuthNavbar />
      <MyForm />
    </div>
  );
}

export default Gig;
