import React, { useState } from "react";
import { auth } from "../../../Config";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { message } from "antd";
function ForgotPassword({ setIsRestPassword }) {
  const [isLoading, setisLoading] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const resetPassword = (e) => {
    e.preventDefault();
    setisLoading(true);
    sendPasswordResetEmail(auth, resetEmail)
      .then(function () {
        message.success("Email sent to the: " + resetEmail);
        setisLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("authentication failed try again");
      });
  };
  return (
    <div>
      <div class="container" style={{ marginTop: "4rem" }}>
        <div class="card login-card">
          <img
            src="./img/black_logo.png"
            style={{ width: "70%", margin: "auto" }}
            alt="Logo"
          />
          <h2 class="text-center my-4">Reset Password</h2>
          {isLoading && (
            <p style={{ textAlign: "center" }}>Sending Reset Link....</p>
          )}
          <form class="login-form" onSubmit={resetPassword}>
            <div class="form-row">
              <div class="col">
                <input
                  required
                  value={resetEmail}
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
            >
              <button type="submit" class="btn btn-primary btn-block">
                Send Reset Link
              </button>
              <button
                onClick={() => setIsRestPassword(false)}
                type="button"
                class="btn btn-primary btn-block"
              >
                <Link
                  //   to={"/"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Back To Home
                </Link>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
