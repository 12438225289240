import React from "react";

function Safe() {
  return (
    <div>
      <div class="bradcam_area bradcam_bg_1">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>Pricing</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="container mt-5 safepay-section">
        <h2 class="text-center mb-4">Safe Pay for Your Peace of Mind</h2>

        <div class="row">
          <div class="col-md-4">
            <div class="safepay-feature">
              <img
                style={{ width: "100%", height: "9rem" }}
                src="https://img.freepik.com/free-photo/3d-render-online-bill-payment-invoice-concept_107791-16582.jpg?t=st=1706169061~exp=1706169661~hmac=186dd38337a608c08017954140ba3559ac4b4b49494fd36ce8c527d1ecf6477c"
                alt="Safe Pay Icon"
                class="safepay-icon"
              />
              <h4>Secure Transactions</h4>
              <p>
                Enjoy the confidence of secure financial transactions through
                our platform.
              </p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="safepay-feature">
              <img
                style={{ width: "100%", height: "9rem" }}
                src="https://img.freepik.com/free-vector/illustration-donation-support-icons_53876-6152.jpg?w=360&t=st=1706169139~exp=1706169739~hmac=d5003ef28042602b6e726cffd8d6b2f4f94d4a6f72c963369017bd36cf46317a"
                alt="Safe Pay Icon"
                class="safepay-icon"
              />
              <h4>Escrow Services</h4>
              <p>
                Our escrow system ensures that funds are held securely until the
                job is completed to your satisfaction.
              </p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="safepay-feature">
              <img
                style={{ width: "100%", height: "9rem" }}
                src="https://img.freepik.com/free-photo/employees-looking-colorful-signpost_1156-579.jpg?w=740&t=st=1706169170~exp=1706169770~hmac=ec631561a6132999553e53b18b3ae5648312650c6c6111660eb3d8faeb81c4d0"
                alt="Safe Pay Icon"
                class="safepay-icon"
              />
              <h4>Dispute Resolution</h4>
              <p>
                In the rare event of a dispute, our platform provides a fair and
                efficient resolution process.
              </p>
            </div>
          </div>
        </div>

        <div class="text-center">
          <p class="lead">
            Experience worry-free transactions on our platform. Your
            satisfaction and security are our top priorities.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Safe;
