import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Slider, Input } from "antd";
import Five from "./Five";
import { db } from "../../../../Config";
import { doc, updateDoc } from "firebase/firestore";
import { EditOutlined } from "@ant-design/icons";
function Fourth({ setStepStage }) {
  const navigate = useNavigate();
  const { job_post_id } = useParams();
  const [isContast, setisContast] = useState(true);
  const [Currency, setCurrency] = useState("USD");
  const [isContestSelected, setisContestSelected] = useState(false);
  const [isDurationSelected, setisDurationSelected] = useState(false);
  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value);
    // Set inputValue according to the new currency
    setInputValue((prevInputValue) => {
      if (e.target.value === "usd") {
        // Convert back to USD if EUR is currently selected
        return prevInputValue * 1.07527; // 1 EUR = 1.07527 USD (assuming conversion rate)
      } else {
        // Convert to EUR if USD is currently selected
        return prevInputValue / 1.07527; // 1 USD = 1.07527 EUR (assuming conversion rate)
      }
    });
  };

  const [inputValue, setInputValue] = useState(1);
  const onChange = (newValue) => {
    setInputValue(newValue);
  };
  const handleContest = (e) => {
    if (e === "Contest") {
      setisContast(true);
      console.log("contest");
    } else {
      setisContast(false);
      console.log("project");
    }
  };
  const [proDeadline, setproDeadline] = useState("");
  const handleDuartion = (e) => {
    if (e === "normal") {
      setproDeadline("5-30 Days");
    } else {
      setproDeadline("1-5 Days");
    }
  };
  const [selectedNames, setSelectedNames] = useState([]);

  const GoToThird = async () => {
    if (!isContestSelected && !isDurationSelected) {
      setisContestSelected(true);
      setisDurationSelected(true);
    } else {
      console.log(selectedNames);
      try {
        const userDocRef = doc(db, "UpdatedJobs", job_post_id);
        await updateDoc(userDocRef, {
          price: `${Currency}${inputValue}`,
          contestOrProject: isContast ? "Contast" : "Project",
          duration: proDeadline,
          pakage: selectedNames,
        });
        setStepStage(6);
        navigate(`/Post-job/${job_post_id}`);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const [isDurationShow, setisDurationShow] = useState(false);
  const handleShowInputDuration = () => {
    setisDurationShow(true);
  };
  return (
    <div style={{ marginTop: "5rem" }}>
      {" "}
      <div className="container">
        <div className="MyAllContents">
          <span>How Whuold you like to get it done?</span>
          <div className="suggesiotionCards">
            <div
              className="suggessionInnerCards"
              onClick={() => handleContest("Project")}
              style={{
                cursor: "pointer",
                border: !isContast ? "1px solid green" : "1px solid gray",
              }}
            >
              {/* <div className="cardInnerImage"> */}
              <svg
                style={{ color: "blue", margin: "auto 10px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="56"
                fill="currentColor"
                class="bi bi-pc-display-horizontal"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5M12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0m2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0M1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1M1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25" />
              </svg>

              <div className="siderText">
                <h4>Post Project</h4>

                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatem, temporibus! Minima libero excepturi delectus
                  voluptas reprehenderit, in ipsum
                </p>
              </div>
            </div>{" "}
            <div
              onClick={() => handleContest("Contest")}
              className="suggessionInnerCards"
              style={{
                border: isContast ? "1px solid green" : "1px solid gray",
              }}
            >
              {" "}
              <svg
                style={{ color: "blue", margin: "auto 10px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="56"
                fill="currentColor"
                class="bi bi-award-fill"
                viewBox="0 0 16 16"
              >
                <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864z" />
                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z" />
              </svg>
              <div className="siderText">
                <h4 style={{ marginTop: "20px" }}>Start Contest</h4>

                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatem, temporibus! Minima libero excepturi delectus
                  voluptas reprehenderit, in ipsum
                </p>
              </div>{" "}
              <span
                // style={{ justifyContent: "flex-end" }}
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"
              >
                <span class="visually-hidden" style={{ color: "white" }}>
                  Recommended
                </span>
              </span>
            </div>
          </div>
        </div>{" "}
        {isContast ? (
          <h3 style={{ marginTop: "1rem", textAlign: "center" }}>
            Project Type: Contest
          </h3>
        ) : (
          <h3 style={{ marginTop: "1rem", textAlign: "center" }}>
            Project Type: Project
          </h3>
        )}
        <h3 style={{ marginTop: "1rem" }}>What's you budget ?</h3>
        <div className="myPricerange">
          {" "}
          <div>
            {" "}
            <Slider
              min={20}
              max={10000}
              onChange={onChange}
              value={typeof inputValue === "number" ? inputValue : 0}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <p>{`${Currency === "usd" ? "$" : "€"} ${inputValue}`}</p>{" "}
            <p> {Currency === "usd" ? "$10,000+" : 10000 * 0.93}</p>
          </div>
          <div
            style={{
              marginTop: "2rem",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Input
              value={`${inputValue}`}
              placeholder="Basic usage"
              style={{ width: "40%" }}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <select
              onChange={handleChangeCurrency}
              class="form-control "
              name="benefits"
              style={{ marginLeft: "10px", width: "40%" }}
            >
              <option value="usd">USD</option>
              <option value="male">EUR</option>
            </select>
          </div>
        </div>
        <div
          className="suggesiotionCards"
          style={{
            marginTop: "2rem",
            display: isContestSelected ? "flex" : "none",
          }}
        >
          <div
            style={{
              backgroundColor: proDeadline === "5-30 Days" ? "lightgreen" : "",
            }}
            className="suggessionInnerCards"
            onClick={() => handleDuartion("normal")}
          >
            {/* <div className="cardInnerImage"> */}
            <svg
              style={{ color: "blue", margin: "auto 10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              fill="currentColor"
              class="bi bi-clock-history"
              viewBox="0 0 16 16"
            >
              <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
              <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
              <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
            </svg>

            {/* </div> */}
            <div className="siderText">
              <h4>3 To 30 Days</h4>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem, temporibus! Minima libero excepturi delectus
                voluptas reprehenderit, in ipsum
              </p>
            </div>
          </div>{" "}
          <div
            style={{
              backgroundColor: proDeadline === "1-5 Days" ? "lightgreen" : "",
            }}
            className="suggessionInnerCards"
            onClick={() => handleDuartion("urgent")}
          >
            <svg
              style={{ color: "red", margin: "auto 10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              fill="currentColor"
              bvvvvvvv
              class="bi bi-clock-history"
              viewBox="0 0 16 16"
            >
              <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
              <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
              <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
            </svg>
            <div className="siderText">
              <h4 style={{ marginTop: "20px" }}>1 Day</h4>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem, temporibus! Minima libero excepturi delectus
                voluptas reprehenderit, in ipsum
              </p>
            </div>{" "}
            <span
              // style={{ justifyContent: "flex-end" }}
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"
            >
              <span class="visually-hidden" style={{ color: "white" }}>
                Recommended
              </span>
            </span>
          </div>
        </div>{" "}
        <h2>
          Do You Want To Add Duration Manually?{" "}
          <EditOutlined onClick={handleShowInputDuration} />{" "}
        </h2>
        <br />
        <Input
          value={proDeadline}
          placeholder="Basic usage"
          style={{ width: "40%", display: isDurationShow ? "" : "none" }}
          onChange={(e) => setproDeadline(e.target.value)}
        />
        <div style={{ display: isDurationSelected ? "" : "none" }}>
          {" "}
          <Five
            selectedNames={selectedNames}
            setSelectedNames={setSelectedNames}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            onClick={GoToThird}
            type="success"
            className="btn btn-success my-3"
          >
            Next
          </button>
          <Link style={{ margin: "auto 2px " }} to={"/"}>
            Skip
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Fourth;
