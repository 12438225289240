import React from "react";
import AuthNavbar from "../AuthCommen/AuthNavbar";
import TargetProfile from "./TargetProfile";

function SearchUser() {
  return (
    <div>
      <AuthNavbar />

      <TargetProfile />
    </div>
  );
}

export default SearchUser;
