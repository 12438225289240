import React, { useContext } from "react";
import Choose from "./Choose";
import Navbar from "../CommenComponents/Navbar";
import Footer from "../CommenComponents/Footer";
import { myContext } from "../../App";
import AuthNavbar from "../../AuthComponents/AuthCommen/AuthNavbar";
function WhyChooseMain() {
  const a = useContext(myContext);
  const isAuth = a.IsLoggedIn;
  return (
    <div>
      {isAuth ? (
        <>
          <AuthNavbar />
          <Choose />
        </>
      ) : (
        <>
          <Navbar />
          <Choose />
        </>
      )}{" "}
      <Footer />
    </div>
  );
}

export default WhyChooseMain;
