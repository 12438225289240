import React from "react";

function Pricing() {
  return (
    <div>
      <div class="bradcam_area bradcam_bg_1">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>Pricing</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="container mt-5">
        <h2 class="text-center mb-4">Choose a Plan</h2>

        <div class="row">
          <div class="col-lg-4">
            <div class="pricing-card">
              <h3>Basic</h3>
              <p class="lead">$10/month</p>
              <ul class="list-unstyled">
                <li>Post 3 Jobs</li>
                <li>Access to Basic Features</li>
                <li>Limited Visibility</li>
                <li>Email Support</li>
              </ul>
              <button class="btn btn-primary">Get Started</button>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="pricing-card">
              <h3>Standard</h3>
              <p class="lead">$20/month</p>
              <ul class="list-unstyled">
                <li>Post 10 Jobs</li>
                <li>Enhanced Visibility</li>
                <li>Featured Listings</li>
                <li>Priority Email Support</li>
              </ul>
              <button class="btn btn-primary">Get Started</button>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="pricing-card">
              <h3>Premium</h3>
              <p class="lead">$30/month</p>
              <ul class="list-unstyled">
                <li>Unlimited Job Postings</li>
                <li>Top-notch Visibility</li>
                <li>Highlighted Listings</li>
                <li>24/7 Priority Support</li>
              </ul>
              <button class="btn btn-primary">Get Started</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pricing;
