import React, { useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";

import { myContext } from '../../../../App'

const Navbar = () => {
  const navi = useNavigate();
  const {  userName, userProfile} = useContext(myContext); const handleBack=()=>{navi('/')}
  return (
    <div className='navbar'>
      {/* <span className="logo">Best Wrench Chat</span> */}
      <div className="user">
      <svg       onClick={handleBack} className="myChoranIconTopToBack" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-chevron-compact-left backButton" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
            </svg>   <img src={userProfile} alt="" />
        <span>{userName}</span>
       
      </div>
    </div>
  )
}






export default Navbar