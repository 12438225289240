import React from "react";
import "./whyChoose.css";
function WhyChooseMain() {
  return (
    <div>
      <div class="services">
        <div class="Mycontainer">
          <div class="container">
            <h2 className="my-3">
              Why Choose{" "}
              <span style={{ color: "blue", fontSize: "2rem" }}>Ithange</span>
              <span style={{ color: "green", fontSize: "2rem" }}>.ee </span>
              For Your Services
            </h2>
            <h3 className="my-3">
              Welcome to our platform, where job posting becomes effortless.
              Whether you're a seasoned professional or a newcomer, our website
              provides a user-friendly interface to post jobs across various
              categories.
            </h3>

            <div class="overview">
              <div class="col-md-4 col-sm-4">
                <div class="feature-box">
                  <img src="./Whychoose/image1.png" class="img-responsive" />
                </div>

                <div class="feature-body">
                  <h4>Business Consultancy</h4>
                  <p>
                    Empower your business with strategic insights and tailored
                    solutions.
                  </p>
                </div>
              </div>

              <div class="col-md-4 col-sm-4">
                <div class="feature-box">
                  <img src="./Whychoose/image2.png" class="img-responsive" />
                </div>

                <div class="feature-body">
                  <h4>Chartered Accountancy</h4>
                  <p>
                    Ensure financial excellence and compliance with the
                    expertise of Chartered Accountants.
                  </p>
                </div>
              </div>

              <div class="col-md-4 col-sm-4">
                <div class="feature-box">
                  <img src="./Whychoose/image3.png" class="img-responsive" />
                </div>

                <div class="feature-body">
                  <h4>Financial Analysis</h4>
                  <p>
                    We are connects to skilled Financial Analysts who can
                    provide optimize your financial strategies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseMain;
