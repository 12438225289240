import React, { useContext, useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

import { AuthContext } from "../context/AuthContext";
import { Avatar } from "antd";
import { myContext } from "../../../../App";
import { db } from "../../../../Config";

const Search = ({ id }) => {
  const [Myusername, setMyusername] = useState("");
  const [user, setUser] = useState(null);
  const [err, setErr] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { RenderNe } = useContext(myContext);
  const [AllUsers, setAllUsers] = useState([]);

  const fetchAllUsers = async () => {
    try {
      const usersCollection = await getDocs(collection(db, "user"));
      const usersData = usersCollection.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllUsers(usersData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  

  useEffect(() => {
    if (id) {
      const selectedUser = AllUsers.find((user) => user.id === id);
      if (selectedUser) {
        setMyusername(selectedUser.username);
      }
    }
  }, [AllUsers, id]);

  const handleSearch = async () => {
    const q = query(collection(db, "user"), where("username", "==", Myusername));
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUser(doc.data());
      });
    } catch (err) {
      console.log(err);
      setErr(true);
    }
  };

  const handleKey = (e) => {
    if (e.code === "Enter") {
      handleSearch();
    }
  };

  const handleSelect = async (FrId, targetProfile) => {
    try {
      const combinedId = currentUser.uid > FrId ? currentUser.uid + FrId : FrId + currentUser.uid;
      await setDoc(doc(db, "chats", combinedId), { messages: [] });

      let currentUserData = AllUsers.find((user) => user.email === currentUser.email);

      if (currentUserData) {
        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: FrId,
            name: Myusername,
            profilePic: targetProfile,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            name: currentUserData.name,
            profilePic: currentUserData.image,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      } else {
        console.log("User Chat Already Exists");
      }
    } catch (err) {
      console.log(err);
    }
  };
useEffect(() => {
    if (id && id !== "chatBox") {
      handleSearch(); // Call handleSearch automatically if id is not "chatBox"
    }
  }, [id, AllUsers, Myusername]);
  return (
    <div className="search">
      <div className="searchForm">
        <input
          type="text"
          placeholder="Find a user"
          onKeyDown={handleKey}
          onChange={(e) => setMyusername(e.target.value)}
          value={Myusername}
        />
      </div>
      {err && <span>User not found!</span>}
      {user && (
        <>
          <span style={{ color: "white", margin: "0rem .5rem" }}>Start chat with</span>
          <div className="userChat" onClick={() => handleSelect(user.uid, user.image)}>
            <Avatar
              src={
                user.image !== "none"
                  ? user.image
                  : "https://img.freepik.com/free-photo/pretty-smiling-joyfully-female-with-fair-hair-dressed-casually-looking-with-satisfaction_176420-15187.jpg?size=626&ext=jpg&ga=GA1.1.79629375.1687438200&semt=sph"
              }
            />
            <div className="userChatInfo">
              <span>{user.name}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Search;
