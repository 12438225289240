import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Avatar, message } from "antd";
import "../../../Component/HireTalent/Hire.css";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../Config";
import { myContext } from "../../../App";
function AvailableTalent() {
  const a = useContext(myContext);
  const myFetchJob = a.fetchJobs;
  const status = a.status;
  const navigate = useNavigate();
  const userId = a.UserUid;
  const [allJobs, setAllJobs] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const fetchJobs = async () => {
    // setisLoading(true);
    try {
      const jobsCollection = await getDocs(collection(db, "jobs"));
      const usersArray = []; // Create an array to store user data

      const jobsArray = await Promise.all(
        jobsCollection.docs.map(async (doc) => {
          const userId = doc.data().uid;
          const user = await getUserData(userId);
          if (user && Object.keys(user).length > 0) {
            // Push user data into the array
            usersArray.push({
              id: userId,
              name: user.name !== "" ? user.name : "",
              username: user.username !== "" ? user.username : "", // Use empty string as a fallback if name is undefined
              profilePicture: user.image !== "" ? user.image : "", // Use empty string as a fallback if profilePicture is undefined
            });
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );

      // Set the AllUsers state after the map function has completed
      setAllUsers(usersArray);
      setAllJobs(jobsArray);
      setisLoading(false);
      myFetchJob();
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setisLoading(false);
    }
  };
  const getUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "user", userId));
      return userDoc.exists() ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user data:", error);
      return {};
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);
  useEffect(() => {
    console.log(allJobs);
  }, [allJobs]);
  useEffect(() => {
    console.log(AllUsers);
  }, [AllUsers]);

  if (!db) {
    return <p>Loading...</p>; // You can show a loading indicator while Firebase is initializing
  }

  const handleGoToProfile = (userId) => {
    navigate(`/Hire-Talent/user-profile/${userId}`);
  };
  const handleClickContatc = (e) => {
    navigate(`/contact/${e}`);
  };

  const AddToFevirit = async (e) => {
    const TragetGigiFavorits = allJobs.find((i) => i.id === e);
    if (TragetGigiFavorits) {
      const FavPostRef = await addDoc(collection(db, "MyFavorites"), {
        uid: userId,
        favId: "",
        gigId: TragetGigiFavorits.id,
        category: TragetGigiFavorits.category,
        title: TragetGigiFavorits.title,
        userName: TragetGigiFavorits.userName,
        profile: TragetGigiFavorits.userProfile,
        description: TragetGigiFavorits.description,
      });
      const jobId = FavPostRef.id;
      await updateDoc(FavPostRef, { favId: jobId });
      message.success("Successfully Added To Favorite");
      myFetchJob();
    } else {
      message.error("Sorry Internal Error");
    }

    console.log(TragetGigiFavorits);
  };
  return (
    <div style={{ width: "100%", marginTop: "-3rem" }}>
      {" "}
      <h2
        style={{
          fontFamily: "sans-serif",
          textAlign: "center",
        }}
      >
        Top verified
        <span style={{ color: "blue", fontSize: "2rem" }}> Ithange</span>
        <span style={{ color: "green", fontSize: "2rem" }}>.ee </span>
        freelancer
      </h2>
      <h1 style={{ textAlign: "center" }}>
        {isLoading && (
          <>
            {" "}
            <img
              style={{ width: "10%", height: "10%" }}
              src="loader.gif"
              alt=""
              srcset=""
            />
            <h3>Loading Getting Details........</h3>
          </>
        )}
      </h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "16px",
          marginTop: "2rem",
        }}
      >
        {allJobs.map((e, index) => {
          const carouselId = `carouselExampleIndicators-${index}`;
          const carouselTarget = `#carouselExampleIndicators-${index}`;
          return (
            <div
              className="card"
              style={{ width: "240px" }}
              id="MyUserCradsService"
              key={index}
            >
              <div
                className="carousel slide"
                data-ride="carousel"
                id={carouselId}
              >
                <div class="carousel-inner" style={{ width: "100%" }}>
                  <div class="carousel-item active">
                    <img
                      style={{ height: "9rem" }}
                      class="d-block w-100"
                      src={
                        e.img1 !== ""
                          ? e.img1
                          : "https://media.istockphoto.com/id/1434742171/photo/laptop-ppt-presentation-business-meeting-and-team-working-on-review-for-new-digital-website.webp?b=1&s=170667a&w=0&k=20&c=CTuv0GsOQhhXBx7nhCghIaadrbJS9bpTdIn7zHi8xOo="
                      }
                      alt="First slide"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      style={{ height: "9rem" }}
                      class="d-block w-100"
                      src={
                        e.img2 !== ""
                          ? e.img2
                          : "https://media.istockphoto.com/id/1434742171/photo/laptop-ppt-presentation-business-meeting-and-team-working-on-review-for-new-digital-website.webp?b=1&s=170667a&w=0&k=20&c=CTuv0GsOQhhXBx7nhCghIaadrbJS9bpTdIn7zHi8xOo="
                      }
                      alt="Second slide"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      style={{ height: "9rem" }}
                      class="d-block w-100"
                      src={
                        e.img3 !== ""
                          ? e.img3
                          : "https://media.istockphoto.com/id/1434742171/photo/laptop-ppt-presentation-business-meeting-and-team-working-on-review-for-new-digital-website.webp?b=1&s=170667a&w=0&k=20&c=CTuv0GsOQhhXBx7nhCghIaadrbJS9bpTdIn7zHi8xOo="
                      }
                      alt="Third slide"
                    />
                  </div>
                </div>
                <a
                  style={{ marginTop: "-1rem" }}
                  className="carousel-control-prev"
                  href={carouselTarget}
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  style={{ marginTop: "-1rem" }}
                  className="carousel-control-next"
                  href={carouselTarget}
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
              <div class="card-body">
                <div class="d-flex">
                  <div class="user-profile-picture mr-3">
                    <Badge
                      dot
                      color="green"
                      style={{ marginRight: "4px", marginTop: "31px" }}
                    >
                      <Avatar
                        onClick={() => handleGoToProfile(e.uid)}
                        style={{ cursor: "pointer" }}
                        size={40}
                        src={
                          e.userProfile !== "none"
                            ? e.userProfile
                            : "./img/candiateds/1.png"
                        }
                      />{" "}
                      <div
                        style={{
                          position: "absolute",
                          top: 74,
                          right: 8,
                          // background: "#52c41a",
                          borderRadius: "50%",
                          width: 12,
                          height: 12,
                        }}
                      ></div>
                    </Badge>
                  </div>
                  <div class="user-detail">
                    <h6 style={{ margin: "auto" }}>
                      {e.userName}{" "}
                      {e.isEmailVerified ? (
                        <>
                          <svg
                            style={{ color: "blue" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            class="bi bi-check-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                          </svg>
                        </>
                      ) : (
                        ""
                      )}
                    </h6>
                    <Link style={{ margin: "auto", fontSize: ".8rem" }}>
                      Web Developer
                    </Link>
                  </div>
                </div>
                <Link
                  to={`/Gigs-details/${e.jobId}`}
                  style={{
                    textAlign: "left",
                    marginTop: "3rem",
                    textDecoration: "underline",
                  }}
                >
                  {e.description && e.description.slice(0, 60)}.....
                </Link>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1.5rem",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    onClick={() => handleClickContatc(e.uid)}
                    style={{ color: "green" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-chat-left-text"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                  </svg>

                  <svg
                    style={{ color: "red" }}
                    onClick={() => AddToFevirit(e.id)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-heart"
                    viewBox="0 0 16 16"
                  >
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                  </svg>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AvailableTalent;
