import React, { useContext } from "react";
import { myContext } from "../../App";

function Talent() {
  const ContextData = useContext(myContext);
  const isAuth = ContextData.IsLoggedIn;
  return (
    <div>
      {" "}
      {isAuth ? (
        <></>
      ) : (
        <>
          {" "}
          <div class="bradcam_area bradcam_bg_1">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="bradcam_text">
                    <h3>Hire Freelancer</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}{" "}
      <div class="featured_candidates_area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section_title text-center mb-10">
                <h3>Top Candidates Of ITHANGE</h3>
              </div>
            </div>
          </div>
          <div class="featured_candidates_area candidate_page_padding">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/1.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/2.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/3.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/4.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/5.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/6.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/7.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/8.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/9.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/10.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/3.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="single_candidates text-center">
                    <div class="thumb">
                      <img src="img/candiateds/4.png" alt="" />
                    </div>
                    <a href="#">
                      <h4>Markary Jondon</h4>
                    </a>
                    <p>Software Engineer</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="pagination_wrap">
                    <ul>
                      <li>
                        <a href="#">
                          {" "}
                          <i class="ti-angle-left"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>01</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>02</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          <i class="ti-angle-right"></i>{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="featured_candidates_area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section_title text-center mb-40">
                <h3>Popular Services</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12" style={{ display: "flex", gap: "2rem" }}>
              <div class="candidate_active owl-carousel">
                <div class="card" style={{ width: "18rem" }}>
                  <img
                    style={{ height: "10rem" }}
                    class="card-img-top"
                    src="https://img.freepik.com/free-vector/flat-construction-landing-page_23-2148194326.jpg?w=740&t=st=1705550365~exp=1705550965~hmac=da546d0123776ed2c7e30d850eb790f5228d7edb2232e523f48a663528c280bd"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h5 class="card-title">Web Development</h5>
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                    <a href="post_job.html" class="btn btn-primary">
                      Post Job
                    </a>
                  </div>
                </div>
                <div class="card" style={{ width: "18rem" }}>
                  <img
                    class="card-img-top"
                    style={{ height: "10rem" }}
                    src="https://img.freepik.com/free-vector/app-development-landing-page-template_52683-47668.jpg?w=740&t=st=1705551362~exp=1705551962~hmac=780e109555308f5ccc60a062c089d9ba1ec8776463ed0fba7bf7b421a6ba5b90"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h5 class="card-title">App Development</h5>
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                    <a href="post_job.html" class="btn btn-primary">
                      Post Job
                    </a>
                  </div>
                </div>
                <div class="card" style={{ width: "18rem" }}>
                  <img
                    class="card-img-top"
                    style={{ height: "10rem" }}
                    src="https://img.freepik.com/free-vector/cartoon-graphic-design-landing-page_52683-70881.jpg?w=740&t=st=1705551414~exp=1705552014~hmac=a394412be20cc917fe859d41a46a683f46d166abe96cdee933986afb6d83bd35"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h5 class="card-title">Graphics Designing</h5>
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                    <a href="post_job.html" class="btn btn-primary">
                      Post Job
                    </a>
                  </div>
                </div>
                <div class="card" style={{ width: "18rem" }}>
                  <img
                    class="card-img-top"
                    style={{ height: "10rem" }}
                    src="https://img.freepik.com/free-photo/person-writing-office_53876-94944.jpg?w=740&t=st=1705551492~exp=1705552092~hmac=163951b1fed92bfce6d4d95a7ccd7a171760be8dbff0054b7bb84ce6f61c6494"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h5 class="card-title">Content Writing</h5>
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                    <a href="post_job.html" class="btn btn-primary">
                      Post Job
                    </a>
                  </div>
                </div>
                <div class="card" style={{ width: "18rem" }}>
                  <img
                    class="card-img-top"
                    style={{ height: "10rem" }}
                    src="https://img.freepik.com/free-photo/female-hand-typing-keyboard-laptop_1150-15739.jpg?w=740&t=st=1705551528~exp=1705552128~hmac=03e38c7ee36a337bff8b95639ddd6e985cc88be55cf1e916733d304ff55a63b0"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h5 class="card-title">Data Entry</h5>
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                    <a href="post_job.html" class="btn btn-primary">
                      Post Job
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Talent;
