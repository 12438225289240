import React, { useContext } from "react";
import HireBest from "./HireBest";
import Home from "./Home";
import Service from "./Service";
import Testamonial from "./Testamonial";
import Navbar from "../CommenComponents/Navbar";
import Footer from "../CommenComponents/Footer";
import MainHome from "../../AuthComponents/HomeAuth/MainHome";
import { myContext } from "../../App";
import Hor from "./Hor";

function HomeMain() {
  const ContextData = useContext(myContext);
  const isAuth = ContextData.IsLoggedIn;

  return (
    <div>
      {isAuth ? (
        <>
          <MainHome />
        </>
      ) : (
        <>
          <Navbar />
          <Home />
          <Service /> <HireBest /> <Testamonial />
        </>
      )}

      <Footer />
    </div>
  );
}

export default HomeMain;
