import React from "react";
import AuthNavbar from "../../AuthCommen/AuthNavbar";
import AuthHome from "./AuthHome";
import Footer from "../../../Component/CommenComponents/Footer";
import SubCom from "./SubCom";
import WhyChooseMain from "./ExtarComponent/WhyChooseMain";
import MostPapuler from "./AllUserGigiWrapper/MostPapupler";

function AuthPage() {
  return (
    <div>
      <AuthNavbar />
      <AuthHome /> <WhyChooseMain />
      <MostPapuler /> <SubCom />
      // <Footer />
    </div>
  );
}

export default AuthPage;
