import React, { useState, useRef, useContext } from "react";
import { Form, Button, Input, message, Avatar, Tag, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { storage, db } from "../../../Config";
import { useNavigate } from "react-router-dom";
import { myContext } from "../../../App";
const { TextArea } = Input;

const { Option } = Select;
const UserLastForm = ({ userId, setIsRegComplete }) => {
  const navigate = useNavigate();
  const UserData = useContext(myContext);
  const CallMe = UserData.RenderNe;
  const [profilePic, setProfilePic] = useState(null);
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("");
  const [languageLevel, setLanguageLevel] = useState("");
  const [languages, setLanguages] = useState([]);
  const [skill, setSkill] = useState("");
  const [skills, setSkills] = useState([]);
  const fileInputRef = useRef(null);
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 600) {
      setDescription(value);
    }
  };

  const getCharacterCount = (text) => {
    return text.length;
  };
  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `RegisterUserImages/${file.name}`);

      uploadBytes(storageRef, file)
        .then((snapshot) => {
          console.log("Uploaded a blob or file!", snapshot);
          return getDownloadURL(snapshot.ref);
        })
        .then((downloadURL) => {
          message.success("Profile Update Success");
          console.log("File available at", downloadURL);
          setProfilePic(downloadURL);
        })
        .catch((error) => {
          message.error("Error uploading file");
          console.error("Error uploading file: ", error);
          message.error(`${file.name} file upload failed.`);
        });
    } else {
      message.error("No file selected");
      console.log("No file selected");
    }
  };

  const handleLanguageSubmit = () => {
    if (language && languageLevel) {
      const newLanguage = `${language} - ${languageLevel}`;
      setLanguages([...languages, newLanguage]);
      setLanguage("");
      setLanguageLevel("");
    }
  };

  const handleSkillSubmit = () => {
    if (skill) {
      setSkills([...skills, skill]);
      setSkill("");
    }
  };

  const handleLanguageDelete = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
  };

  const handleSkillDelete = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
  };

  const handleAvatarClick = () => {
    // Trigger click event on file input
    fileInputRef.current.click();
  };

  const HandleFinnish = () => {
    console.log(description);
    console.log(languages);
    console.log(skills);
    console.log(profilePic);
    try {
      const userDocRef = doc(db, "user", userId);
      updateDoc(userDocRef, {
        description: description,
        image: profilePic,
        skill: skills,
        language: languages,
        speakingLevel: "not set",
      })
        .then((res) => {
          message.success("Profile Completed");
          setIsRegComplete(true);
          CallMe();
          navigate("/");
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setIsRegComplete(false);
          message.error("Sorry Some Error Occurred");
        });
    } catch (error) {
      message.error("Sorry Some Error Occurred");
      console.error("Error updating user status:", error.message);
    }
  };

  return (
    <div
      style={{ width: "70%", margin: "3rem auto", border: "1px solid black" }}
    >
      {" "}
      <div className="card" style={{ padding: "5px" }}>
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            Fill The Following Form To Know About You
          </h2>{" "}
          <br />{" "}
          <h4 style={{ textAlign: "center", margin: "-2rem" }}>
            Take The First Step Toward Success
          </h4>{" "}
          <br />{" "}
          <p style={{ textAlign: "center", marginTop: "10px" }}>Ithange.ee</p>
        </div>
        <Form onFinish={HandleFinnish}>
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0rem 2rem",
            }}
          >
            <h3 className="mx-2">
              Profile Picture:
              <br />{" "}
              <p>
                Upload The Profile Picture The Other User Can <br /> Better Know
                About You
              </p>
            </h3>
            <Form.Item>
              <input
                type="file"
                name="image"
                ref={fileInputRef}
                onChange={handleProfilePicChange}
                style={{ display: "none" }}
              />
              <Avatar
                className="mx-3"
                size={84}
                icon={<UploadOutlined />}
                src={profilePic}
                onClick={handleAvatarClick}
              />{" "}
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "2rem 2rem",
            }}
          >
            <h3>Description:</h3>
            <Form.Item style={{ width: "100%" }} className="mx-2">
              <Input.TextArea
                rows={3}
                value={description}
                onChange={handleDescriptionChange}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {description.length > 600 && (
                  <div style={{ color: "red" }}>
                    Description must not exceed 600 characters.
                  </div>
                )}
                <div>{getCharacterCount(description)}/600 characters</div>
              </div>
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "2rem 35px",
            }}
          >
            <h3 className="mx-2">Select Language:</h3>
            <Form.Item style={{ width: "45%", marginTop: "1rem" }}>
              <Input.Group compact>
                <Input
                  style={{ width: "50%", margin: "0px 8px" }}
                  placeholder="Language"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                />
                <Select
                  className="my-2"
                  style={{ width: "45%" }}
                  placeholder="Level"
                  value={languageLevel}
                  onChange={(value) => setLanguageLevel(value)}
                >
                  <Option value="Beginner">Beginner</Option>
                  <Option value="Intermediate">Intermediate</Option>
                  <Option value="Advanced">Advanced</Option>
                </Select>
              </Input.Group>

              <svg
                style={{ marginTop: "1rem" }}
                onClick={handleLanguageSubmit}
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-plus-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
              </svg>

              <div>
                {languages.map((lang, index) => (
                  <Tag color="blue" key={index}>
                    {lang}{" "}
                    <span onClick={() => handleLanguageDelete(index)}>×</span>
                  </Tag>
                ))}
              </div>
            </Form.Item>
          </div>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "3rem 2rem",
            }}
          >
            <h3>Select Skill:</h3>
            <Form.Item style={{ width: "45%", marginTop: "1rem" }}>
              <Input
                placeholder="Skill"
                value={skill}
                onChange={(e) => setSkill(e.target.value)}
              />
              <svg
                style={{ marginTop: "1rem" }}
                onClick={handleSkillSubmit}
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-plus-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
              </svg>
              <div>
                {skills.map((sk, index) => (
                  <Tag color="green" key={index}>
                    {sk} <span onClick={() => handleSkillDelete(index)}>×</span>
                  </Tag>
                ))}
              </div>
            </Form.Item>
          </div>
          <Form.Item>
            <Button
              className="mx-4"
              style={{
                backgroundColor: "black",
                float: "right",
                marginTop: "2rem",
              }}
              type="primary"
              htmlType="submit"
            >
              Create Account
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UserLastForm;
