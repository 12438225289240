import React from "react";
import Lottie from "lottie-react";
import MyLoader from "./Animation - 1709380234376.json";

function MyServicesLoader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        style={{ height: "30%" }}
        animationData={MyLoader}
        autoplay
        loop
      />
    </div>
  );
}

export default MyServicesLoader;
